import React from "react";

import { Message } from "semantic-ui-react";

const InfoMessage = (props: { header?: string; infoMessage?: string | React.ReactNode }) => (
    <Message color={"blue"}>
        <Message.Header>{props.header || "Info"}</Message.Header>
        <div style={{ whiteSpace: "pre-wrap" }}>{props.infoMessage}</div>
    </Message>
);

export default InfoMessage;
