import { MouseEvent, useEffect, useState } from "react";

import { Button, Icon, Loader, Message, Pagination } from "semantic-ui-react";
import { PaginationProps } from "semantic-ui-react/dist/commonjs/addons/Pagination/Pagination";

import { role } from "@/autogen/openapi";

import { getErrorMessage } from "../../../utils/errors";
import { getActivePage, getTotalPages } from "../../../utils/pagination";
import { useUserRole } from "../../query";
import InviteUserModalV2 from "./InviteUserModalV2";
import MembersTableV2 from "./MembersTableV2";
import { useUsersQueryV2 } from "./query";

const USERS_PER_PAGE = 10;

const MembersTab = () => {
    // Local state:
    const [inviteModalOpen, setInviteModalOpen] = useState(false);
    const [offset, setOffset] = useState(0);
    const [total, setTotal] = useState(0);

    // Users Query and State
    const queryParams = {
        offset,
        limit: USERS_PER_PAGE,
    };
    const { data: userList, isLoading: userListLoading, error: userListError } = useUsersQueryV2(queryParams);
    const userRole = useUserRole();

    useEffect(() => {
        if (userList) {
            setOffset(userList.offset);
            setTotal(userList.total);
        }
    }, [userList, setOffset, setTotal]);

    const errorMessage = getErrorMessage(userListError);

    if (userListLoading) {
        return <Loader inline />;
    }

    return (
        <>
            {errorMessage && (
                <Message negative>
                    <Message.Header>Error</Message.Header>
                    <p>{errorMessage}</p>
                </Message>
            )}
            {userRole !== role.READONLY && (
                <Button primary size="small" onClick={() => setInviteModalOpen(true)}>
                    <Icon name="user plus" /> Invite
                </Button>
            )}
            <MembersTableV2 users={userList?.data} />
            <div style={{ justifyContent: "center", display: "flex" }}>
                <Pagination
                    activePage={getActivePage(offset, USERS_PER_PAGE)}
                    totalPages={getTotalPages(total, USERS_PER_PAGE)}
                    onPageChange={(e: MouseEvent<HTMLAnchorElement>, { activePage }: PaginationProps) => {
                        const offset = ((activePage as number) - 1) * USERS_PER_PAGE;
                        setOffset(offset);
                    }}
                    firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                    lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                    prevItem={{ content: <Icon name="angle left" />, icon: true }}
                    nextItem={{ content: <Icon name="angle right" />, icon: true }}
                />
            </div>
            <InviteUserModalV2 open={inviteModalOpen} setOpen={setInviteModalOpen} />
        </>
    );
};

export default MembersTab;
