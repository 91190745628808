import React, { memo, useEffect } from "react";

import { Form, Icon, SemanticCOLORS } from "semantic-ui-react";
import { DropdownProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";

import metrics from "../metrics/metrics";
import { getOverflowItem } from "../utils/overflow";
import { noFunctionCompare } from "../utils/reactUtils";
import { rawtextSearch } from "../utils/search";
import { commonGetDatasetsOptions, useDatasetsForConnectionQuery } from "./query";

export const DatasetStatusMap = {
    connected: {
        color: "green",
        active: true,
        busy: false,
    },
    connecting: {
        color: "olive",
        active: false,
        busy: true,
    },
    refreshing: {
        color: "yellow",
        active: false,
        busy: true,
    },
    errored: {
        color: "red",
        active: false,
        busy: false,
    },
};

const getDatasetFullName = (connection?: Connection, dataset?: Dataset) => {
    return connection?.type + ": " + dataset?.name;
};

const getDatasetStatusInfo = (status?: string) => (status ? DatasetStatusMap[status as DatasetStatus] : undefined);

const DatasetStatusIcon = (props: { dataset?: Dataset }) => (
    <Icon
        style={{ float: "right" }}
        name="circle"
        color={getDatasetStatusInfo(props.dataset?.status)?.color as SemanticCOLORS}
    />
);

/**
 * An unoffical component to display the selected dataset.
 * @param datasetSelectionName The name of the dataset the user selected.
 * @param dataset The dataset object that the user selected.
 * @returns undefined | JSX.Element of the dataset name and status icon.
 */
const getDatasetSelectorText = (datasetSelectionName: string | null, dataset?: Dataset) => {
    if (datasetSelectionName === null || datasetSelectionName === undefined) {
        return undefined;
    }

    return (
        <div style={{ width: "100%" }}>
            {getOverflowItem(datasetSelectionName, true, 85, "inline-block")}
            {datasetSelectionName !== null && <DatasetStatusIcon dataset={dataset} />}
        </div>
    );
};

const DatasetSelector = (props: {
    connection?: Connection;
    dataset?: Dataset;
    initialDatasetId?: number;
    selectDataset: (dataset: Dataset) => void;
    disabled?: boolean;
}) => {
    const { connection, dataset, initialDatasetId, selectDataset, disabled } = props;

    const {
        data: datasets,
        error,
        isLoading,
    } = useDatasetsForConnectionQuery(connection, {
        ...commonGetDatasetsOptions(),
        enabled: connection !== undefined,
    });

    useEffect(() => {
        if (!dataset && Array.isArray(datasets) && datasets?.length > 0) {
            let selectedDataset = datasets[0];

            // Allow for a specific dataset to be selected initially
            if (initialDatasetId) {
                const dataset = datasets.find((dataset) => dataset.id === initialDatasetId);
                if (dataset) {
                    selectedDataset = dataset;
                }
            }

            selectDataset(selectedDataset);
        }
    }, [datasets, dataset, selectDataset]);

    const onDatasetChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (!datasets) {
            return;
        }
        const dataset = datasets[data.value as number];
        selectDataset(dataset);
    };

    const datasetOptions = datasets?.map((dataset: Dataset, index: number) => {
        const fullName = getDatasetFullName(connection, dataset);

        return {
            rawtext: fullName,
            text: getOverflowItem(fullName, false, 85, "inline-block"),
            description: <DatasetStatusIcon dataset={dataset} />,
            disabled: !getDatasetStatusInfo(dataset.status)?.active,
            value: index,
        };
    });

    let selectedDatasetIdx = undefined;
    let datasetSelectionName = null;
    if (Array.isArray(datasets) && datasets.length > 0) {
        const idx = datasets.findIndex((x) => x.id === dataset?.id);
        if (idx !== -1) {
            selectedDatasetIdx = idx;
            datasetSelectionName = getDatasetFullName(connection, datasets?.[idx]);
        }
    }

    return (
        <div style={{ width: "100%", paddingLeft: "0.5rem" }}>
            <Form.Select
                error={error}
                className={metrics.BLOCK_AUTO_CAPTURE}
                onClick={() => metrics.captureClick("Data.DatasetSelector")}
                name="dataset"
                label="Dataset"
                options={datasetOptions || []}
                value={selectedDatasetIdx}
                // @ts-expect-error Element | undefined is officially not allowed, but it works
                text={getDatasetSelectorText(datasetSelectionName, dataset)}
                fluid
                placeholder={error ? "--" : "Dataset"}
                onChange={onDatasetChange}
                search={rawtextSearch}
                selection
                loading={isLoading}
                disabled={disabled}
            />
        </div>
    );
};

export default memo(DatasetSelector, noFunctionCompare);
