import { acceleratorId, deployment, deploymentStatus } from "@/autogen/openapi";

// TODO: Remove null when we fix gateway middleware!
export const isDeploymentPromptable = (deployment?: deployment | null): boolean => {
    switch (deployment?.status) {
        case deploymentStatus.READY:
        case deploymentStatus.STANDBY:
        case deploymentStatus.INITIALIZING:
        case deploymentStatus.UPDATING:
            return true;
        default:
            return false;
    }
};

export const formatAcceleratorID = (acceleratorId?: acceleratorId | null) => {
    if (acceleratorId === null || acceleratorId === undefined) {
        return "—";
    }

    const gpuID = acceleratorId!;
    let gpuType = gpuID.split("_")[0].toUpperCase();
    if (gpuType === "A10") {
        gpuType = "A10G";
    }
    const gpuFrac = Number(gpuID.split("_").at(-1)) / 100;

    // Handle SXM / PCIE specifications on accelerators
    let gpuSocket = (gpuID.split("_")[2] as string | undefined)?.toUpperCase();

    if (gpuSocket === "PCIE" || gpuSocket === "SXM") {
        if (gpuSocket === "PCIE") {
            gpuSocket = "PCIe";
        }
        gpuType = `${gpuType} (${gpuSocket})`;
    }

    return `${gpuFrac} x ${gpuType}`;
};

export const formatAdapterType = (adapterType?: string | null) => {
    if (adapterType === null || adapterType === undefined) {
        return "--";
    }

    return adapterType.replace("_", " ").replace("lora", "LoRA").replace("turbo", "Turbo");
};

export const TERMINAL_BLACK_BACKGROUND = "rgb(35, 35, 35)";
