import { useContext, useState } from "react";

import { useRecoilState } from "recoil";
import { Button, Icon, SemanticCOLORS, Table } from "semantic-ui-react";

import { tier } from "@/autogen/openapi";

import GrayDash from "../components/GrayDash";
import LiveTimeAgo from "../components/LiveTimeAgo";
import metrics from "../metrics/metrics";
import { USER_STATE } from "../state/global";
import { SEMANTIC_GREY } from "../utils/colors";
import { isNotProduction } from "../utils/environment";
import { FeatureFlagsContext } from "../utils/feature-flags";
import { capitalize } from "../utils/strings";
import EngineCheckbox from "./EngineCheckbox";
import { getEngineStatusInfo } from "./EngineStatus";
import UpdateEngineModal from "./UpdateEngineModal";

const DefaultTrainingEngineName = "train_engine";

const EngineName = (props: { engine: Engine; subscriptionTier: tier }) => {
    const { engine, subscriptionTier } = props;
    let engineName = isNotProduction() ? (
        <span>
            {engine.name} <span style={{ color: SEMANTIC_GREY }}>({engine.instanceID || "--"})</span>
        </span>
    ) : (
        engine.name || null
    );
    if (engine.restricted && engine.name === DefaultTrainingEngineName) {
        if (subscriptionTier !== tier.PREMIUM) {
            engineName = (
                <>
                    {engineName}
                    <Icon name={"lock"} />
                </>
            );
        }
    }
    return <span>{engineName}</span>;
};

const TableRow = (props: {
    setEngineActive: (engine: Engine, active: boolean, callback: Function) => void;
    engine: Engine;
    templates?: Map<number, string>;
    setActiveEngineSelection: (engine: Engine | null) => void;
    setUpdateEngineModalOpen: (open: boolean) => void;
}) => {
    // User and context state:
    const { featureFlags } = useContext(FeatureFlagsContext);
    const [userContext] = useRecoilState(USER_STATE);
    const subscriptionTier = userContext?.tenant.tier;

    // Parent state:
    const { engine, setEngineActive, templates } = props;

    // Local state:
    const [busy, setBusy] = useState<any>({});

    // Derived state:
    const lastJobCompleted = engine.lastJobCompleted && <LiveTimeAgo fromDate={new Date(engine.lastJobCompleted)} />;
    const displayName: JSX.Element | null = subscriptionTier ? (
        <EngineName engine={engine} subscriptionTier={subscriptionTier} />
    ) : null;

    return (
        <Table.Row key={engine.id}>
            <Table.Cell>
                <Button
                    className={`${metrics.BLOCK_AUTO_CAPTURE} button-reset`}
                    style={{ fontWeight: "normal", padding: 0 }}
                    onClick={() => {
                        props.setActiveEngineSelection(engine);
                        props.setUpdateEngineModalOpen(true);
                    }}
                >
                    {displayName}
                </Button>
            </Table.Cell>
            <Table.Cell>{templates && engine?.templateID && templates.get(engine.templateID)}</Table.Cell>
            {featureFlags["Engine Environment Show/Hide"] ? <Table.Cell>{engine?.environment?.name}</Table.Cell> : null}
            <Table.Cell>{engine.autoSuspendSeconds || <GrayDash />}</Table.Cell>
            <Table.Cell>{engine.autoResume ? "yes" : "no"}</Table.Cell>
            <Table.Cell>{lastJobCompleted || "never"}</Table.Cell>
            <Table.Cell>
                <Icon name="circle" color={getEngineStatusInfo(engine.engineStatus)?.color as SemanticCOLORS} />
                {capitalize(engine.engineStatus)}
            </Table.Cell>
            <Table.Cell collapsing style={{ paddingBottom: "1px" }}>
                <EngineCheckbox
                    checked={getEngineStatusInfo(engine.engineStatus)?.active}
                    disabled={getEngineStatusInfo(engine.engineStatus)?.busy || busy[engine.id]}
                    onClick={(event, data) => {
                        setBusy({ ...busy, [engine.id]: true });
                        setEngineActive(engine, data.checked || false, () =>
                            setBusy({
                                ...busy,
                                [engine.id]: false,
                            }),
                        );
                    }}
                />
            </Table.Cell>
        </Table.Row>
    );
};

const GeneralEnginesTable = (props: {
    setEngineActive: (engine: Engine, active: boolean, callback: Function) => void;
    engines: Engine[];
    templates?: Map<number, string>;
}) => {
    // Parent state:
    const { setEngineActive, engines, templates } = props;

    // Context state:
    const { featureFlags } = useContext(FeatureFlagsContext);

    // Modal state:
    const [activeEngineSelection, setActiveEngineSelection] = useState<Engine | null>(null);
    const [updateEngineModalOpen, setUpdateEngineModalOpen] = useState(false);

    return (
        <>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Template</Table.HeaderCell>
                        {featureFlags["Engine Environment Show/Hide"] ? (
                            <Table.HeaderCell>Environment</Table.HeaderCell>
                        ) : null}
                        <Table.HeaderCell>Auto Suspend (Seconds)</Table.HeaderCell>
                        <Table.HeaderCell>Auto Resume</Table.HeaderCell>
                        <Table.HeaderCell>Last Job Completed</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Active</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {engines.map((engine) => (
                        <TableRow
                            key={`enginesTableRow_${engine.id}`}
                            setEngineActive={setEngineActive}
                            engine={engine}
                            templates={templates}
                            setActiveEngineSelection={setActiveEngineSelection}
                            setUpdateEngineModalOpen={setUpdateEngineModalOpen}
                        />
                    ))}
                </Table.Body>
            </Table>
            {activeEngineSelection && (
                <UpdateEngineModal
                    engine={activeEngineSelection}
                    open={updateEngineModalOpen}
                    setOpen={setUpdateEngineModalOpen}
                />
            )}
        </>
    );
};

export default GeneralEnginesTable;
