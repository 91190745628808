import { AxiosResponse } from "axios";

import { Auth0TokenOptions } from "../data";
import metrics from "../metrics/metrics";
import { createV1APIServer, redirectIfSessionInvalid } from "../utils/api";
import { getErrorMessage } from "../utils/errors";
import { getTraceId } from "./trace";

export const getTokens = async (auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "tokens";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get(endpoint)
        .then((res: AxiosResponse<Token[]>) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const createToken = async (description: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "tokens";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .post(endpoint, { description })
        .then((res: AxiosResponse<Token>) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const updateToken = async (id: string, description: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `tokens/${id}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .put(endpoint, { description })
        .then((res: AxiosResponse<Token>) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "PUT",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const expireToken = async (id: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `tokens/${id}/expire`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .post(endpoint)
        .then((res: AxiosResponse<Token>) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const activateToken = async (id: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `tokens/${id}/activate`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .post(endpoint)
        .then((res: AxiosResponse<Token>) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const deleteToken = async (id: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `tokens/${id}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .delete(endpoint)
        .then(() => {})
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "DELETE",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};
