import React, { useEffect, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosError } from "axios";
import { useRecoilState } from "recoil";
import { Button, Form, Message } from "semantic-ui-react";

import { track } from "../../../metrics/june";
import metrics from "../../../metrics/metrics";
import { USER_STATE } from "../../../state/global";
import { getDocsHome } from "../../../utils/api";
import { SEMANTIC_GREY } from "../../../utils/colors";
import { getFileTypeAndExtension } from "../../../utils/files";
import { useFileDatasetNameValidQuery } from "../../query";
import ConnectorPanel from "../ConnectorPanel";
import { Connector } from "../util";

function sanitize(s: string) {
    // lowercase and remove whitespace
    return s.toLowerCase().replaceAll(" ", "");
}

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
const allowedMimeTypes: Set<string> = new Set([
    "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "vnd.ms-excel",
    "vnd.oasis.opendocument.spreadsheet",
    "tsv",
    "csv",
    "json",
]);
// https://github.com/ludwig-ai/ludwig/blob/38d4c9a75e889960237bf896f74e07cf91d39268/ludwig/utils/data_utils.py#L700
const allowedExtensions: Set<string> = new Set([
    "csv",
    "tsv",
    "json",
    "jsonl",
    "xls",
    "xlsx",
    "xlsm",
    "xlsb",
    "odf",
    "ods",
    "odt",
    "parquet",
    "pickle",
    "feather",
    "fwf",
    "html",
    "orc",
    "sas",
    "spss",
    "dta",
    "stata",
    "h5",
    "hdf5",
]);

function FileConnector(props: React.PropsWithChildren<any> & ConnectorProps) {
    const [file, setFile] = useState<File>();
    let fileData: FileUploadData | undefined = undefined;
    const [tableName, setTableName] = useState(props.connection ? props.connection.name : "");

    const [tableNameError, setTableNameError] = useState<string | null>(null);
    const [fileExtensionError, setFileExtensionError] = useState<string | null>(null);
    const [fileSizeError, setFileSizeError] = useState<boolean>(false);
    const [validLoading, setValidLoading] = useState(false);
    const nameTimer = useRef<NodeJS.Timeout>();
    const [user] = useRecoilState(USER_STATE);

    const validateName = (name: string) => {
        if (nameTimer.current) {
            clearTimeout(nameTimer.current);
        }
        if (name === "") {
            setTableNameError(null);
            return;
        }

        if (name !== name.toLowerCase()) {
            setTableNameError("Name must be lowercase");
            return;
        }

        if (name.indexOf(" ") >= 0) {
            setTableNameError("Name must not contain whitespace");
            return;
        }

        if (!isNaN(Number(name.charAt(0)))) {
            setTableNameError("Name cannot start with a number");
            return;
        }

        setTableNameError(null);
        setValidLoading(true);

        nameTimer.current = setTimeout(() => {
            refetchNameValid();
            setValidLoading(false);
        }, 500);
    };

    const reset = () => {
        setTableName(props.connection ? props.connection.name : "");
        setFile(undefined);
    };

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value;
        setTableName(val);
        validateName(val);
    };
    const { refetch: refetchNameValid, error: validNameError } = useFileDatasetNameValidQuery(tableName, {
        enabled: false,
        retry: false,
    });
    useEffect(() => {
        // TODO: Hard dependency on custom logic in the core function in src/data/data.ts...
        if ((validNameError as AxiosError | null)?.message === "dataset already exists with this name") {
            setTableNameError("dataset already exists with this name");
        }
    }, [validNameError]);

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const file = e.target.files?.[0] as File;

        // Check file exists
        if (!file) {
            return;
        }

        const { name, type } = file;
        const { fileType, fileExt } = getFileTypeAndExtension(name, type);

        // Check file type and extension exist
        if (fileType === "" && fileExt === "") {
            setFileExtensionError(
                `No type found for file [${name}]. Supported file types are [${Array.from(allowedExtensions).join(
                    ", ",
                )}]`,
            );
            return;
        }

        // Check file type and extension are supported
        if (!allowedExtensions.has(fileExt) && !allowedMimeTypes.has(fileType)) {
            user &&
                track(user, "file_upload_constraint_violation", {
                    type: "file_type",
                    file_type: fileType,
                    file_ext: fileExt,
                    file_size: file.size,
                });
            setFileExtensionError(
                `File type [${fileType}] with extension [${fileExt}] not supported. Supported file types are [${Array.from(
                    allowedExtensions,
                ).join(", ")}]`,
            );
            return;
        }

        // Check file size is less than 100MB
        if (file.size > 1e8) {
            user &&
                track(user, "file_upload_constraint_violation", {
                    type: "file_size",
                    file_type: fileType,
                    file_ext: fileExt,
                    file_size: file.size,
                });
            setFileSizeError(true);
            return;
        }

        // Set file
        setFile(file);

        // Set table name
        let newTableName = tableName || name.split(".").slice(0, -1).join(".");
        newTableName = sanitize(newTableName);
        setTableName(newTableName);
        validateName(newTableName);

        // Remove errors upon successful file upload
        setFileExtensionError(null);
        setFileSizeError(false);

        // TODO: Capture this when user submits the form
        // metrics.captureChange("Source.Connect.ConnectorPanel.File", {
        //     file: {
        //         name: name,
        //         size: size,
        //         type: type,
        //         lastModified: lastModified,
        //     },
        //     connector: "File",
        // });
    };

    const getUpdateRequest = () => {
        return {
            endpoint: "datasets/update",
            body: {
                id: props.connection!.id,
                name: tableName,
                type: props.connection!.type,
            },
            headers: {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        };
    };

    const isDisabled = () => {
        if (props.connection) {
            return !tableName;
        }
        return validLoading || !file?.name || !tableName || tableNameError || fileExtensionError || fileSizeError;
    };

    if (file) {
        fileData = {
            file: file,
            fileName: tableName,
        };
    }

    const getModalContent = () => {
        return (
            <Form>
                {!props.connection ? (
                    <>
                        <Message floating info>
                            File uploads are limited to 100 MB at this time. If you have a larger dataset, we recommend
                            using cloud storage (Snowflake, BigQuery, etc.) to connect your data to Predibase. &nbsp;
                            <a
                                onClick={() => {
                                    user &&
                                        track(user, "docs", {
                                            url: getDocsHome() + "/user-guide/fine-tuning/prepare-data",
                                            clickSource: "file-connector",
                                        });
                                }}
                                href={getDocsHome() + "/user-guide/fine-tuning/prepare-data"}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Learn more about dataset requirements{" "}
                                <FontAwesomeIcon icon="arrow-up-right-from-square" />
                            </a>
                        </Message>
                        {fileSizeError ? (
                            <Message negative>
                                <Message.Header>File size too large</Message.Header>
                                <p>Please choose a file smaller than 100 MB.</p>
                            </Message>
                        ) : null}
                        <Form.Field width={3}>
                            <label>File upload</label>
                            <Button
                                className={metrics.BLOCK_AUTO_CAPTURE}
                                onClick={() =>
                                    metrics.captureClick("Data.Connect.ConnectorPanel.File", { connector: "File" })
                                }
                                content="Choose File"
                                as="label"
                                htmlFor="file"
                                type="button"
                                labelPosition="left"
                                icon="file"
                            />
                            <input type="file" id="file" hidden onChange={onFileChange} />
                            <label>{file?.name}</label>
                        </Form.Field>
                    </>
                ) : null}
                {fileExtensionError ? (
                    <Message negative>
                        <Message.Header>File extension error</Message.Header>
                        <p>{fileExtensionError}</p>
                    </Message>
                ) : null}
                <div className="field">
                    <label style={{ fontSize: "14px", marginBottom: "4px" }}>Dataset Name</label>
                    <p style={{ color: SEMANTIC_GREY, fontSize: "12px", marginBottom: "10px" }}>
                        Choose a name for your dataset.
                    </p>
                    <Form.Input
                        width={5}
                        autoFocus
                        loading={validLoading}
                        error={tableNameError}
                        name="datasetName"
                        placeholder="Dataset name"
                        value={tableName}
                        onChange={onNameChange}
                    />
                </div>
            </Form>
        );
    };

    return (
        <ConnectorPanel
            modalHeader="Upload Data from File"
            header="File"
            editModalHeader="Edit File Source"
            submitLabel="Upload"
            name={Connector.FILE}
            errorHeader="Error in file upload"
            isDisabled={isDisabled}
            fileUploadData={fileData}
            getModalContent={getModalContent}
            getUpdateRequest={getUpdateRequest}
            reset={reset}
            {...props}
        />
    );
}

export default FileConnector;
