import { useEffect, useMemo, useState } from "react";

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-tomorrow";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Accordion, Button, Grid, Icon } from "semantic-ui-react";

import { rewardFunction, rewardFunctionsConfig } from "@/autogen/openapi";

import { useAuth0TokenOptions } from "../../../../data";
import { getDocsHome } from "../../../../utils/api";
import { PREDIBASE_LIGHT_GRAY, SEMANTIC_BLACK } from "../../../../utils/colors";
import { updateJobConfig } from "../../../data";
import { GET_GRPO_PROMPT_FUNCTIONS_QUERY_KEY, useGRPOPromptFunctions } from "../../../query";

type SelectedFunction = {
    name: string;
    version: number;
};

const TreeNavigation = (props: {
    activePanel?: SelectedFunction;
    setActivePanel: React.Dispatch<React.SetStateAction<SelectedFunction | undefined>>;
    functions?: Record<string, rewardFunction>;
}) => {
    const { activePanel, setActivePanel, functions } = props;

    const handleAccordionTitleClick = (title: string) => {
        const selectedFunction = functions?.[title];
        setActivePanel({ name: title, version: selectedFunction?.version ?? 1 });
    };

    const Title = (title: string, hasChildren: boolean) => {
        const isActive = activePanel?.name === title;
        return (
            <Accordion.Title
                style={{
                    paddingLeft: "1.714rem",
                    backgroundColor: isActive && !hasChildren ? "rgb(33, 133, 208, 0.2)" : undefined,
                }}
                active={isActive}
                onClick={() => {
                    handleAccordionTitleClick(title);
                }}
            >
                {title}
                {hasChildren && (
                    <Icon name={isActive ? "caret down" : "caret right"} style={{ marginLeft: "0.286rem" }} />
                )}
            </Accordion.Title>
        );
    };

    const PanelButton = (selectedFunction: SelectedFunction) => (
        <button
            className="button-reset tree-navigation-button"
            style={{
                padding: "0.3572rem 1.7857rem 0.3572rem 4.2857rem",
                width: "100%",
                textAlign: "left",
                backgroundColor:
                    JSON.stringify(selectedFunction) === JSON.stringify(activePanel)
                        ? "rgb(33, 133, 208, 0.2)"
                        : undefined,
                fontWeight: JSON.stringify(selectedFunction) === JSON.stringify(activePanel) ? "bold" : "normal",
                color: SEMANTIC_BLACK,
            }}
            onClick={() => {
                setActivePanel(selectedFunction);
            }}
        >
            version {selectedFunction.version}
        </button>
    );

    const rootPanels = Object.keys(functions ?? {}).map((func, idx) => {
        const currentFunc = functions?.[func];
        const hasOlderVersions = Array.isArray(currentFunc?.history) && currentFunc?.history?.length > 0;
        return {
            key: func,
            title: Title(func, hasOlderVersions),
            content: (
                <Accordion.Content style={{ padding: "0 0 0" }}>
                    <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                        {hasOlderVersions && <li>{PanelButton({ name: func, version: currentFunc.version ?? 1 })}</li>}
                        {functions &&
                            functions[func]?.history?.map((prevFunc: string, funcIndex: number, funcs: string[]) => (
                                <li key={prevFunc.substring(0, 60)}>
                                    {PanelButton({ name: func, version: funcs.length - funcIndex })}
                                </li>
                            ))}
                    </ul>
                </Accordion.Content>
            ),
        };
    });

    return <Accordion defaultActiveIndex={0} panels={rootPanels} />;
};

const RewardFunctions = (props: { jobUUID: string }) => {
    // Parent props:
    const { jobUUID } = props;

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Local state:
    const [activePanel, setActivePanel] = useState<SelectedFunction>();
    const [isEditing, setIsEditing] = useState(false);
    const [editedCode, setEditedCode] = useState("");

    // Query state:
    const queryClient = useQueryClient();
    const { data: functionsMeta, isLoading: functionsMetaLoading } = useGRPOPromptFunctions(jobUUID);
    const functionNames = Object.keys(functionsMeta?.functions ?? {});

    // Mutations:
    const { mutate: updateRewardFunctions, reset: resetMutation } = useMutation({
        mutationFn: () =>
            updateJobConfig(
                jobUUID,
                {
                    rewardFns: {
                        functions: {
                            ...functionsMeta?.functions, // We need to pass all functions to preserve them
                            [activePanel!.name]: {
                                encodedFn: btoa(editedCode), // Need to use btoa (base64 encode) not atob (decode)
                            },
                        },
                    },
                },
                auth0TokenOptions,
            ),
        onSuccess: () => {
            // Invalidate the query to refetch the latest data
            queryClient.invalidateQueries({ queryKey: GET_GRPO_PROMPT_FUNCTIONS_QUERY_KEY(jobUUID) }).then(() => {
                // After refetching data, update the active panel to show the latest version
                if (activePanel) {
                    const currentFunctionName = activePanel.name;
                    // Need to DIRECTLY refetch the latest data to get the new version number
                    const latestVersionFunction = queryClient.getQueryData<rewardFunctionsConfig | undefined>(
                        GET_GRPO_PROMPT_FUNCTIONS_QUERY_KEY(jobUUID),
                    );
                    if (latestVersionFunction?.functions?.[currentFunctionName]) {
                        const newVersionNumber = latestVersionFunction.functions[currentFunctionName].version;
                        // Set to the latest version of the same function
                        setActivePanel({ name: currentFunctionName, version: newVersionNumber! });
                    }
                }
            });
            resetMutation();
        },
        onError: (error) => {
            console.error("Failed to update reward function:", error);
            // TODO: Show error message to user
            setIsEditing(true); // Keep in edit mode if there's an error
        },
    });

    // Derived state:
    const selectedFunction = useMemo(() => {
        if (!activePanel) {
            return "";
        }

        // TODO: Ask Jeff T if we can simplify this data structure:
        const selectedFunction = functionsMeta?.functions?.[activePanel.name];
        if (activePanel.version === selectedFunction?.version) {
            return atob(selectedFunction?.encodedFn);
        }

        if (!Array.isArray(selectedFunction?.history) || selectedFunction?.history.length === 0) {
            return "";
        }

        const selectedPreviousVersionIndex = selectedFunction.history.length - activePanel.version;
        return atob(selectedFunction.history[selectedPreviousVersionIndex]);
    }, [activePanel]);

    useEffect(() => {
        if (!functionsMeta || typeof activePanel !== "undefined") {
            return;
        }

        const firstFunction = functionNames[0];
        const latestVersion = functionsMeta?.functions?.[firstFunction]?.version ?? 1;
        setActivePanel({ name: firstFunction, version: latestVersion });
    }, [activePanel, functionsMetaLoading, functionsMeta]);

    // Handle function switching while editing
    useEffect(() => {
        if (isEditing) {
            setIsEditing(false);
            setEditedCode(selectedFunction);
        }
    }, [activePanel?.name, activePanel?.version]);

    useEffect(() => {
        if (selectedFunction) {
            setEditedCode(selectedFunction);
        }
    }, [selectedFunction]);

    const handleEdit = () => {
        console.log("Editing");
        setIsEditing(true);
    };

    const handleSave = () => {
        console.log("Saving");
        setIsEditing(false);
        updateRewardFunctions();
    };

    const handleCancel = () => {
        console.log("Cancelling");
        setIsEditing(false);
        setEditedCode(selectedFunction);
    };

    const handleDelete = () => {
        // TODO: Implement API call to delete the function
        console.log("Deleting function:", activePanel?.name);
    };

    // Check if current function is the latest version
    const isLatestVersion = useMemo(() => {
        if (!activePanel || !functionsMeta) return false;
        const func = functionsMeta.functions?.[activePanel.name];
        return activePanel.version === func?.version;
    }, [activePanel, functionsMeta]);

    return (
        <>
            <a
                href={`${getDocsHome()}/user-guide/fine-tuning/grpo#how-do-i-update-my-reward-functions`}
                target="_blank"
                rel="noreferrer"
            >
                <Icon name="book" />
                How to update your reward functions during training (SDK)
            </a>
            <Grid style={{ margin: "0" }}>
                <Grid.Row>
                    <Grid.Column
                        computer={4}
                        tablet={4}
                        mobile={16}
                        style={{ backgroundColor: PREDIBASE_LIGHT_GRAY, padding: "1rem 0 0 0", marginTop: "0.5rem" }}
                    >
                        <TreeNavigation
                            activePanel={activePanel}
                            setActivePanel={setActivePanel}
                            functions={functionsMeta?.functions}
                        />
                    </Grid.Column>
                    <Grid.Column computer={12} tablet={12} mobile={16}>
                        <div style={{ position: "relative" }}>
                            {!isEditing && isLatestVersion && (
                                <div style={{ position: "absolute", top: "0.6rem", right: "0.6rem", zIndex: 10 }}>
                                    <Button
                                        size="tiny"
                                        onClick={handleEdit}
                                        basic
                                        style={{ border: "none", boxShadow: "none", padding: "0.3rem" }}
                                    >
                                        <FontAwesomeIcon icon={"pen"} /* style={{ color: '#2185d0' }} */ />
                                    </Button>
                                    <Button
                                        size="tiny"
                                        onClick={handleDelete}
                                        basic
                                        style={{
                                            border: "none",
                                            boxShadow: "none",
                                            padding: "0.3rem",
                                            marginLeft: "0.3rem",
                                        }}
                                    >
                                        <FontAwesomeIcon icon={"trash"} /* style={{ color: '#db2828' }} */ />
                                    </Button>
                                </div>
                            )}
                            {isEditing ? (
                                <>
                                    <div
                                        style={{
                                            padding: "0.5rem 0",
                                            marginBottom: "0.8rem",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: `${16 / 14}rem` /* 14px = 1rem in this project */,
                                                display: "flex",
                                                alignItems: "center",
                                                lineHeight: "2.57rem" /* 36px ÷ 14px = 2.57rem */,
                                                height: "2.57rem" /* 36px ÷ 14px = 2.57rem */,
                                            }}
                                        >
                                            <span style={{ fontWeight: "bold", marginRight: "0.3rem" }}>
                                                Editing Reward Function
                                            </span>
                                            <span>
                                                {" "}
                                                - {activePanel?.name}{" "}
                                                {activePanel &&
                                                    `(version ${(functionsMeta?.functions?.[activePanel.name]?.version || 0) + 1})`}
                                            </span>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Button
                                                onClick={handleCancel}
                                                style={{ marginRight: "0.5rem" }}
                                                color="grey"
                                            >
                                                Cancel
                                            </Button>
                                            <Button onClick={handleSave} primary>
                                                Save Changes
                                            </Button>
                                        </div>
                                    </div>
                                    <AceEditor
                                        mode="python"
                                        theme="tomorrow"
                                        value={editedCode}
                                        onChange={(newValue) => {
                                            setEditedCode(newValue);
                                        }}
                                        onLoad={(editor) => {
                                            // Initial settings based on content
                                            editor.renderer.setScrollMargin(10, 10, 0, 0);
                                            editor.setOptions({
                                                maxLines: Infinity,
                                                minLines: 5,
                                            });
                                        }}
                                        name="reward-function-editor"
                                        editorProps={{ $blockScrolling: true }}
                                        width="100%"
                                        fontSize={14}
                                        showPrintMargin={false}
                                        showGutter={true}
                                        highlightActiveLine={true}
                                        setOptions={{
                                            enableBasicAutocompletion: true,
                                            enableLiveAutocompletion: true,
                                            enableSnippets: true,
                                            showLineNumbers: true,
                                            tabSize: 4,
                                        }}
                                        maxLines={Infinity}
                                        minLines={5}
                                        wrapEnabled={true}
                                    />
                                </>
                            ) : (
                                <SyntaxHighlighter language="python" showLineNumbers={true} style={oneLight}>
                                    {selectedFunction}
                                </SyntaxHighlighter>
                            )}
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
};

export default RewardFunctions;
