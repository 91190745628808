import { deploymentEventUpdate } from "@/autogen/openapi";

import { SEMANTIC_WHITE } from "../../../../utils/colors";

const DeploymentEvents = (props: { events?: deploymentEventUpdate[] }) => {
    const { events } = props;
    return (
        <>
            {Array.isArray(events) && events.length > 0 && (
                <ul style={{ listStyleType: "none", padding: 0 }}>
                    {events.map((event, index) => {
                        return (
                            <li key={index} style={{ padding: "10px 20px", color: SEMANTIC_WHITE }}>
                                <div style={{ display: "flex", justifyContent: "flex-start", fontFamily: "monospace" }}>
                                    <span style={{ marginRight: "1rem" }}>
                                        {new Date(event.timeStamp).toLocaleString()}
                                    </span>
                                    <span style={{ marginLeft: "1.5rem", fontWeight: "bold" }}>{event.eventType}</span>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            )}
        </>
    );
};

export default DeploymentEvents;
