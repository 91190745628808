import React, { useEffect, useRef, useState } from "react";

import { useMutation } from "@tanstack/react-query";
import {
    Button,
    Checkbox,
    Dimmer,
    Divider,
    Header,
    Input,
    Loader,
    Message,
    Modal,
    Popup,
    Table,
} from "semantic-ui-react";

import { useAuth0TokenOptions } from "../../data";
import metrics from "../../metrics/metrics";
import { getErrorMessage } from "../../utils/errors";
import { createDatasets } from "../data";
import { useConnectionSchemaConfigQuery } from "../query";

function CreateDatasetsView(props: {
    connection: Connection;
    onSubmit: () => void;
    getBreadcrumb?: () => React.ReactNode;
    onStepChange?: React.Dispatch<React.SetStateAction<number>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Local state:
    const [schemaConfig, setSchemaConfig] = useState<ConnectionSchemaConfig>();

    const [importedObjects, setImportedObjects] = useState<Set<string>>(new Set());
    const [importedMap, setImportedMap] = useState<Map<string, string>>(new Map());

    const [selectedObjects, setSelectedObjects] = useState<Set<string>>(new Set());
    const selectedMap = useRef(new Map());

    const [totalObjects, setTotalObjects] = useState<Set<string>>(new Set());

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if (props.connection?.datasets) {
            const imported = props.connection.datasets?.map((x) => x.objectName) || [];

            const localImportedMap = new Map();
            props.connection.datasets?.forEach((x) => {
                localImportedMap.set(x.objectName, x.name);
            });
            setImportedMap(localImportedMap);
            setImportedObjects(new Set(imported));
        }
    }, [props.connection]);

    const { data: connectionSchemaConfig } = useConnectionSchemaConfigQuery(props.connection.id, {
        enabled: schemaConfig === undefined,
    });
    useEffect(() => {
        if (connectionSchemaConfig) {
            setSchemaConfig(connectionSchemaConfig);
        }
    }, [connectionSchemaConfig]);

    useEffect(() => {
        if (schemaConfig) {
            setTotalObjects(new Set(schemaConfig?.tables.map((x) => x.name)));
        }
    }, [schemaConfig]);

    const { mutate: mutateCreateDatasets } = useMutation({
        mutationFn: (
            datasetNames: {
                objectName: string;
                datasetName: any;
            }[],
        ) => createDatasets(props.connection.id, datasetNames, auth0TokenOptions),
        onSuccess: () => {
            setErrorMessage(null);
            props.onSubmit();
        },
        onError: (error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            setErrorMessage(errorMsg);
        },
        onSettled: () => {
            setLoading(false);
        },
    });

    let errorMessageHeader = null;
    if (errorMessage) {
        errorMessageHeader = (
            <>
                <Divider hidden />
                <Message negative>
                    <Message.Header>Error</Message.Header>
                    <p>{errorMessage}</p>
                </Message>
                <Divider hidden />
            </>
        );
    }

    if (schemaConfig === undefined && !errorMessage) {
        return (
            <>
                <Modal.Header>Create Datasets</Modal.Header>
                <Modal.Content>
                    <Dimmer active inverted>
                        <Loader size="medium">Detecting tables in connection...</Loader>
                    </Dimmer>
                </Modal.Content>
            </>
        );
    }

    const createDatasetsFn = () => {
        setLoading(true);
        const objectNames = Array.from(selectedObjects);
        const createDatasetsNames = objectNames.map((objName) => ({
            objectName: objName,
            datasetName: selectedMap.current.get(objName) || objName,
        }));
        mutateCreateDatasets(createDatasetsNames);
    };

    return (
        <>
            <Modal.Header>
                Create Datasets
                {props.getBreadcrumb ? (
                    <>
                        <br />
                        {props.getBreadcrumb()}
                    </>
                ) : null}
            </Modal.Header>
            <Modal.Content scrolling>
                <Header>Select tables/objects from connection [{props.connection?.name}] to import as datasets</Header>
                {errorMessageHeader}
                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                <Checkbox
                                    onClick={(event, data) => {
                                        if (!data.checked) {
                                            setSelectedObjects(new Set());
                                        } else {
                                            setSelectedObjects(
                                                new Set(
                                                    schemaConfig?.tables
                                                        .filter((x) => !importedObjects.has(x.name))
                                                        .map((x) => x.name),
                                                ),
                                            );
                                        }
                                    }}
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>Object Name</Table.HeaderCell>
                            <Table.HeaderCell>Dataset Name (Leave blank for default)</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {Array.from(totalObjects).map((obj) => {
                            const alreadyImported = importedObjects.has(obj);
                            return (
                                <Table.Row key={obj}>
                                    <Table.Cell collapsing>
                                        <Checkbox
                                            checked={selectedObjects.has(obj) || alreadyImported}
                                            disabled={importedObjects.has(obj)}
                                            onChange={() => {
                                                if (selectedObjects.has(obj)) {
                                                    selectedObjects.delete(obj);
                                                } else {
                                                    selectedObjects.add(obj);
                                                }
                                                setSelectedObjects(new Set(selectedObjects));
                                            }}
                                        />
                                    </Table.Cell>
                                    <Table.Cell style={{ opacity: alreadyImported ? "50%" : undefined }}>
                                        {alreadyImported ? (
                                            <Popup
                                                position={"top center"}
                                                trigger={<span>{obj}</span>}
                                                content={"Already imported"}
                                            />
                                        ) : (
                                            obj
                                        )}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {alreadyImported ? (
                                            <Input placeholder={obj} value={importedMap.get(obj)} />
                                        ) : (
                                            <Input
                                                placeholder={obj}
                                                onChange={(event, data) => {
                                                    selectedMap.current.set(obj, data.value);
                                                }}
                                            />
                                        )}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {alreadyImported ? (
                                            <span style={{ opacity: "40%" }}>(Already imported)</span>
                                        ) : null}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                {props.onStepChange ? (
                    <Button
                        className={metrics.BLOCK_AUTO_CAPTURE}
                        style={{ float: "left" }}
                        onClick={() => props.onStepChange!((x) => x - 1)}
                    >
                        Back
                    </Button>
                ) : null}
                <Popup
                    position={"top center"}
                    trigger={
                        <Button content={"Skip"} onClick={(event, data) => props.setOpen(false)} loading={loading} />
                    }
                    content={"You can skip this step and create datasets from the connections table later"}
                />
                <Button
                    content={"Finish"}
                    labelPosition="right"
                    icon="checkmark"
                    onClick={(event, data) => createDatasetsFn()}
                    loading={loading}
                    disabled={selectedObjects.size === 0}
                    positive
                />
            </Modal.Actions>
        </>
    );
}

export default CreateDatasetsView;
