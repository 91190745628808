import { useEffect, useState } from "react";

import { Auth0ContextInterface, OAuthError } from "@auth0/auth0-react";
import axios from "axios";

import { Auth0TokenOptions, useAuth0TokenOptions } from "../data";
import metrics from "../metrics/metrics";

export const FRONTEND_ORIGIN_LOCAL = "http://localhost:3000";

export const getApiEndpoint = () => {
    const hostname = window.location.hostname.toString();
    const protocol = "https://";
    if (hostname.startsWith("localhost")) {
        if (process.env.REACT_APP_PROXY_API === "true") {
            return "/gateway";
        }
        return "http://localhost:8080/v1";
    }

    return `${protocol}api.${hostname}/v1`;
};

export const getV2APIEndpoint = () => {
    const hostname = window.location.hostname.toString();
    const protocol = "https://";
    if (hostname.startsWith("localhost")) {
        if (process.env.REACT_APP_PROXY_API === "true") {
            return "/gateway/v2";
        }
        return "http://localhost:8080/v2";
    }

    return `${protocol}api.${hostname}/v2`;
};

export const getServingAPIEndpoint = () => {
    const hostname = window.location.hostname.toString();
    const protocol = "https://";
    if (hostname.startsWith("localhost")) {
        if (process.env.REACT_APP_PROXY_API === "true") {
            return "/serving";
        }
        return "http://localhost:8080";
    }

    return `${protocol}serving.${hostname}`;
};

export const getClientEndpoint = () => {
    if (origin.startsWith("http://localhost")) {
        return FRONTEND_ORIGIN_LOCAL;
    }
    return window.location.origin.toString();
};

export const getDocsHome = () => {
    const origin = window.location.origin.toString();
    // For prod the URL is docs.predibase.com so replace
    // app.predibase.com with docs.predibase.com
    if (origin.includes("https://app.")) {
        return origin.replace("https://app.", "https://docs.");
    }

    // For dev and staging we host the docs at docs.staging.predibase.com
    // so replace the staging.predibase.com with docs.staging.predibase.com
    return origin.replace("http://", "http://docs.").replace("https://", "https://docs.");
};

export const getWebSocketEndpointV2 = () => {
    const hostname = window.location.hostname.toString();
    if (hostname.startsWith("localhost")) {
        if (process.env.REACT_APP_PROXY_API === "true") {
            return "ws://localhost:3000/socket/v2";
        }
        return "ws://localhost:8080/v2";
    }
    return getV2APIEndpoint().replace("http://", "ws://").replace("https://", "wss://");
};

export const apiServerFactory = (baseURL: string, accessToken?: string) => {
    if (accessToken) {
        return axios.create({
            withCredentials: false,
            baseURL,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    return axios.create({
        withCredentials: true,
        baseURL,
    });
};

export const servingAPIServer = apiServerFactory(getServingAPIEndpoint());

export const getAuth0TokenWithFallbacks = async (
    getAccessTokenSilently: Auth0ContextInterface["getAccessTokenSilently"],
    getAccessTokenWithPopup: Auth0ContextInterface["getAccessTokenWithPopup"],
    loginWithPopup: Auth0ContextInterface["loginWithPopup"],
): Promise<string> => {
    try {
        return await getAccessTokenSilently();
    } catch (error) {
        const authError = error as OAuthError;
        switch (authError.error) {
            case "login_required":
                await loginWithPopup();
                try {
                    return await getAccessTokenSilently();
                } catch (error) {
                    throw error;
                }
            case "consent_required":
                const popupToken = await getAccessTokenWithPopup();
                if (typeof popupToken === "string") {
                    return popupToken;
                } else {
                    throw error;
                }
            default:
                throw error;
        }
    }
};

export const useAuth0Token = () => {
    const [token, setToken] = useState<string | undefined>(undefined);
    const auth0TokenOptions = useAuth0TokenOptions();
    useEffect(() => {
        const getAndSetAuth0Token = async () => {
            // Get token for header injection:
            const { getAccessTokenSilently, getAccessTokenWithPopup, loginWithPopup } = auth0TokenOptions;
            setToken(
                getAccessTokenSilently === undefined ||
                    getAccessTokenWithPopup === undefined ||
                    loginWithPopup === undefined
                    ? undefined
                    : await getAuth0TokenWithFallbacks(getAccessTokenSilently, getAccessTokenWithPopup, loginWithPopup),
            );
        };
        getAndSetAuth0Token();
    }, [auth0TokenOptions]);
    return token;
};

export const createV1APIServer = async (auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = getApiEndpoint();
    const { getAccessTokenSilently, getAccessTokenWithPopup, loginWithPopup } = auth0TokenOptions || {};
    if (getAccessTokenSilently === undefined || getAccessTokenWithPopup === undefined || loginWithPopup === undefined) {
        return apiServerFactory(endpoint);
    }

    const token = await getAuth0TokenWithFallbacks(getAccessTokenSilently, getAccessTokenWithPopup, loginWithPopup);
    return apiServerFactory(endpoint, token);
};

export const createV2APIServer = async (auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = getV2APIEndpoint();
    const { getAccessTokenSilently, getAccessTokenWithPopup, loginWithPopup } = auth0TokenOptions || {};
    if (getAccessTokenSilently === undefined || getAccessTokenWithPopup === undefined || loginWithPopup === undefined) {
        return apiServerFactory(endpoint);
    }

    const token = await getAuth0TokenWithFallbacks(getAccessTokenSilently, getAccessTokenWithPopup, loginWithPopup);
    return apiServerFactory(endpoint, token);
};

export const redirectIfSessionInvalid = (errorMsg: string) => {
    if (errorMsg === "session is inactive") {
        if (window.location.pathname && window.location.pathname !== "/") {
            metrics.capture("signin_redirect", {
                source: "session_invalid_redirect_sub_path",
            });
            window.location.href = `/auth/signin?return_to=${window.location.pathname}`;
        } else {
            metrics.capture("signin_redirect", {
                source: "session_invalid_redirect_home_path",
            });
            window.location.href = "/auth/signin";
        }
    }
};
