import { useEffect, useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { Button, Divider, Form, Icon, Loader, Message, Tab } from "semantic-ui-react";

import { role } from "@/autogen/openapi";

import { useAuth0TokenOptions } from "../data";
import metrics from "../metrics/metrics";
import { useUserRole } from "../settings/query";
import { EngineServiceType } from "../types/engineServiceType";
import { getErrorMessage } from "../utils/errors";
import { rawtextSearch } from "../utils/search";
import CreateEngineModal from "./CreateEngineModal";
import { engineStatusSort, getEngineDropdownItemCSS, getEngineDropdownSelectionWithStatus } from "./EngineStatus";
import GeneralEnginesTable from "./GeneralEnginesTable";
import ServingEnginesTable from "./ServingEnginesTable";
import TrainingEnginesTable from "./TrainingEnginesTable";
import { startEngine, stopEngine, updateCurrentDefaultEngine } from "./data";
import { useEngineSchemaQuery, useEnginesQuery } from "./query";

const EnginesView = () => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // User state:
    const userRole = useUserRole();
    const isViewerSupport = userRole?.toUpperCase() === role.SUPPORT;

    // Local state:
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [engines, setEngines] = useState<Engine[]>([]);
    const [currentEngines, setCurrentEngines] = useState<CurrentEnginesResponse | undefined>();
    const [templates, setTemplates] = useState<Map<number, string>>();

    // We have to manage searchText and clear the dropdown selection when the search text changes
    // since dropdown library doesn't play nice with JSX.
    const [querySearchText, setQuerySearchText] = useState<string>("");
    const [trainSearchText, setTrainSearchText] = useState<string>("");

    // Modal state:
    const [createEngineModalOpen, setCreateEngineModalOpen] = useState(false);

    // Query state:

    // Fetch engine schemas:
    const { data: engineSchemasResponse, error: engineSchemasResponseError } = useEngineSchemaQuery({
        refetchInterval: false,
    });
    useEffect(() => {
        if (engineSchemasResponse) {
            setTemplates(new Map(engineSchemasResponse.templates.map((template) => [template.id, template.name])));
        }
    }, [engineSchemasResponse]);

    // Fetch list of engines:
    const { data: enginesResponse, error: enginesResponseError } = useEnginesQuery({
        refetchInterval: 1000,
    });
    useEffect(() => {
        if (enginesResponse) {
            // TODO: The types for these are probably outdated. All the checks imply that these properties may not
            // exist while the types say they are required:
            setEngines(enginesResponse.engines || []);
            if (enginesResponse.currentEngines != null) {
                setCurrentEngines(enginesResponse.currentEngines);
            }
        }
    }, [enginesResponse]);

    // Query errors:
    useEffect(() => {
        if (engineSchemasResponseError) {
            setErrorMessage(getErrorMessage(engineSchemasResponseError));
        } else if (enginesResponseError) {
            setErrorMessage(getErrorMessage(enginesResponseError));
        }
    }, [engineSchemasResponseError, enginesResponseError]);

    const { mutate: mutateCurrentDefaultEngine } = useMutation({
        mutationFn: ({ engineType, engineID }: { engineType: DefaultEngineType; engineID: number }) =>
            updateCurrentDefaultEngine(engineType, engineID, auth0TokenOptions),
        onSuccess: (data, variables) => {
            setCurrentEngines((prevCurrentEngines) => {
                if (prevCurrentEngines) {
                    return { ...prevCurrentEngines, [variables.engineType]: data };
                }
                return prevCurrentEngines;
            });
            setErrorMessage(null);
        },
        onError: (error) => {
            setErrorMessage(getErrorMessage(error));
        },
    });

    const { mutate: mutateStartEngine } = useMutation({
        // NOTE: `callback` goes unused in the mutation function but is then used in the success handler:
        // TODO: I don't really like the callback tbh
        mutationFn: ({ engine, callback }: { engine: Engine; callback: Function }) =>
            startEngine(engine, auth0TokenOptions),
        onSuccess: (data, variables) => {
            variables.callback();
        },
        onError: (error, variables) => {
            setErrorMessage(getErrorMessage(error));
            variables.callback();
        },
    });

    const { mutate: mutateStopEngine } = useMutation({
        // NOTE: `callback` goes unused in the mutation function but is then used in the success handler:
        // TODO: I don't really like the callback tbh
        mutationFn: ({ engine, callback }: { engine: Engine; callback: Function }) =>
            stopEngine(engine, auth0TokenOptions),
        onSuccess: (data, variables) => {
            variables.callback();
        },
        onError: (error, variables) => {
            setErrorMessage(getErrorMessage(error));
            variables.callback();
        },
    });

    // Generate dropdown options for engines:
    const engineIdMap = new Map(engines?.map((engine) => [engine.id, engine]));
    const queryEngineOptions = engines
        .filter((engine) => engine.serviceType === EngineServiceType.RAY)
        .map((engine) => {
            return {
                key: engine.id,
                text: getEngineDropdownItemCSS(engine),
                value: engine.id,
                rawtext: engine.name,
            } as Partial<Engine>;
        })
        .sort(engineStatusSort);
    const trainingEngineOptions = engines
        .filter((engine) => engine.serviceType === EngineServiceType.BATCH)
        .map((engine) => {
            return {
                key: engine.id,
                text: getEngineDropdownItemCSS(engine),
                value: engine.id,
                rawtext: engine.name,
            } as Partial<Engine>;
        })
        .sort(engineStatusSort);

    return (
        <div style={{ padding: "20px" }}>
            {currentEngines ? (
                <div style={{ display: "flex", columnGap: "1rem" }}>
                    <div>
                        <Form>
                            <Form.Select
                                label={"Default General Engine"}
                                className={metrics.BLOCK_AUTO_CAPTURE}
                                placeholder="Current Query Engine"
                                options={queryEngineOptions}
                                // @ts-ignore
                                text={
                                    querySearchText
                                        ? querySearchText
                                        : // TODO: Unsafe access:
                                          getEngineDropdownSelectionWithStatus(
                                              engineIdMap.get(currentEngines?.query?.id)!,
                                          )
                                }
                                onChange={(_, { value }) => {
                                    mutateCurrentDefaultEngine({
                                        engineID: value as number,
                                        engineType: "query",
                                    });
                                }}
                                onSearchChange={(e, data) => {
                                    // @ts-ignore
                                    setQuerySearchText(e.target.value);
                                }}
                                onClose={(event, data) => setQuerySearchText("")}
                                value={currentEngines?.query?.id}
                                search={rawtextSearch}
                                error={currentEngines?.query === undefined}
                                selection
                            />
                        </Form>
                    </div>
                    <div>
                        <Form>
                            <Form.Select
                                label={"Default Training Engine"}
                                className={metrics.BLOCK_AUTO_CAPTURE}
                                placeholder="Current Training Engine"
                                options={trainingEngineOptions}
                                // @ts-ignore
                                text={
                                    trainSearchText
                                        ? trainSearchText
                                        : // TODO: Unsafe access:
                                          getEngineDropdownSelectionWithStatus(
                                              engineIdMap.get(currentEngines?.train?.id)!,
                                          )
                                }
                                onChange={(e, { value }) => {
                                    mutateCurrentDefaultEngine({
                                        engineID: value as number,
                                        engineType: "train",
                                    });
                                }}
                                onSearchChange={(e, data) => {
                                    // @ts-ignore
                                    setTrainSearchText(e.target.value);
                                }}
                                onClose={(event, data) => setTrainSearchText("")}
                                value={currentEngines?.train?.id}
                                search={rawtextSearch}
                                error={currentEngines?.train === undefined}
                                selection
                            />
                        </Form>
                    </div>
                </div>
            ) : (
                <Loader active inline size="small" />
            )}
            <div className="row">
                {errorMessage && (
                    <Message negative>
                        <Message.Header>Error</Message.Header>
                        <p>{errorMessage}</p>
                    </Message>
                )}
            </div>

            <Divider hidden />
            {isViewerSupport && (
                <Button
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    floated="right"
                    icon
                    labelPosition="left"
                    primary
                    size="small"
                    style={{ marginBottom: `-${27 / 14}rem` }}
                    onClick={() => setCreateEngineModalOpen(true)}
                >
                    <Icon name="plus square" /> New Engine
                </Button>
            )}
            <Tab
                panes={[
                    {
                        menuItem: "General Engines",
                        render: () => (
                            <Tab.Pane>
                                <GeneralEnginesTable
                                    engines={engines.filter((engine) => engine.serviceType === EngineServiceType.RAY)}
                                    setEngineActive={(engine: Engine, active: boolean, callback: Function) => {
                                        if (active) {
                                            mutateStartEngine({ engine, callback });
                                        } else {
                                            mutateStopEngine({ engine, callback });
                                        }
                                    }}
                                    templates={templates}
                                />
                            </Tab.Pane>
                        ),
                    },
                    {
                        menuItem: "Training Engines",
                        render: () => (
                            <Tab.Pane>
                                <TrainingEnginesTable
                                    engines={engines.filter((engine) => engine.serviceType === EngineServiceType.BATCH)}
                                    templates={templates}
                                />
                            </Tab.Pane>
                        ),
                    },
                    {
                        menuItem: "Serving Engines",
                        render: () => (
                            <Tab.Pane>
                                <ServingEnginesTable
                                    engines={engines.filter(
                                        (engine) => engine.serviceType === EngineServiceType.SERVING,
                                    )}
                                    templates={templates}
                                />
                            </Tab.Pane>
                        ),
                    },
                ]}
            />
            <CreateEngineModal open={createEngineModalOpen} setOpen={setCreateEngineModalOpen} />
        </div>
    );
};

export default EnginesView;

// Should all be covered by refetching, unless it takes a long time for an engine to be created?...
// const onEngineSubmit = (engine: Engine, method: string) => {
//     let newEngines = [...engines];
//     if (method !== "delete") {
//         const index = newEngines.findIndex((e) => e.id === engine.id);
//         if (index !== -1) {
//             newEngines[index] = engine;
//         } else {
//             newEngines = [...newEngines, engine];
//         }
//         setEngines(newEngines);
//     } else {
//         newEngines = newEngines.filter((e) => e.id !== engine.id);
//         setEngines(newEngines);
//     }
// };
