import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { Table } from "semantic-ui-react";

import { SEMANTIC_BLUE } from "../../../utils/colors";
import { formatValueToNumericString } from "../../../utils/numbers";

/**
 * Returns the fill value for a the graphic visualization of a feature profile.
 * @param should_enable
 * @returns
 */
function getFillValue(should_enable: boolean) {
    return should_enable ? SEMANTIC_BLUE : "#D3D3D3";
}

/**
 * Returns the binary profile visualization.
 *
 * The binary profile visualization is a bar chart with two bars for the frequencies of the true and false labels.
 *
 * @param binary_profile
 * @returns
 */
function BinaryProfileVisualization(props: { binary_profile: any; should_enable: boolean }) {
    const fillValue = getFillValue(props.should_enable);

    const data = [
        {
            name: props.binary_profile.true_label,
            value: props.binary_profile.percent_true,
        },
        {
            name: props.binary_profile.false_label,
            value: props.binary_profile.percent_false,
        },
    ];
    return (
        <ResponsiveContainer width="100%" minHeight={100} height="100%">
            <BarChart data={data}>
                <Bar dataKey="value" fill={fillValue} />
                <XAxis dataKey="name" />
                <Tooltip />
            </BarChart>
        </ResponsiveContainer>
    );
}

/**
 * Returns the category profile visualization.
 *
 * The category profile visualization is a bar chart with the frequencies of all categories.
 *
 * @param category_profile
 * @param should_enable
 * @returns
 */
function CategoryProfileVisualization(props: { category_profile: any; should_enable: boolean }) {
    if (!props.category_profile.all_frequent_items) {
        // Profiles before May 22, 2023 do not have a all_frequent_items field.
        return null;
    }
    const data = props.category_profile.all_frequent_items.map((frequent_item: any) => ({
        name: frequent_item.label,
        value: frequent_item.percent_occurrence,
    }));

    const fillValue = getFillValue(props.should_enable);

    if (data.length > 20) {
        // If there are more than 20 unique values, show a frequency distribution instead of actual frequencies.
        return (
            <DistributionVisualization
                histogram_bins={props.category_profile.percentage_distribution.histogram_bins}
                should_enable={props.should_enable}
            />
        );
    }

    // There aren't many unique values. Show actual frequencies.
    return (
        <ResponsiveContainer width="100%" minHeight={100} height="100%">
            <BarChart data={data}>
                <Bar dataKey="value" fill={fillValue} />
                <XAxis dataKey="name" interval={"preserveStartEnd"} />
                <Tooltip />
            </BarChart>
        </ResponsiveContainer>
    );
}

/**
 * Returns a distribution histogram with the given histogram bins.
 * @param props
 * @returns
 */
function DistributionVisualization(props: { histogram_bins: any; should_enable: boolean }) {
    const numSamples = props.histogram_bins.reduce(
        (accumulator: any, histogram_bin: any) => accumulator + (histogram_bin.count ? histogram_bin.count : 0),
        0,
    );
    var data: any = props.histogram_bins.map((histogram_bin: any) => ({
        name: formatValueToNumericString(histogram_bin.bin),
        value: histogram_bin.count ? histogram_bin.count / numSamples : 0,
    }));

    const fillValue = getFillValue(props.should_enable);

    return (
        <ResponsiveContainer width="100%" minHeight={100} height="100%">
            <BarChart data={data}>
                <Bar dataKey="value" fill={fillValue} />
                <XAxis dataKey="name" interval={"preserveStartEnd"} />
                <Tooltip />
            </BarChart>
        </ResponsiveContainer>
    );
}

/**
 * Returns the number profile visualization.
 *
 * The number profile visualization is a histogram distribution of values.
 *
 * @param number_profile
 * @param should_enable
 * @returns
 */
function NumberProfileVisualization(props: { sampled_values: any; number_profile: any; should_enable: boolean }) {
    if (!props.number_profile.distribution.histogram_bins) {
        // Profiles before May 22, 2023 do not have a histogram_bins field.
        return null;
    }
    return (
        <DistributionVisualization
            histogram_bins={props.number_profile.distribution.histogram_bins}
            should_enable={props.should_enable}
        />
    );
}

/**
 * Returns the text profile visualization.
 *
 * The text profile visualization is a histogram distribution of word lengths.
 *
 * @param text_profile
 * @param should_enable
 * @returns
 */
function TextProfileVisualization(props: { text_profile: any; should_enable: boolean }) {
    if (!props.text_profile.word_length_distribution.histogram_bins) {
        // Profiles before May 22, 2023 do not have a word_length_distribution.histogram_bins field.
        return null;
    }
    return (
        <DistributionVisualization
            histogram_bins={props.text_profile.word_length_distribution.histogram_bins}
            should_enable={props.should_enable}
        />
    );
}

/**
 * Returns type-specific visualizations for a feature profile.
 * @param feature_profile
 * @returns
 */
function TypeSpecificVisualizations(props: { feature_profile: any }) {
    const should_enable = props.feature_profile.should_enable;
    const sampled_values = props.feature_profile.type_agnostic_profile.sampled_unique_values;
    if (props.feature_profile.inferred_type === "binary") {
        return (
            <BinaryProfileVisualization
                binary_profile={props.feature_profile.binary_profile}
                should_enable={should_enable}
            />
        );
    }
    // Category features with 1 unique value have no category profile.
    if (props.feature_profile.inferred_type === "category" && props.feature_profile.category_profile) {
        return (
            <CategoryProfileVisualization
                category_profile={props.feature_profile.category_profile}
                should_enable={should_enable}
            />
        );
    }
    if (props.feature_profile.inferred_type === "number") {
        return (
            <NumberProfileVisualization
                sampled_values={sampled_values}
                number_profile={props.feature_profile.number_profile}
                should_enable={should_enable}
            />
        );
    }
    if (props.feature_profile.inferred_type === "text") {
        return (
            <TextProfileVisualization text_profile={props.feature_profile.text_profile} should_enable={should_enable} />
        );
    }
    return null;
}

/**
 * Returns a Table.Cell with type-specific visualizations for a feature profile.
 * @param props
 * @returns
 */
export const ProfileVizCell = (props: { columnName: string; columnIndex: number; datasetProfile: any }) => {
    // Type-specific visualizations.
    return (
        <Table.Cell singleLine key={props.columnIndex} verticalAlign="top">
            <TypeSpecificVisualizations feature_profile={props.datasetProfile.feature_profiles[props.columnName]} />
        </Table.Cell>
    );
};
