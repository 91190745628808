import { Link } from "react-router-dom";
import { Breadcrumb, Icon } from "semantic-ui-react";

import { repo } from "@/autogen/openapi";

import metrics from "../metrics/metrics";

const Viewcrumb = (props: { adapterRepo: repo }) => {
    // Parent props:
    const { adapterRepo } = props;

    return (
        <Breadcrumb.Section>
            <Link to={"/adapters/repo/" + adapterRepo?.uuid} className={metrics.BLOCK_AUTO_CAPTURE}>
                <Icon name={"folder outline"} />
                {adapterRepo?.name}
            </Link>
        </Breadcrumb.Section>
    );
};

const AdapterBreadcrumb = (props: {
    adapterRepo?: repo;
    adapterVersionTag?: number;
    archived?: boolean;
    continueMode?: boolean;
    newVersion?: boolean;
}) => {
    // Parent props:
    const { adapterRepo, adapterVersionTag, archived, continueMode, newVersion } = props;

    const isNew = Boolean(newVersion);

    const adapterVersionText = continueMode
        ? "New Adapter (Continue Training)"
        : adapterVersionTag !== undefined && isNew
          ? `New Adapter${adapterVersionTag ? ` (from v${adapterVersionTag})` : ""}`
          : adapterVersionTag !== undefined
            ? `Version ${adapterVersionTag}`
            : "";

    return (
        <Breadcrumb>
            <Breadcrumb.Section>
                <Link to="/adapters" className={metrics.BLOCK_AUTO_CAPTURE}>
                    Adapters
                </Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider />
            {adapterRepo?.uuid && <Viewcrumb adapterRepo={adapterRepo} />}
            {adapterVersionText && (
                <>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section active style={{ fontWeight: "normal" }}>
                        {adapterVersionText} {archived && "(archived)"}
                    </Breadcrumb.Section>
                </>
            )}
        </Breadcrumb>
    );
};

export default AdapterBreadcrumb;
