import { Segment, Table } from "semantic-ui-react";

import LiveTimeAgo from "../../components/LiveTimeAgo";

function ConnectionDetailsTable(props: { connection?: Connection }) {
    if (!props.connection) {
        return null;
    }
    return (
        <div style={{ width: "50%" }}>
            <Table compact>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            <b>ID</b>
                        </Table.Cell>
                        <Table.Cell>{props.connection?.id}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <b>Name</b>
                        </Table.Cell>
                        <Table.Cell>{props.connection?.name}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <b>Type</b>
                        </Table.Cell>
                        <Table.Cell>{props.connection?.type}</Table.Cell>
                    </Table.Row>
                    {!props.connection.restricted && (
                        <>
                            <Table.Row>
                                <Table.Cell>
                                    <b>Config</b>
                                </Table.Cell>
                                <Table.Cell>
                                    <Segment>
                                        <pre style={{ marginBottom: 0 }}>
                                            {JSON.stringify(props.connection?.config, null, 2)}
                                        </pre>
                                    </Segment>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <b>Created</b>
                                </Table.Cell>
                                <Table.Cell>
                                    <LiveTimeAgo fromDate={new Date(props.connection.created)} />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <b>Updated</b>
                                </Table.Cell>
                                <Table.Cell>
                                    <LiveTimeAgo fromDate={new Date(props.connection.updated)} />
                                </Table.Cell>
                            </Table.Row>
                        </>
                    )}
                </Table.Body>
            </Table>
        </div>
    );
}

export default ConnectionDetailsTable;
