import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { useRecoilState } from "recoil";

import { useInviteParams } from "../auth/hooks";
import metrics from "../metrics/metrics";
import { SESSION_STATE, USER_STATE } from "../state/global";

export const useGetSignOut = () => {
    const [, setSession] = useRecoilState(SESSION_STATE);
    const [, setUser] = useRecoilState(USER_STATE);
    const queryClient = useQueryClient();
    const inviteTokenHook = useInviteParams();

    // Auth0 state:
    const { logout } = useAuth0();

    const commonLogout = async () => {
        Sentry.setUser(null);
        // TODO: Unidentify user in June?
        metrics.reset();

        // Invalidate every query in the cache
        // TODO: We should move this to the Login code and only
        // invalidate the queries that are affected when the tenant changes
        queryClient.invalidateQueries();
        queryClient.clear();

        setSession(undefined);
        setUser(undefined);
    };

    const auth0Logout = async () => {
        // Clear out invite tokens in case people get stuck
        inviteTokenHook.clearInviteParams();

        // * NOTE: Logout from Auth0 won't return an error, see:
        // https://community.auth0.com/t/handle-logout-error-in-react-sdk/63235/3
        await logout({
            logoutParams: {
                returnTo: `${window.location.origin}/auth/signin`,
            },
        });

        commonLogout();
    };

    return auth0Logout;
};
