import { useRecoilState } from "recoil";
import { Header, Table, TableBody, TableCell, TableRow } from "semantic-ui-react";

import { tier } from "@/autogen/openapi";

import { SESSION_STATE, USER_STATE } from "../../../state/global";
import { SEMANTIC_GREY } from "../../../utils/colors";
import { formatSubscription } from "../../../utils/subscription";

const UserInfo = () => {
    const [session] = useRecoilState(SESSION_STATE);
    const [userContext] = useRecoilState(USER_STATE);

    let username: string | undefined = "";
    let email: string | undefined = "";
    let firstName: string | undefined = "";
    let lastName: string | undefined = "";
    let subscriptionTier: tier | undefined;
    let shortCode: string | undefined;

    if (session) {
        username = session.nickname;
        email = session?.email;
        firstName = session?.given_name;
        lastName = session?.family_name;
    }

    if (userContext) {
        subscriptionTier = userContext.tenant.tier;
        shortCode = userContext.tenant.shortcode;
    }

    return (
        <>
            <Header as={"h2"} size={"medium"}>
                Overview
            </Header>
            <Table basic={"very"} collapsing compact>
                <TableBody>
                    <TableRow>
                        <TableCell
                            style={{
                                borderTop: "none",
                                color: SEMANTIC_GREY,
                                paddingRight: "40px",
                            }}
                        >
                            Username
                        </TableCell>
                        <TableCell style={{ borderTop: "none" }}>{username}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ borderTop: "none", color: SEMANTIC_GREY, paddingRight: "40px" }}>
                            Email Address
                        </TableCell>
                        <TableCell style={{ borderTop: "none" }}>
                            {email}
                            {/* TODO: Show user status of email verification when we start using it. */}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ borderTop: "none", color: SEMANTIC_GREY, paddingRight: "40px" }}>
                            First Name
                        </TableCell>
                        <TableCell style={{ borderTop: "none" }}>{firstName}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ borderTop: "none", color: SEMANTIC_GREY, paddingRight: "40px" }}>
                            Last Name
                        </TableCell>
                        <TableCell style={{ borderTop: "none" }}>{lastName}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ borderTop: "none", color: SEMANTIC_GREY, paddingRight: "40px" }}>
                            Team
                        </TableCell>
                        <TableCell style={{ borderTop: "none" }}>{userContext?.tenant?.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ borderTop: "none", color: SEMANTIC_GREY, paddingRight: "40px" }}>
                            Tenant ID
                        </TableCell>
                        <TableCell style={{ borderTop: "none" }}>{shortCode}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ borderTop: "none", color: SEMANTIC_GREY, paddingRight: "40px" }}>
                            Subscription
                        </TableCell>
                        <TableCell style={{ borderTop: "none" }}>{formatSubscription(subscriptionTier)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
};

export default UserInfo;
