import { Message } from "semantic-ui-react";

import ModelConfigViewer from "../../../../models/version/ModelConfigViewer";
import { useLegacyModelByIDQuery } from "../../../../prompt/query";

const LegacyConfig = (props: { legacyModelId: string }) => {
    const { legacyModelId } = props;

    const { data: legacyModel } = useLegacyModelByIDQuery(legacyModelId, {
        refetchOnWindowFocus: false,
    });

    return (
        <>
            <Message info>
                <Message.Header>Legacy model</Message.Header>
                <p>This model was created with an older version of our fine-tuning stack.</p>
            </Message>
            <ModelConfigViewer config={legacyModel?.config} />
        </>
    );
};

export default LegacyConfig;
