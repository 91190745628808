import { Header } from "semantic-ui-react";

import { CurrentUser } from "../../types/user";
import { SubscriptionButton } from "./SubscriptionButtons";

import "./GlobalHeader.css";

function GlobalHeader(props: { headerName: string; component: JSX.Element; userContext?: CurrentUser }) {
    const { headerName, component, userContext } = props;

    const tenantTier = userContext?.tenant.tier;

    return (
        <>
            <div className="global-header">
                <Header as="h2" block>
                    {headerName}
                </Header>
                <SubscriptionButton isExpired={userContext?.isExpired} currentTier={tenantTier} />
            </div>
            <div className={"component"}>{component}</div>
        </>
    );
}

export default GlobalHeader;
