import { useState } from "react";

import { Tab, TabProps } from "semantic-ui-react";

import {
    adapterTaskType,
    adapterVersion,
    finetuningJob,
    finetuningJobStatus,
    grpoJobMetrics,
    repo,
    sftMetricsPayload,
} from "@/autogen/openapi";

import { isTerminalJobStatus } from "../../misc/utils";
import { useHistoricalJobMetricsQuery } from "./metrics/query";
import Completions from "./tabs/Completions";
import ErrorLogs from "./tabs/ErrorLogs";
import ImportedAdapterConfigTable from "./tabs/ImportedAdapterConfigTable";
import LegacyConfig from "./tabs/LegacyConfig";
import LossCurves from "./tabs/LossCurves";
import Parameters from "./tabs/Parameters";
import RewardFunctions from "./tabs/RewardFunctions";
import RewardGraphs from "./tabs/RewardGraphs";
import RewardLogs from "./tabs/RewardLogs";
import TurboAccuracyCurves from "./tabs/TurboAccuracyCurves";

import "./AdapterVersionView.css";

const ImportedAdapterTabPanel = (props: { adapterVersion: adapterVersion }) => {
    const { adapterVersion } = props;
    return (
        <Tab
            style={{ position: "relative" }}
            menu={{ secondary: true, pointing: true }}
            panes={[
                {
                    menuItem: "Details",
                    render: () => <ImportedAdapterConfigTable adapterVersion={adapterVersion} />,
                },
            ]}
            activeIndex={0}
        />
    );
};

const TabPanel = (props: {
    adapterVersion: adapterVersion;
    repoUUID: repo["uuid"];
    job: finetuningJob;
    websocketOpen: boolean;
    setWebsocketOpen: React.Dispatch<React.SetStateAction<boolean>>;
    websocketData: sftMetricsPayload[] | grpoJobMetrics[] | undefined;
}) => {
    // Parent state:
    const { adapterVersion, repoUUID, job, websocketOpen, setWebsocketOpen, websocketData } = props;
    const jobStatus = adapterVersion.finetuningJob?.status;

    const { data: historicalData, isLoading: historicalDataIsLoading } = useHistoricalJobMetricsQuery(job.uuid, {
        enabled: isTerminalJobStatus(jobStatus),
    });

    // Declare all possible panes:
    const legacyConfigPane = {
        menuItem: "(Legacy) Config",
        render: () => (
            <Tab.Pane className={`model-tab`} key={"legacyConfig"}>
                <LegacyConfig
                    // @ts-expect-error This tab is tightly controlled, so we will always have a valid legacyModelId when it is shown
                    legacyModelId={adapterVersion.legacyModelId}
                />
            </Tab.Pane>
        ),
    };
    const parametersPane = {
        menuItem: "Parameters",
        render: () => (
            <Tab.Pane className={`model-tab`} key={"parameters"}>
                <Parameters adapterVersion={adapterVersion} />
            </Tab.Pane>
        ),
    };
    const lossCurvesPane = {
        menuItem: "Loss Curves",
        render: () => (
            <Tab.Pane className={`model-tab`} key={"learningCurves"}>
                <LossCurves
                    adapterVersion={adapterVersion}
                    repoUUID={repoUUID}
                    job={job}
                    websocketOpen={websocketOpen}
                    setWebsocketOpen={setWebsocketOpen}
                    websocketData={websocketData as sftMetricsPayload[] | undefined}
                    historicalData={historicalData as sftMetricsPayload[] | undefined}
                    historicalDataIsLoading={historicalDataIsLoading}
                />
            </Tab.Pane>
        ),
    };
    const turboAccuracyPane = {
        menuItem: "Turbo Accuracy Curves",
        render: () => (
            <Tab.Pane className={`model-tab`} key={"turboAccuracy"}>
                <TurboAccuracyCurves
                    adapterVersion={adapterVersion}
                    repoUUID={repoUUID}
                    job={job}
                    websocketOpen={websocketOpen}
                    setWebsocketOpen={setWebsocketOpen}
                    websocketData={websocketData as sftMetricsPayload[] | undefined}
                    historicalData={historicalData as sftMetricsPayload[] | undefined}
                    historicalDataIsLoading={historicalDataIsLoading}
                />
            </Tab.Pane>
        ),
    };
    const grpoLogsPane = {
        menuItem: "Reward Logs",
        render: () => (
            <Tab.Pane className="model-tab" key="grpoLogs">
                <RewardLogs jobUUID={job.uuid} />
            </Tab.Pane>
        ),
    };
    const grpoCompletionsPane = {
        menuItem: "Completions",
        render: () => (
            <Tab.Pane className="model-tab" key="grpoCompletions">
                <Completions job={job} websocketOpen={websocketOpen} streamingData={websocketData} />
            </Tab.Pane>
        ),
    };
    const grpoRewardGraphsPane = {
        menuItem: "Reward Graphs",
        render: () => (
            <Tab.Pane className="model-tab" key="grpoRewardGraphs">
                <RewardGraphs
                    adapterVersion={adapterVersion}
                    repoUUID={repoUUID}
                    job={job}
                    setWebsocketOpen={setWebsocketOpen}
                    websocketData={websocketData as grpoJobMetrics[] | undefined}
                    historicalData={historicalData as grpoJobMetrics[] | undefined}
                    historicalDataIsLoading={historicalDataIsLoading}
                />
            </Tab.Pane>
        ),
    };
    const grpoRewardFunctionsPane = {
        menuItem: "Reward Functions",
        render: () => (
            <Tab.Pane className="model-tab" key="grpoFunctions">
                <RewardFunctions jobUUID={job?.uuid} />
            </Tab.Pane>
        ),
    };
    const errorPane = {
        menuItem: "Error",
        render: () => (
            <Tab.Pane className="model-tab" key="error">
                <ErrorLogs errorMessage={adapterVersion.finetuningJob?.error} />
            </Tab.Pane>
        ),
    };

    // Determine which panes to show based on the adapter and task types:
    const isLegacyAdapter = adapterVersion?.legacyModelId !== undefined;
    const isGRPOTask = adapterVersion.taskType === adapterTaskType.GRPO;
    const isTurboAdapter = job?.params?.adapter === "turbo" || job?.params?.adapter === "turbo_lora";
    const paneConfig = isLegacyAdapter ? "legacy" : isGRPOTask ? "grpo" : isTurboAdapter ? "turbo" : "default";

    // eslint-disable-next-line prefer-const
    let { panes, initialTab } = (() => {
        switch (paneConfig) {
            case "legacy":
                return { panes: [legacyConfigPane, lossCurvesPane], initialTab: 0 };
            case "grpo":
                return adapterVersion.status === finetuningJobStatus.QUEUED
                    ? { panes: [], initialTab: 0 }
                    : {
                          panes: [grpoRewardFunctionsPane, grpoRewardGraphsPane, grpoLogsPane, grpoCompletionsPane],
                          initialTab: 0,
                      };
            case "turbo":
                return { panes: [parametersPane, lossCurvesPane, turboAccuracyPane], initialTab: 2 };
            default:
                return { panes: [parametersPane, lossCurvesPane], initialTab: 1 };
        }
    })();
    if (adapterVersion.status === finetuningJobStatus.ERRORED) {
        panes.push(errorPane);
        initialTab = panes.length - 1;
    }

    // Tab state:
    const [tabIndex, setTabIndex] = useState(initialTab);

    return (
        <Tab
            hidden={isGRPOTask && adapterVersion.status === finetuningJobStatus.QUEUED}
            style={{ position: "relative" }}
            menu={{ secondary: true, pointing: true }}
            panes={panes}
            activeIndex={tabIndex}
            onTabChange={(e: React.MouseEvent<HTMLDivElement>, { activeIndex }: TabProps) => {
                const idx = Number(activeIndex);
                setTabIndex(idx);
            }}
        />
    );
};

const AdapterTabPanel = (props: {
    adapterVersion: adapterVersion;
    repoUUID: repo["uuid"];
    websocketOpen: boolean;
    setWebsocketOpen: React.Dispatch<React.SetStateAction<boolean>>;
    websocketData: sftMetricsPayload[] | grpoJobMetrics[] | undefined;
}) => {
    const { adapterVersion, repoUUID, websocketOpen, setWebsocketOpen, websocketData } = props;
    return (
        <>
            {/* TODO: Probably want repo uuid on the adapterVersionListItem so we don't have to plumb it: */}
            {adapterVersion.finetuningJob ? (
                <TabPanel
                    adapterVersion={adapterVersion}
                    repoUUID={repoUUID}
                    job={adapterVersion.finetuningJob}
                    websocketOpen={websocketOpen}
                    setWebsocketOpen={setWebsocketOpen}
                    websocketData={websocketData}
                />
            ) : (
                <ImportedAdapterTabPanel adapterVersion={adapterVersion} />
            )}
        </>
    );
};

export default AdapterTabPanel;
