import { finetuningJobStatus } from "@/autogen/openapi";

import {
    SEMANTIC_BLUE_ACTIVE,
    SEMANTIC_GREY,
    SEMANTIC_GREY_DISABLED,
    SEMANTIC_OLIVE_ACTIVE,
    SEMANTIC_RED_ACTIVE,
} from "../../utils/colors";
import { capitalize } from "../../utils/strings";

export const getColorAndOpacityForStatus = (status: finetuningJobStatus) => {
    let textColor = undefined;
    let opacity = 0.1;
    switch (status) {
        case finetuningJobStatus.ERRORED:
            textColor = SEMANTIC_RED_ACTIVE;
            break;
        case finetuningJobStatus.TRAINING:
            textColor = SEMANTIC_OLIVE_ACTIVE;
            break;
        case finetuningJobStatus.COMPLETED:
        case finetuningJobStatus.STOPPED:
            textColor = SEMANTIC_BLUE_ACTIVE;
            opacity = 0.3;
            break;
        case finetuningJobStatus.QUEUED:
        case finetuningJobStatus.PENDING:
        case finetuningJobStatus.STOPPING:
            textColor = SEMANTIC_GREY_DISABLED;
            break;
        case finetuningJobStatus.CANCELED:
            textColor = SEMANTIC_GREY;
            break;
        default:
            textColor = SEMANTIC_GREY;
            break;
    }
    return { textColor, opacity };
};

const JobStatusChip = (props: { status?: finetuningJobStatus | null; width?: string }) => {
    const { status, width } = props;
    const { textColor: color, opacity } = getColorAndOpacityForStatus(status ?? finetuningJobStatus.PENDING);
    return <span style={{ color, width }}>{capitalize(status ?? "—")}</span>;
};

export default JobStatusChip;
