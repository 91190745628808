/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Message, Modal } from "semantic-ui-react";

import { deployment } from "@/autogen/openapi";

import { useAuth0TokenOptions } from "../../../data";
import { GET_DEPLOYMENTS_QUERY_KEY } from "../../../query";
import { getErrorMessage } from "../../../utils/errors";
import { deleteDeployment } from "./../../data/data";
import { useDeploymentQuery } from "./../../data/query";

const GET_DEPLOYMENT_POLLING_INTERVAL_MILLISECONDS = 3 * 1000; // 3 seconds

const DeleteDeploymentModal = (props: {
    deployment: deployment;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onDelete?: () => void;
}) => {
    // Parent state:
    const { deployment, open, setOpen, onDelete } = props;

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Local state:
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [deploymentIsBeingDeleted, setDeploymentIsBeingDeleted] = useState<boolean>(false);

    // Query state:
    const queryClient = useQueryClient();

    const { error: getDeploymentError } = useDeploymentQuery(deployment.uuid, {
        enabled: deploymentIsBeingDeleted,
        retry: false,
        refetchInterval: GET_DEPLOYMENT_POLLING_INTERVAL_MILLISECONDS,
    });

    useEffect(() => {
        if (getDeploymentError) {
            queryClient.invalidateQueries({
                queryKey: GET_DEPLOYMENTS_QUERY_KEY,
            });
            setDeploymentIsBeingDeleted(false);
            setOpen(false);
        }
    }, [getDeploymentError]);

    // TODO: What should happen if there is an error?
    const { mutate: mutateDeleteDeployment, reset: resetMutation } = useMutation({
        mutationFn: (uuid: string) => deleteDeployment(uuid, auth0TokenOptions),
        onError: (error) => {
            setErrorMessage(getErrorMessage(error));
        },
        onSuccess: () => {
            onDelete?.();
        },
    });

    return (
        <Modal
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
                resetMutation();
            }}
            open={open}
        >
            <Modal.Header>Delete this deployment</Modal.Header>
            <Modal.Content>
                <span>
                    Are you sure you'd like to delete your private serverless deployment <b>{deployment.name}</b>? This
                    action cannot be undone and will shut down the endpoint.
                </span>
                {errorMessage ? <Message negative>{errorMessage}</Message> : null}
            </Modal.Content>

            {/* // Bottom bar with buttons: */}
            <Modal.Actions>
                <Button
                    onClick={() => {
                        setOpen(false);
                        resetMutation();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    className={deploymentIsBeingDeleted ? "loading" : ""}
                    color={"red"}
                    onClick={() => {
                        resetMutation();
                        mutateDeleteDeployment(deployment.uuid);
                        setDeploymentIsBeingDeleted(true);
                    }}
                    disabled={deploymentIsBeingDeleted}
                >
                    Delete
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteDeploymentModal;
