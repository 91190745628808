import { adapterVersion } from "@/autogen/openapi";

import { useBaseModelsQuery } from "../../../../deployments/data/query";
import ContinueTrainingForm from "../../create/ContinueTrainingForm";
import ParametersForm from "../../create/ParametersForm";
import { AdapterConfig } from "../../create/schema";
import { ConfigProvider } from "../../create/store";
import { adapterTypes } from "../../create/utils";

const FormSelector = (props: { adapterVersion: adapterVersion }) => {
    // Parent state:
    const { adapterVersion } = props;

    // Query state:
    // NOTE: If this query fails, it doesn't hurt the user experience
    // (we can still show parameters via the adapterVersion object)
    // so we ignore the error.
    const { data: baseModels } = useBaseModelsQuery({
        refetchOnWindowFocus: false,
    });

    const isContinuedAdapter = adapterVersion.baseVersionTag !== undefined;

    return (
        <>
            {isContinuedAdapter ? (
                <ContinueTrainingForm
                    baseModels={baseModels ?? []}
                    modelDescription={adapterVersion.description ?? ""}
                    setModelDescription={() => {}}
                    readOnly={true}
                    paramsFromJob={adapterVersion.finetuningJob?.params as AdapterConfig | undefined}
                    parentAdapterType={adapterVersion.adapterType as adapterTypes | undefined}
                    parentAndChildDatasetsMatch={undefined} // TODO
                />
            ) : (
                <ParametersForm
                    baseModels={baseModels ?? []}
                    modelDescription={adapterVersion.description ?? ""}
                    setModelDescription={() => {}}
                    readOnly={true}
                    paramsFromJob={adapterVersion.finetuningJob?.params as AdapterConfig | undefined}
                />
            )}
        </>
    );
};

const Parameters = (props: { adapterVersion: adapterVersion }) => {
    return (
        <ConfigProvider>
            <FormSelector adapterVersion={props.adapterVersion} />
        </ConfigProvider>
    );
};

export default Parameters;
