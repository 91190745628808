import { useReducer } from "react";

import { createContainer } from "react-tracked";

import { adapter, deployment } from "@/autogen/openapi";

export type TemplateVariable = {
    index: number;
    skipLength: number;
    value: string;
};
export type State = {
    temperature: number;
    maxNewTokens: number;
    doSample: boolean;
    topP: number;
    selectedDeployment: deployment | null;
    selectedAdapter: adapter | null;
    prompt: string;
    promptTemplate: string | null;
    promptTemplateVariables: { [key: string]: TemplateVariable };
};
type GenericStateUpdate = Partial<State>;
const initialState: State = {
    temperature: 1,
    maxNewTokens: 512,
    doSample: false,
    topP: 1,
    selectedDeployment: null,
    selectedAdapter: null,
    prompt: "",
    promptTemplate: null,
    promptTemplateVariables: {},
};

export enum ActionType {
    INIT = "INIT",
    UPDATE = "UPDATE",
}
type InitAction = { type: ActionType.INIT };
type UpdateAction = { type: ActionType.UPDATE } & GenericStateUpdate;
export type Action = InitAction | UpdateAction;

export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "INIT":
            return {
                ...initialState,
            };
        case "UPDATE":
            const { type: _ignore, ...stateUpdate } = action;
            return {
                ...state,
                ...stateUpdate,
            };
        default:
            return state;
    }
};

const useValue = () => useReducer(reducer, initialState);
const { Provider, useTrackedState, useUpdate } = createContainer(useValue);

export { Provider as PromptStateProvider, useUpdate as useDispatch, useTrackedState as usePromptState };
