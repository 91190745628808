import { Divider, Header } from "semantic-ui-react";
import urlJoin from "url-join";

import { getDocsHome } from "../../../../utils/api";
import { SEMANTIC_GREY } from "../../../../utils/colors";
import { isProduction } from "../../../../utils/environment";

const getAWSLink = (shortCode: string, envRegion: string) => {
    if (isProduction()) {
        return `https://${envRegion}.console.aws.amazon.com/cloudformation/home?region=${envRegion}#/stacks/quickcreate?templateUrl=https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fpredibase-public-us-west-2%2Finfrastructure%2Fdataplane-preprovision.yaml&stackName=predibase-dataplane-${shortCode}&param_EnvironmentID=${shortCode}&param_PredibaseDomain=${window.location.host}`;
    } else {
        return `https://${envRegion}.console.aws.amazon.com/cloudformation/home?region=${envRegion}#/stacks/quickcreate?templateUrl=https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fpredibase-public-us-west-2%2Finfrastructure%2Fstaging-dataplane-preprovision.yaml&stackName=predibase-dataplane-${shortCode}&param_EnvironmentID=${shortCode}&param_PredibaseDomain=${window.location.host}`;
    }
};

const AWSDetailsForm = (props: { envShortCode: string; region: string }) => {
    const { envShortCode, region } = props;

    return (
        <>
            <Header as="h3" textAlign="center">
                Configure your AWS account
            </Header>
            <b>Selected region:</b> {region}
            <Divider hidden />
            Please follow the steps below to create a Predibase environment that is deployed within your Amazon Web
            Services (AWS) account.
            <Divider hidden />
            <ol style={{ textAlign: "left", paddingLeft: "20px" }}>
                <li>
                    Navigate to this{" "}
                    <a href={getAWSLink(envShortCode, region)} target="_blank" rel="noreferrer">
                        direct link
                    </a>{" "}
                    to create a Predibase-enabled CloudFormation stack in your AWS account. The required fields are
                    pre-populated so no additional input is needed.
                </li>
                <li style={{ paddingTop: "10px" }}>
                    Scroll down to the bottom of that page and click the checkbox to confirm the CloudFormation
                    acknowledgment.
                </li>
                <li style={{ paddingTop: "10px" }}>
                    On that page, click <strong>“Create stack”</strong>.
                </li>
                <li style={{ paddingTop: "10px" }}>
                    After your CloudFormation stack has been created (about 2 minutes), navigate to the “Outputs” tab
                    and copy the value for <strong>CustomerEnvironmentRoleArn</strong>. Paste that value below.
                </li>
            </ol>
            <div style={{ color: SEMANTIC_GREY, fontSize: "12" }}>
                For more detailed instructions/screenshots, see our{" "}
                {
                    <a
                        href={urlJoin(getDocsHome(), "/user-guide/admin/vpc-provisioning/aws")}
                        target="_blank"
                        rel="noopener"
                    >
                        docs
                    </a>
                }
                .
            </div>
        </>
    );
};

export default AWSDetailsForm;
