import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRecoilState } from "recoil";
import { Button, Icon, Popup } from "semantic-ui-react";

import { track } from "../metrics/june";
import metrics from "../metrics/metrics";
import { USER_STATE } from "../state/global";
import { getDocsHome } from "../utils/api";
import { usePromptState } from "./utils/reducer";

const PromptTemplateDocsLink = () => {
    const [user] = useRecoilState(USER_STATE);
    const url = getDocsHome() + "/user-guide/fine-tuning/prompt_templates";

    return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
            href={url}
            target="_blank"
            rel="noopener"
            onClick={() => {
                metrics.capture("docs", {
                    url,
                    clickSource: "prompt-view",
                });
                user &&
                    track(user, "docs", {
                        url,
                        clickSource: "prompt-view",
                    });
            }}
        >
            docs
        </a>
    );
};

const PromptTemplateTooltip = () => {
    return (
        <Popup
            className="transition-scale"
            hoverable
            wide={"very"}
            position={"right center"}
            trigger={<Icon name={"question circle"} color={"grey"} />}
            content={
                <span>
                    Your prompt template defines how columns from your dataset should be dynamically injected into your
                    prompt at query time against an LLM. Please see the <PromptTemplateDocsLink /> for more information.
                </span>
            }
        />
    );
};

const PromptTemplate = (props: {
    promptTemplateVisible: boolean;
    setPromptTemplateVisible: (value: React.SetStateAction<boolean>) => void;
}) => {
    const { setPromptTemplateVisible } = props;
    const { promptTemplate } = usePromptState();

    return (
        <>
            <div style={{ marginBottom: "1rem" }}>
                <span style={{ fontWeight: 700 }}>
                    Prompt Template <PromptTemplateTooltip />
                </span>
                <Button
                    style={{
                        display: "flex",
                        float: "right",
                        backgroundColor: "#F7F7F7",
                        marginLeft: "auto",
                        padding: 0,
                    }}
                    onClick={() => {
                        setPromptTemplateVisible(false);
                    }}
                >
                    <FontAwesomeIcon icon="down-left-and-up-right-to-center" />
                </Button>
            </div>

            <div style={{ whiteSpace: "pre-line" }}>{promptTemplate}</div>
        </>
    );
};

export default PromptTemplate;
