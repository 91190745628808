import { useContext, useState } from "react";

import { upperFirst } from "lodash";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Header, Table } from "semantic-ui-react";

import { deployment, deploymentQuantization, deploymentType } from "@/autogen/openapi";

import { SEMANTIC_GREY, SEMANTIC_RED_ACTIVE } from "../../../../utils/colors";
import { FeatureFlagsContext, POSTHOG_FEATURE_FLAG, getPosthogFeatureFlag } from "../../../../utils/feature-flags";
import { capitalize } from "../../../../utils/strings";
import DeleteDeploymentModal from "../../modals/DeleteDeploymentModal";

const DeploymentConfig = (props: { deployment?: deployment }) => {
    const { deployment } = props;
    const headerCellStyle = { border: 0, color: SEMANTIC_GREY, paddingRight: "40px" };
    const standardCellStyle = { border: 0 };

    // Context state:
    const { posthogFeatureFlags } = useContext(FeatureFlagsContext);
    const showReplicasCount = getPosthogFeatureFlag(POSTHOG_FEATURE_FLAG.ShowReplicasCount, posthogFeatureFlags);

    const navigate = useNavigate();

    // Local state:
    const [deleteDeploymentModalOpen, setDeleteDeploymentModalOpen] = useState(false);

    // Derived state:
    const speculator = deployment?.config?.speculator;
    // Filter out adapter source and hub from custom args if speculator is auto
    const indexOfAdapterSource = deployment?.config?.customArgs?.indexOf("--adapter-source");
    const adapterSourceIsHub =
        indexOfAdapterSource !== undefined && deployment?.config?.customArgs?.[indexOfAdapterSource + 1] === "hub";
    const customArgs =
        deployment?.config?.customArgs && speculator?.toLowerCase() === "auto"
            ? adapterSourceIsHub
                ? deployment.config.customArgs
                      .slice(0, indexOfAdapterSource)
                      .concat(deployment.config.customArgs.slice(indexOfAdapterSource + 2))
                : deployment.config.customArgs
            : (deployment?.config?.customArgs ?? []);

    return (
        <>
            <Table basic="very" collapsing compact>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell style={headerCellStyle}>Name</Table.Cell>
                        <Table.Cell style={standardCellStyle}>{deployment?.name}</Table.Cell>
                    </Table.Row>
                    {deployment?.type === deploymentType.DEDICATED && (
                        <Table.Row>
                            <Table.Cell style={headerCellStyle}>Base model</Table.Cell>
                            <Table.Cell style={standardCellStyle}>{deployment?.model?.name}</Table.Cell>
                        </Table.Row>
                    )}
                    {deployment?.type === deploymentType.DEDICATED && (
                        <Table.Row>
                            <Table.Cell style={headerCellStyle}>Accelerator</Table.Cell>
                            <Table.Cell style={standardCellStyle}>{deployment?.accelerator?.name}</Table.Cell>
                        </Table.Row>
                    )}
                    {deployment?.type === deploymentType.DEDICATED && deployment?.quantization && (
                        <Table.Row>
                            <Table.Cell style={headerCellStyle}>Quantization</Table.Cell>
                            <Table.Cell style={standardCellStyle}>
                                {deployment?.quantization === deploymentQuantization.NONE
                                    ? "-"
                                    : upperFirst(deployment.quantization)}
                            </Table.Cell>
                        </Table.Row>
                    )}
                    {deployment?.type === deploymentType.DEDICATED && showReplicasCount && (
                        <Table.Row>
                            <Table.Cell style={headerCellStyle}>Min replicas</Table.Cell>
                            <Table.Cell style={standardCellStyle}>{deployment?.config?.minReplicas}</Table.Cell>
                        </Table.Row>
                    )}
                    {deployment?.type === deploymentType.DEDICATED && showReplicasCount && (
                        <Table.Row>
                            <Table.Cell style={headerCellStyle}>Max Replicas</Table.Cell>
                            <Table.Cell style={standardCellStyle}>{deployment?.config?.maxReplicas}</Table.Cell>
                        </Table.Row>
                    )}
                    {deployment?.type === deploymentType.DEDICATED && (
                        <Table.Row>
                            <Table.Cell style={headerCellStyle}>Scale up threshold</Table.Cell>
                            <Table.Cell style={standardCellStyle}>
                                {deployment?.config?.scaleUpRequestThreshold}
                            </Table.Cell>
                        </Table.Row>
                    )}
                    <Table.Row>
                        <Table.Cell style={headerCellStyle}>Cooldown time (s)</Table.Cell>
                        <Table.Cell style={standardCellStyle}>{deployment?.config?.cooldownTime}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={headerCellStyle}>Max input length</Table.Cell>
                        <Table.Cell style={standardCellStyle}>{deployment?.model?.maxInputLength}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={headerCellStyle}>Max total tokens</Table.Cell>
                        <Table.Cell style={standardCellStyle}>{deployment?.model?.maxTotalTokens}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={headerCellStyle}>Max batch prefill tokens</Table.Cell>
                        <Table.Cell style={standardCellStyle}>{deployment?.model?.maxBatchPrefillTokens}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={headerCellStyle}>Preloaded Adapters</Table.Cell>
                        <Table.Cell style={standardCellStyle}>
                            {deployment?.config?.preloadedAdapters?.length
                                ? deployment?.config?.preloadedAdapters?.join(", ")
                                : "-"}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={headerCellStyle}>Speculator</Table.Cell>
                        <Table.Cell style={standardCellStyle}>
                            {deployment?.config?.speculator?.includes("/")
                                ? deployment.config.speculator
                                : capitalize(deployment?.config?.speculator, true)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={headerCellStyle}>Prefix Caching</Table.Cell>
                        <Table.Cell style={standardCellStyle}>
                            {deployment?.config?.prefixCaching ? "Enabled" : "Disabled"}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={headerCellStyle}>Request Logging</Table.Cell>
                        <Table.Cell style={standardCellStyle}>
                            {deployment?.config?.requestLoggingEnabled ? "Enabled" : "Disabled"}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={headerCellStyle}>Direct Ingress</Table.Cell>
                        <Table.Cell style={standardCellStyle}>
                            {deployment?.config?.directIngress ? "Enabled" : "Disabled"}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={headerCellStyle}>Lorax Version</Table.Cell>
                        <Table.Cell style={standardCellStyle}>{deployment?.config?.loraxImageTag}</Table.Cell>
                    </Table.Row>
                    {deployment?.type === deploymentType.DEDICATED && customArgs.length > 0 && (
                        <Table.Row>
                            <Table.Cell style={headerCellStyle}>Custom Args</Table.Cell>
                            <Table.Cell style={standardCellStyle}>{customArgs?.join(" ")}</Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
            {deployment?.type === deploymentType.DEDICATED && (
                <>
                    <Divider />

                    <Header as={"h2"} size={"medium"} style={{ color: SEMANTIC_RED_ACTIVE, marginBottom: 0 }}>
                        Delete Deployment
                    </Header>
                    <span
                        style={{
                            color: SEMANTIC_GREY,
                            paddingBottom: `${10 / 14}rem`,
                            display: "block",
                            fontSize: "0.9em",
                        }}
                    >
                        This action is final and cannot be undone.
                    </span>
                    <Button
                        content="Delete Deployment"
                        icon="trash"
                        negative
                        style={{ marginBottom: `${10 / 14}rem` }}
                        onClick={() => setDeleteDeploymentModalOpen(true)}
                    />
                    <DeleteDeploymentModal
                        deployment={deployment}
                        open={deleteDeploymentModalOpen}
                        setOpen={setDeleteDeploymentModalOpen}
                        onDelete={() => {
                            navigate("/deployments");
                        }}
                    />
                </>
            )}
        </>
    );
};

export default DeploymentConfig;
