import {
    deploymentEventUpdate,
    deploymentMetrics,
    deploymentStatusUpdate,
    prometheusInstantVector,
    prometheusRangeVector,
} from "@/autogen/openapi";

export type deploymentUpdate = deploymentEventUpdate | deploymentStatusUpdate;

/**
 * Returns true if the provided JSON represents a deployment event status message from the Gateway. Otherwise, the JSON
 * represents deployment status heartbeat message and this function returns false.
 */
export const isDeploymentEventUpdate = (data: deploymentUpdate): data is deploymentEventUpdate => {
    return data.hasOwnProperty("eventType");
};

// Deployment metrics types:

export type unixEpoch = number; // integer
export type stringifiedValue = string; // float or NaN
export type typesafeDeploymentMetricsTuple = [unixEpoch, stringifiedValue];

// NOTE: prometheus types are too loose for now (arrays instead of tuples), so this clarifies the matter for TS:
export type typesafeDeploymentMetrics = {
    [key in keyof deploymentMetrics]: deploymentMetrics[key] extends prometheusRangeVector
        ? typesafeDeploymentMetricsTuple[]
        : deploymentMetrics[key] extends prometheusInstantVector
          ? typesafeDeploymentMetricsTuple
          : deploymentMetrics[key];
};
