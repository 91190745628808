import { Popup } from "semantic-ui-react";

import Chip from "../../components/Chip";
import { SEMANTIC_BLUE, SEMANTIC_BLUE_ACTIVE } from "../../utils/colors";

const GuaranteedDeploymentChip = () => {
    let color = SEMANTIC_BLUE;
    let textColor = SEMANTIC_BLUE_ACTIVE;
    let opacity = 0.1;
    return (
        <Popup
            className="transition-scale"
            hoverable
            wide={"very"}
            position={"right center"}
            trigger={
                <span>
                    <Chip
                        color={color}
                        text={"Guaranteed"}
                        textColor={textColor}
                        opacity={opacity}
                        width={`${100 / 14}rem`}
                    />
                </span>
            }
            content={
                <span>
                    {"Guaranteed deployments are backed by an SLA and will scale up faster than regular deployments"}
                </span>
            }
        />
    );
};

export default GuaranteedDeploymentChip;
