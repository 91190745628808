import { useEffect } from "react";

import dagre from "dagre";
import { capitalize } from "lodash";
import ReactFlow, {
    Edge,
    Node,
    Position,
    ReactFlowProvider,
    isNode,
    useEdgesState,
    useNodesState,
} from "react-flow-renderer";
import { Icon, Message, Popup } from "semantic-ui-react";

import {
    finetuningJob,
    finetuningJobStatus,
    finetuningJobTimeline,
    finetuningJobTimelineEntry,
} from "@/autogen/openapi";

import CountUpTimer from "../../../components/unsorted/history/CountUpTimer";
import { SEMANTIC_BLACK, SEMANTIC_BLUE, SEMANTIC_GREEN, SEMANTIC_RED } from "../../../utils/colors";
import dayjsExtended, { durationAsString, durationCalculation } from "../../../utils/dayjs";
import { isFailedOrCanceledJobStatus, isTerminalJobStatus } from "../../misc/utils";

/**
 * TODO: We need to find a simpler tool to do this, this is too complex.
 */

const FineTuningJobTimelineStatus = [
    finetuningJobStatus.QUEUED,
    finetuningJobStatus.TRAINING,
    finetuningJobStatus.COMPLETED,
];

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const position = { x: 0, y: 0 };

const nodeProps = (index: number, label: React.ReactNode, style: React.CSSProperties) => {
    return {
        id: String(index),
        data: { label },
        style,
        position,
    };
};

const getNode = (
    currentStatus: finetuningJobStatus,
    step: finetuningJobStatus,
    index: number,
    trainingJobStatus: finetuningJobStatus,
    trainingJobEndedAt?: string,
    timelineEntry?: finetuningJobTimelineEntry,
) => {
    const stepName = capitalize(step);

    if (!timelineEntry) {
        return nodeProps(
            index,
            <span>
                <b>{stepName}</b>
            </span>,
            { opacity: 0.2 },
        );
    }

    const created = dayjsExtended(new Date(timelineEntry.startedAt));
    // This is a hack to show the duration of the last step
    // TODO: This should be done in the backend
    if (timelineEntry.startedAt && !timelineEntry.endedAt && isTerminalJobStatus(trainingJobStatus)) {
        timelineEntry.endedAt = trainingJobEndedAt;
    }
    const duration = durationCalculation(timelineEntry.startedAt, timelineEntry.endedAt);
    const durationString = durationAsString(duration);

    // Ready is the last step, so we don't need to show the duration
    if (step === finetuningJobStatus.COMPLETED) {
        if (isTerminalJobStatus(trainingJobStatus) && !isFailedOrCanceledJobStatus(trainingJobStatus)) {
            return nodeProps(
                index,
                <>
                    <Icon name="check" style={{ color: "white" }} />
                    <span style={{ color: "white" }}>
                        <b>{stepName}</b>
                    </span>
                </>,
                { backgroundColor: SEMANTIC_GREEN },
            );
        }

        return nodeProps(
            index,
            <>
                <Icon name="close" style={{ color: "white" }} />
                <span style={{ color: "white" }}>
                    <b>{stepName}</b>
                </span>
            </>,
            { backgroundColor: SEMANTIC_RED },
        );
    }

    if (timelineEntry.error) {
        let error = timelineEntry.error;
        // Some errors can be JSON
        try {
            const errorJson = JSON.parse(error);
            error = JSON.stringify(errorJson, null, 2);
        } catch (e) {}

        return nodeProps(
            index,
            <Popup
                hoverable
                wide={"very"}
                offset={[0, 10]}
                className={"transition-scale"}
                position={"top center"}
                content={
                    <Message negative style={{ overflowY: "auto", maxHeight: "30vh" }}>
                        {error}
                    </Message>
                }
                trigger={
                    <div>
                        <Icon name={"close"} style={{ color: "white" }} />
                        <span style={{ color: "white" }}>
                            <b>{stepName}</b>
                        </span>
                        <div className={"model-progress-subtext"}>{durationString}</div>
                    </div>
                }
            ></Popup>,
            { backgroundColor: SEMANTIC_RED },
        );
    }

    if (timelineEntry.endedAt) {
        return nodeProps(
            index,
            <>
                <Icon name={"check"} style={{ color: "white" }} />
                <span style={{ color: "white" }}>
                    <b>{stepName}</b>
                </span>
                <div className={"model-progress-subtext"}>{durationString}</div>
            </>,
            { backgroundColor: SEMANTIC_GREEN },
        );
    }

    if (step === currentStatus) {
        return nodeProps(
            index,
            <>
                <span style={{ color: SEMANTIC_BLUE }}>
                    <b>{stepName}</b>
                </span>
                <div className={"model-progress-subtext"}>
                    <CountUpTimer start={created} color={SEMANTIC_BLACK} posLoaderLeft={true} />
                </div>
            </>,
            { borderColor: SEMANTIC_BLUE },
        );
    }

    return nodeProps(
        index,
        <>
            <span>
                <b>{stepName}</b>
            </span>
            <div className={"model-progress-subtext"}>
                <CountUpTimer start={created} color={SEMANTIC_BLACK} posLoaderLeft={true} />
            </div>
        </>,
        { opacity: 0.2 },
    );
};

const getTrainingJobProgressNodes = (job: finetuningJob, timeline?: finetuningJobTimeline): Node[] => {
    return FineTuningJobTimelineStatus.map((step, index) =>
        getNode(job.status, step, index, job.status, job?.endedAt, timeline?.[step]),
    );
};

const getTrainingJobProgressEdges = (): Edge[] => {
    return FineTuningJobTimelineStatus.map((_, stepValue) => {
        const source = String(stepValue);
        const target = String(stepValue + 1);
        return {
            id: source + "-" + target,
            source: source,
            target: target,
            markerEnd: { type: "arrow" },
            style: { strokeWidth: "2px" },
            position,
        } as Edge;
    });
};

const getLayoutElements = (nodes: Node[], edges: Edge[], direction = "LR") => {
    const isHorizontal = direction === "LR";
    const nodeWidth = isHorizontal ? 160 : 120;
    const nodeHeight = isHorizontal ? 120 : 140;

    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight }));
    edges.forEach((edge) => dagreGraph.setEdge(edge.source, edge.target));
    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
        if (isNode(node)) {
            const nodeWithPosition = dagreGraph.node(node.id);
            node.targetPosition = isHorizontal ? Position.Left : Position.Top;
            node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

            node.position = {
                x: nodeWithPosition.x - nodeWidth / 2,
                y: nodeWithPosition.y - nodeHeight / 2,
            };
            return node;
        }
    });

    return { nodes, edges };
};

const FinetuningProgressFlow = (props: { job: finetuningJob; timeline?: finetuningJobTimeline }) => {
    // Parent props:
    const { job, timeline } = props;

    // Local state:
    const [nodes, setNodes, onNodesChange] = useNodesState(getTrainingJobProgressNodes(job, timeline));
    const [edges, setEdges, onEdgesChange] = useEdgesState(getTrainingJobProgressEdges());

    useEffect(() => {
        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutElements(
            getTrainingJobProgressNodes(job, timeline),
            edges,
        );
        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
    }, [timeline]);

    if (nodes === undefined || edges === undefined) {
        return null;
    }

    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodesDraggable={false}
            zoomOnPinch={false}
            zoomOnDoubleClick={false}
            zoomOnScroll={false}
            preventScrolling={false}
            panOnDrag={false}
            fitView
            fitViewOptions={{ maxZoom: 1 }}
        ></ReactFlow>
    );
};

const FinetuningJobProgressView = (props: { job: finetuningJob; timeline?: finetuningJobTimeline; open?: boolean }) => {
    // Parent props:
    const { job, timeline, open } = props;

    return (
        <div
            style={{
                height: "15vh",
                minHeight: "220px",
                background: "white",
                border: "1px solid rgba(34,36,38,.15)",
                borderRadius: "4px",
            }}
        >
            {/**
             * ? NOTE: When the parent Accordion is closed on page load (i.e. for completed jobs), ReactFlow seems
             * to render the nodes off-screen. When the user then expands the accordion, the position does not update -
             * probably due to some subtle interaction between the parent viewport and the ReactFlow viewport. The
             * `open` prop here is used to force a re-render:
             * */}
            {open ? (
                <ReactFlowProvider>
                    <FinetuningProgressFlow job={job} timeline={timeline} />
                </ReactFlowProvider>
            ) : null}
        </div>
    );
};

export default FinetuningJobProgressView;
