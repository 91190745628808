import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { currentUser, deployment } from "@/autogen/openapi";

import {
    Environment,
    EnvironmentResponse,
    UserCredits,
    getDeployments,
    getEnvironment,
    getEnvironments,
    getUserContextV2,
    getUserCredits,
    useAuth0TokenOptions,
} from "./data";

// Constants:
export const USERS_CONSTANT = "users";
export const ENVIRONMENTS_CONSTANT = "environments";
export const DEPLOYMENTS_CONSTANT = "deployments";
export const ADAPTERS_CONSTANT = "adapters";
export const MODEL_REPOS_CONSTANT = "model-repos";
export const CREDITS_CONSTANT = "credits";

// Queries:
export const GET_USER_CONTEXT_QUERY_KEY_V2 = [USERS_CONSTANT, "v2", "context"];
export const useUserContextQueryV2 = (options?: Partial<UseQueryOptions<currentUser>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<currentUser>({
        queryKey: GET_USER_CONTEXT_QUERY_KEY_V2,
        queryFn: () => getUserContextV2(auth0TokenOptions),
        retry: false,
        ...options,
    });
};

export const GET_ENVIRONMENTS_QUERY_KEY = [ENVIRONMENTS_CONSTANT];
export const useEnvironmentsQuery = (options?: Partial<UseQueryOptions<Environment[]>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<Environment[]>({
        queryKey: GET_ENVIRONMENTS_QUERY_KEY,
        queryFn: () => getEnvironments(auth0TokenOptions),
        retry: false,
        ...options,
    });
};

export const GET_ENVIRONMENT_QUERY_KEY = (shortCode: string) => [ENVIRONMENTS_CONSTANT, shortCode];
export const useEnvironmentQuery = (shortCode: string, options?: Partial<UseQueryOptions<EnvironmentResponse>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<EnvironmentResponse>({
        queryKey: GET_ENVIRONMENT_QUERY_KEY(shortCode),
        queryFn: () => getEnvironment(shortCode, auth0TokenOptions),
        ...options,
    });
};

export const GET_DEPLOYMENTS_QUERY_KEY = [DEPLOYMENTS_CONSTANT];
// TODO: Remove null when we fix gateway middleware!
export const useDeploymentsQuery = (options?: Partial<UseQueryOptions<deployment[] | null>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<deployment[] | null>({
        queryKey: GET_DEPLOYMENTS_QUERY_KEY,
        queryFn: () => getDeployments(auth0TokenOptions),
        ...options,
    });
};

export const GET_USER_CREDITS_QUERY_KEY = [USERS_CONSTANT, CREDITS_CONSTANT];
export const useUserCreditsQuery = (options?: Partial<UseQueryOptions<UserCredits>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<UserCredits>({
        queryKey: GET_USER_CREDITS_QUERY_KEY,
        queryFn: () => getUserCredits(auth0TokenOptions),
        retry: false,
        ...options,
    });
};
