import React, { useEffect, useState } from "react";

import { Breadcrumb, Button, Icon, Modal } from "semantic-ui-react";

import metrics from "../../metrics/metrics";
import ConnectorCardPanel from "./ConnectorCardPanel";
import { Connector, getConnectorFromName, getDatasetCreatorFromName } from "./util";

function ConnectDataModal(props: { onSubmit: (datasetID: number) => void }) {
    const [open, setOpen] = useState(false);
    const [step, setStep] = useState(0);

    // TODO: unsafe initial value (undefined):
    const [connection, setConnection] = useState<Connection>();
    const [connector, setConnector] = useState<string>(); // step 1
    const [connectorConfig, setConnectorConfig] = useState<ConnectorConfig>({}); // step 2
    const [connectionSchema, setConnectionSchema] = useState<ConnectionSchema | null>(null); // step 3

    useEffect(() => {
        if (step === 0) {
            setConnection(undefined);
            setConnector(undefined);
            setConnectorConfig({});
            setConnectionSchema(null);
        }
    }, [step]);

    const onConnectorClick = (connector: string) => {
        setConnector(connector);
        setStep(1);
    };

    const reset = () => {
        setConnection(undefined);
        setConnector(undefined);
        setConnectorConfig({});
        setConnectionSchema(null);
        setStep(0);

        setOpen(false);
    };

    const onCredentialsSubmit = (connectionID: number) => {
        props.onSubmit(connectionID);
        if (connector === "file" || connector === "public_datasets") {
            props.onSubmit(0);
            reset();
        } else {
            setStep(2);
        }
    };

    const onDatasetsSubmit = () => {
        props.onSubmit(0);
        reset();
    };

    const getBreadcrumbHeaderForCredentials = () => {
        switch (connector) {
            case Connector.FILE:
                return "2. Choose File";
            case Connector.PUBLIC_DATASETS:
                return "2. Import Datasets";
            default:
                return "2. Enter Credentials";
        }
    };

    const getBreadcrumb = () => {
        return (
            <Breadcrumb>
                <Breadcrumb.Section active={step === 0} onClick={step > 0 ? () => setStep(0) : undefined}>
                    1. Choose Connector
                </Breadcrumb.Section>

                <Breadcrumb.Divider icon="right chevron" />
                <Breadcrumb.Section
                    active={step === 1}
                    style={step < 1 ? { opacity: 0.2 } : {}}
                    onClick={step > 1 ? () => setStep(1) : undefined}
                >
                    {getBreadcrumbHeaderForCredentials()}
                </Breadcrumb.Section>

                {connector !== Connector.PUBLIC_DATASETS && connector !== Connector.FILE && (
                    <>
                        <Breadcrumb.Divider icon="right chevron" />
                        <Breadcrumb.Section
                            active={step === 2}
                            style={step < 2 ? { opacity: 0.2 } : {}}
                            onClick={step > 2 ? () => setStep(2) : undefined}
                        >
                            3. Create Datasets
                        </Breadcrumb.Section>
                    </>
                )}
            </Breadcrumb>
        );
    };

    const getModalBody = () => {
        switch (step) {
            case 0:
                return (
                    <ConnectorCardPanel onClick={onConnectorClick} setOpen={setOpen} getBreadcrumb={getBreadcrumb} />
                );
            case 1:
                const ConnectorComponent: React.FC<ConnectorProps> | undefined = getConnectorFromName(connector!);
                if (ConnectorComponent !== undefined) {
                    return (
                        <ConnectorComponent
                            onSubmit={onCredentialsSubmit}
                            connection={connection}
                            setConnection={setConnection}
                            connectorConfig={connectorConfig}
                            setConnectorConfig={setConnectorConfig}
                            setConnectionObjects={setConnectionSchema}
                            setOpen={setOpen}
                            getBreadcrumb={getBreadcrumb}
                            onStepChange={setStep}
                            reset={reset}
                        />
                    );
                }
                return null;
            case 2:
                const DatasetComponent: React.FC<DatasetCreatorProps> | undefined = getDatasetCreatorFromName(
                    connector!,
                );
                if (DatasetComponent !== undefined) {
                    return (
                        <DatasetComponent
                            // TODO: This is very unsafe:
                            connection={connection!}
                            onSubmit={onDatasetsSubmit}
                            connectionSchema={connectionSchema}
                            getBreadcrumb={getBreadcrumb}
                            onStepChange={setStep}
                            setOpen={setOpen}
                        />
                    );
                }
        }
        return null;
    };

    return (
        <Modal
            centered={true}
            size={"large"}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                reset();
            }}
            trigger={
                <Button className={metrics.BLOCK_AUTO_CAPTURE} primary icon labelPosition="left">
                    <Icon name="plus" />
                    Connect Data
                </Button>
            }
        >
            {getModalBody()}
        </Modal>
    );
}

export default ConnectDataModal;
