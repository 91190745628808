import { useRecoilState } from "recoil";
import { Icon, Message } from "semantic-ui-react";

import { CONTACT_MODAL_STATE } from "../state/subscription";
import { SEMANTIC_BLUE, SEMANTIC_DARK_YELLOW } from "../utils/colors";

function InactiveSubscriptionMessage(props: { content: string; fontSize?: string }) {
    const [, setContactModal] = useRecoilState(CONTACT_MODAL_STATE);
    return (
        <Message color={"yellow"} style={{ fontSize: props.fontSize }}>
            <Message.Content>
                <button
                    className="button-reset"
                    onClick={() => setContactModal(true)}
                    style={{ color: SEMANTIC_DARK_YELLOW }}
                >
                    <b>
                        <Icon name={"warning sign"} /> {props.content}
                    </b>{" "}
                    <span style={{ textDecoration: "underline", color: SEMANTIC_BLUE }}>Add a credit card</span> in
                    order to continue using Predibase.
                </button>
            </Message.Content>
        </Message>
    );
}

export default InactiveSubscriptionMessage;
