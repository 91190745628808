import { deployment, deploymentType } from "@/autogen/openapi";

import { GET_DEPLOYMENT_LOGS_QUERY_KEY, useDeploymentLogsWithTimestampQuery } from "../../../data/query";
import GrafanaLogsTab from "./GrafanaLogs";

const DeploymentLogs = (props: { deploymentUUID?: string; deployment?: deployment }) => {
    // Parent state:
    const { deploymentUUID, deployment } = props;

    // Query state:
    const { data: logsData, isFetching: loadingLogs } = useDeploymentLogsWithTimestampQuery(deploymentUUID ?? "", {
        enabled: Boolean(deploymentUUID) && deployment?.type === deploymentType.DEDICATED,
        retry: (failureCount, error) => {
            const errorMessage = error?.message ?? (error as unknown as string);
            if (errorMessage === "record not found" || errorMessage?.includes("No logs found")) {
                return false;
            }
            return failureCount < 3;
        },
    });

    // Derived state:
    const logs = logsData?.data ?? [];
    const logsUpdatedAt = logsData?.timestamp ?? 0;
    const queryKey = GET_DEPLOYMENT_LOGS_QUERY_KEY(deploymentUUID ?? "");

    return (
        <GrafanaLogsTab
            logs={logs}
            logsUpdatedAt={logsUpdatedAt}
            loadingLogs={loadingLogs}
            queryKeyToInvalidate={queryKey}
        />
    );
};

export default DeploymentLogs;
