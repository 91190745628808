import { Message } from "semantic-ui-react";

const ErrorLogs = (props: { errorMessage?: string }) => {
    const { errorMessage } = props;

    return (
        <Message negative>
            <Message.Header>Error in model training</Message.Header>
            <div style={{ whiteSpace: "pre-wrap" }}>{errorMessage}</div>
        </Message>
    );
};

export default ErrorLogs;
