import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { finetuningJob, grpoJobMetrics, sftMetricsPayload } from "@/autogen/openapi";

import { useAuth0TokenOptions } from "../../../../data";
import { JOBS_CONSTANT } from "../../../query";
import { getHistoricalJobMetrics } from "./data";

// Queries:
export const GET_HISTORICAL_JOB_METRICS_QUERY_KEY = (jobUUID: finetuningJob["uuid"]) => [
    JOBS_CONSTANT,
    jobUUID,
    "metrics",
    "historical",
];
export const useHistoricalJobMetricsQuery = (
    jobUUID: finetuningJob["uuid"],
    options?: Partial<UseQueryOptions<sftMetricsPayload[] | grpoJobMetrics[]>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<sftMetricsPayload[] | grpoJobMetrics[]>({
        queryKey: GET_HISTORICAL_JOB_METRICS_QUERY_KEY(jobUUID),
        queryFn: () => getHistoricalJobMetrics(jobUUID, auth0TokenOptions),
        ...options,
    });
};
