import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { userList } from "@/autogen/openapi";

import { useAuth0TokenOptions } from "../../../data";
import { USERS_CONSTANT } from "../../../query";
import { GetUsersParams, getUsersV2 } from "./data";

// Queries:
export const GET_USERS_QUERY_KEY_V2 = (params: GetUsersParams) => [USERS_CONSTANT, "v2", params];
export const useUsersQueryV2 = (params: GetUsersParams, options?: Partial<UseQueryOptions<userList>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<userList>({
        queryKey: GET_USERS_QUERY_KEY_V2(params),
        queryFn: () => getUsersV2(params, auth0TokenOptions),
        ...options,
    });
};
