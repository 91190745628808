import { AxiosError } from "axios";
import { Message } from "semantic-ui-react";

import { SEMANTIC_GREY_DISABLED } from "../utils/colors";
import { getErrorMessage } from "../utils/errors";
import { GenerateResponse } from "./data";

const Response = (props: {
    isSuccessful: boolean;
    isErrored: boolean;
    error: Error | null;
    response: GenerateResponse | undefined;
}) => {
    const { isSuccessful, isErrored, error, response } = props;

    const errorMessage =
        (error as any)?.code === 404
            ? "Something went wrong. Please try again later."
            : getErrorMessage(error as AxiosError);

    return (
        <div
            style={{
                whiteSpace: "pre-line",
                padding: "1rem",
                backgroundColor: "#F7F7F7",
                height: "100%",
                width: "100%",
                borderTop: "1px solid #d4d4d5",
            }}
        >
            {isSuccessful ? (
                <>
                    <b>Response</b>
                    <pre style={{ whiteSpace: "pre-wrap" }}>{response?.generated_text}</pre>
                    {response?.details?.generated_tokens && response?.tokensPerSecond && (
                        <div style={{ color: SEMANTIC_GREY_DISABLED, fontSize: `${12 / 14}rem`, marginTop: "0.5rem" }}>
                            {response?.details?.generated_tokens} tokens | {response?.tokensPerSecond} tokens/s
                        </div>
                    )}
                </>
            ) : (
                isErrored && (
                    <Message negative>
                        {/* TODO: Improve this */}
                        <Message.Header>Error received</Message.Header>
                        <Message.Content>{errorMessage}</Message.Content>
                    </Message>
                )
            )}
        </div>
    );
};

export default Response;
