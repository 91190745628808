import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { useAuth0TokenOptions } from "../data";
import { USERS_CONSTANT } from "../query";
import { getTokens } from "./data";

// Constants:
export const TOKENS_CONSTANT = "tokens";

// Queries:
export const GET_API_TOKENS_QUERY_KEY = [USERS_CONSTANT, TOKENS_CONSTANT];
export const useTokensQuery = (options?: Partial<UseQueryOptions<Token[]>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<Token[]>({
        queryKey: GET_API_TOKENS_QUERY_KEY,
        queryFn: () => getTokens(auth0TokenOptions),
        retry: false,
        ...options,
    });
};
