import { useState } from "react";

import { Button, Icon, SemanticCOLORS, Table } from "semantic-ui-react";

import metrics from "../metrics/metrics";
import { isNotProduction } from "../utils/environment";
import { capitalize } from "../utils/strings";
import { getEngineStatusInfo } from "./EngineStatus";
import UpdateEngineModal from "./UpdateEngineModal";

const EngineTableRow = (props: {
    engine: Engine;
    templates?: Map<number, string>;
    setActiveEngineSelection: (engine: Engine | null) => void;
    setUpdateEngineModalOpen: (open: boolean) => void;
}) => {
    const { engine, templates, setActiveEngineSelection, setUpdateEngineModalOpen } = props;
    const displayName = isNotProduction() ? `${engine.name} (${engine.instanceID || "--"})` : engine.name;

    return (
        <Table.Row key={engine.id}>
            <Table.Cell>
                <Button
                    className={`${metrics.BLOCK_AUTO_CAPTURE} button-reset`}
                    style={{ fontWeight: "normal", padding: 0 }}
                    onClick={() => {
                        setActiveEngineSelection(engine);
                        setUpdateEngineModalOpen(true);
                    }}
                >
                    {displayName}
                </Button>
            </Table.Cell>
            <Table.Cell>{templates && engine?.templateID && templates.get(engine.templateID)}</Table.Cell>
            <Table.Cell>
                <Icon name="circle" color={getEngineStatusInfo(engine.engineStatus)?.color as SemanticCOLORS} />
                {capitalize(engine.engineStatus)}
            </Table.Cell>
        </Table.Row>
    );
};

const TrainingEnginesTable = (props: { engines: Engine[]; templates?: Map<number, string> }) => {
    // Parent state:
    const { engines, templates } = props;

    // Local state:
    const [activeEngineSelection, setActiveEngineSelection] = useState<Engine | null>(null);
    const [updateEngineModalOpen, setUpdateEngineModalOpen] = useState(false);

    return (
        <>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Template</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {engines.map((engine, idx) => (
                        <EngineTableRow
                            engine={engine}
                            templates={templates}
                            setActiveEngineSelection={setActiveEngineSelection}
                            setUpdateEngineModalOpen={setUpdateEngineModalOpen}
                        />
                    ))}
                </Table.Body>
            </Table>
            <UpdateEngineModal
                engine={activeEngineSelection}
                open={updateEngineModalOpen}
                setOpen={setUpdateEngineModalOpen}
            />
        </>
    );
};

export default TrainingEnginesTable;
