import React, { useEffect, useState } from "react";

import { useRecoilState } from "recoil";
import { Button, Icon, Modal } from "semantic-ui-react";

import metrics from "../../metrics/metrics";
import { USER_STATE } from "../../state/global";
import { getDatasetCreatorFromName } from "../connect/util";
import { useConnectionSchemaConfigQuery } from "../query";

function ImportDataModal(props: { connection: Connection }) {
    const [userContext] = useRecoilState(USER_STATE);
    const [open, setOpen] = useState(false);
    const [schema, setSchema] = useState<ConnectionSchema>(); // step 3

    const { data: connectionSchemaConfig } = useConnectionSchemaConfigQuery(props.connection.id, {
        enabled: open && props.connection?.id !== undefined,
    });
    useEffect(() => {
        if (connectionSchemaConfig) {
            // TODO: Apparently this component was using the wrong type the whole time. Too lazy to figure it out rn.
            setSchema(connectionSchemaConfig as unknown as ConnectionSchema);
        }
    }, [connectionSchemaConfig]);

    const DatasetComponent: React.FC<DatasetCreatorProps> | undefined = getDatasetCreatorFromName(
        props.connection.type!,
    );
    if (DatasetComponent == null) {
        return null;
    }

    return (
        <Modal
            centered={true}
            size={"large"}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            trigger={
                <Button
                    disabled={userContext?.isExpired || false}
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    size="small"
                    primary
                    style={{ marginRight: "10px" }}
                >
                    <Icon name="plus square" />
                    Import Datasets
                </Button>
            }
        >
            <DatasetComponent
                connection={props.connection}
                onSubmit={() => setOpen(false)}
                connectionSchema={schema!}
                onStepChange={undefined}
                setOpen={setOpen}
            />
        </Modal>
    );
}

export default ImportDataModal;
