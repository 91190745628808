/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum adapterTaskType {
    GRPO = 'grpo',
    SFT = 'sft',
    CONTINUED_PRETRAINING = 'continued_pretraining',
    INSTRUCTION_TUNING = 'instruction_tuning',
    COMPLETION = 'completion',
}
