import { useRecoilState } from "recoil";
import { Loader } from "semantic-ui-react";

import { tier } from "@/autogen/openapi";

import { ErrorBoundaryMessage } from "../../../components/ErrorBoundary";
import { useDeploymentsQuery } from "../../../query";
import { USER_STATE } from "../../../state/global";
import DedicatedDeployments from "./DedicatedDeployments";
import ServerlessDeploymentsTable from "./ServerlessDeployments";

const DeploymentsView = () => {
    // Recoil state:
    const [userContext] = useRecoilState(USER_STATE);
    // Derived user state:
    const subscriptionTier = userContext?.tenant.tier;

    // Queries:
    // Fetches current LLMs:
    const {
        data: deployments,
        isLoading: deploymentsAreLoading,
        isFetching: deploymentsAreFetching,
        error: deploymentsQueryError,
    } = useDeploymentsQuery({
        refetchInterval: 1000 * 10, // 10 seconds
    });

    // Loaders and errors:
    const isLoading = deploymentsAreLoading || deploymentsAreFetching;
    // TODO: Needs better error handling:
    if (deploymentsQueryError) {
        return <ErrorBoundaryMessage />;
    }

    return (
        <>
            {isLoading && deployments === undefined && (
                <div className="loading-overlay">
                    <Loader active />
                </div>
            )}
            <div style={{ padding: "1.71rem" }}>
                <DedicatedDeployments deployments={deployments} />
                {subscriptionTier !== tier.ENTERPRISE_VPC && <ServerlessDeploymentsTable deployments={deployments} />}
            </div>
        </>
    );
};

export default DeploymentsView;
