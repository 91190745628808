import { ReactNode } from "react";

import { Dropdown, DropdownItemProps, Header, Input } from "semantic-ui-react";

import { SEMANTIC_GREY } from "../utils/colors";
import { rawtextSearch } from "../utils/search";

export const FieldWrapper = (props: { header: string; description?: string; children?: ReactNode }) => {
    return (
        <div style={{ width: "100%" }}>
            <Header as={"h2"} size={"small"} style={{ marginBottom: `${5 / 14}rem` }}>
                {props.header}
            </Header>
            {props.description && (
                <p style={{ color: SEMANTIC_GREY, fontSize: "0.9em", marginBottom: `${5 / 14}rem` }}>
                    {props.description}
                </p>
            )}
            {props.children}
        </div>
    );
};

export const StringInputField = (props: {
    header: string;
    name: string;
    placeholder?: string;
    description?: string;
    value: string;
    type?: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    styleOverride?: { [key: string]: string };
    disabled?: boolean;
    fullWidth?: boolean;
}) => {
    return (
        <FieldWrapper
            header={props.header}
            description={props.description}
            children={
                <Input
                    disabled={props.disabled}
                    name={props.name}
                    placeholder={props.placeholder}
                    style={{ width: props.fullWidth ? "100%" : "50%", marginBottom: "2rem", ...props.styleOverride }}
                    autoFocus
                    value={props.value}
                    type={props.type || "text"}
                    onChange={(event, data) => props.setValue(data.value)}
                />
            }
        />
    );
};

export const IntegerInputField = (props: {
    header: string;
    name: string;
    placeholder?: string;
    description?: string;
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    styleOverride?: { [key: string]: string };
    disabled?: boolean;
    fullWidth?: boolean;
}) => {
    return (
        <FieldWrapper
            header={props.header}
            description={props.description}
            children={
                <Input
                    disabled={props.disabled}
                    name={props.name}
                    placeholder={props.placeholder}
                    style={{ width: props.fullWidth ? "100%" : "50%", marginBottom: "2rem", ...props.styleOverride }}
                    autoFocus
                    value={props.value}
                    onChange={(event, data) => {
                        if (data.value === "") {
                            props.setValue("");
                        } else {
                            if (Number.isInteger(Number(data.value)) && Number(data.value) >= 0) {
                                props.setValue(data.value);
                            }
                        }
                    }}
                />
            }
        />
    );
};

export const DropdownField = (props: {
    header: string;
    name: string;
    placeholder?: string;
    description?: string;
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    options: DropdownItemProps[];
    clearable?: boolean;
    allowAdditions?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
}) => {
    return (
        <FieldWrapper
            header={props.header}
            description={props.description}
            children={
                <Dropdown
                    disabled={props.disabled}
                    clearable={props.clearable}
                    allowAdditions={props.allowAdditions}
                    name={props.name}
                    placeholder={props.placeholder}
                    style={{ width: props.fullWidth ? "100%" : "50%", marginBottom: `2rem` }}
                    options={props.options}
                    // ! NOTE: Leaving value as undefined may not propery clear selection when user clicks the X. See:
                    // https://github.com/Semantic-Org/Semantic-UI-React/issues/3625#issuecomment-654199235
                    value={props.value}
                    onChange={(_, { value }) => {
                        props.setValue(value as string);
                    }}
                    selection
                    fluid
                    search={rawtextSearch}
                />
            }
        />
    );
};
