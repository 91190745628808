import { AxiosResponse } from "axios";

import { role, roleAssignmentRequest, userList } from "@/autogen/openapi";

import { getTraceId } from "../../../api/trace";
import { Auth0TokenOptions } from "../../../data";
import metrics from "../../../metrics/metrics";
import { createV2APIServer, redirectIfSessionInvalid } from "../../../utils/api";
import { getErrorMessage } from "../../../utils/errors";

// GET operations:
export const generateInviteLinkV2 = async (roleName: role, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `/users/invite?role=${roleName.toUpperCase()}`;
    const apiServer = await createV2APIServer(auth0TokenOptions);

    return apiServer
        .post(endpoint)
        .then((res: AxiosResponse<{ link: string }>) => {
            return res.data.link;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

// TODO: Should be autogenerated:
export interface GetUsersParams {
    offset: number;
    limit: number;
}
export const getUsersV2 = async (params: GetUsersParams, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "users";
    const apiServer = await createV2APIServer(auth0TokenOptions);

    return apiServer
        .get(endpoint, { params })
        .then((res: AxiosResponse<userList>) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const grantRoleV2 = async (
    uuid: string,
    request: roleAssignmentRequest,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `/users/${uuid}/roles`;
    const apiServer = await createV2APIServer(auth0TokenOptions);

    return apiServer
        .post(endpoint, request)
        .then((res: AxiosResponse<undefined>) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                request,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};
