import { DropdownItemProps } from "semantic-ui-react";

import { adapter, deployment, deploymentStatus, deploymentType } from "@/autogen/openapi";

import TruncatedText from "../../components/TruncatedText";
import DeploymentStatusChip from "../../deployments/misc/DeploymentStatusChip";
import { deploymentModelSupportsAdapterModel } from "./model-matching";

export const sortDeployments = (
    deployments: deployment[] | undefined | null, // TODO: Remove null when we fix gateway middleware!
) => {
    // Sort dedicated deployments by name:
    const dedicated = deployments?.filter((dep) => dep.type === deploymentType.DEDICATED);
    const sortedDedicated = dedicated?.sort((dep1, dep2) =>
        dep1.name > dep2.name ? 1 : dep1.name < dep2.name ? -1 : 0,
    );

    // Sort serverless deployments by status, then by name:
    const serverless = deployments?.filter((dep) => dep.type === deploymentType.SERVERLESS);
    const ready = serverless
        ?.filter((dep) => dep.status === deploymentStatus.READY)
        .sort((dep1, dep2) => (dep1.name > dep2.name ? 1 : dep1.name < dep2.name ? -1 : 0));
    const notReady = serverless
        ?.filter((dep) => dep.status !== deploymentStatus.READY)
        .sort((dep1, dep2) => (dep1.name > dep2.name ? 1 : dep1.name < dep2.name ? -1 : 0));

    // Then combine into a final sorted list of deployments, always-on/ready serverless, standby/other serverless:
    return [...(sortedDedicated ? sortedDedicated : []), ...(ready ? ready : []), ...(notReady ? notReady : [])];
};

export type DropdownItemsArray = DropdownItemProps[];

export type DeploymentUUIDLookup = { [key: deployment["uuid"]]: deployment };
export const generateDeploymentSelectorOptions = (
    deployments: deployment[] | undefined | null, // TODO: Remove null when we fix gateway middleware!,
): [DropdownItemsArray, DeploymentUUIDLookup] => {
    const deploymentOptions: DropdownItemsArray = [];
    const deploymentLookup: DeploymentUUIDLookup = {};

    deployments?.forEach((deployment, idx) => {
        const annotatedName =
            deployment.name + (deployment.type === deploymentType.SERVERLESS ? " (Shared)" : " (Private)");
        deploymentOptions.push({
            rawtext: annotatedName,
            text: (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <TruncatedText text={annotatedName} />
                    <DeploymentStatusChip status={deployment.status} />
                </div>
            ),
            value: deployment.uuid,
            key: deployment.uuid,
        });
        deploymentLookup[deployment.uuid] = deployment;
    });

    return [deploymentOptions, deploymentLookup];
};

export type AdapterRepoLookup = { [key: string]: adapter[] };
export const generateAdapterRepoSelectorOptions = (adapterRepos?: {
    [key: string]: adapter[];
}): [DropdownItemsArray, AdapterRepoLookup] => {
    const options: DropdownItemsArray = [];
    const lookup: AdapterRepoLookup = {};

    if (adapterRepos) {
        for (const repoName in adapterRepos) {
            options.push({
                rawtext: repoName,
                text: <TruncatedText text={repoName} />,
                value: repoName,
                key: repoName,
            });
            lookup[repoName] = adapterRepos[repoName];
        }
    }

    return [options, lookup];
};

export type AdapterVersionLookup = { [key: string]: adapter };
export const generateAdapterVersionSelectorOptions = (
    adapters?: adapter[],
): [DropdownItemsArray, AdapterVersionLookup] => {
    const options: DropdownItemsArray = [];
    const lookup: AdapterVersionLookup = {};

    if (adapters) {
        adapters.forEach((adapter, idx) => {
            if (adapter.archived) {
                return;
            }

            const repoVersion = adapter.versionTag.toString();
            options.push({
                rawtext: repoVersion,
                text: <TruncatedText text={repoVersion} />,
                value: repoVersion,
                key: repoVersion,
            });
            lookup[repoVersion] = adapter;
        });
    }

    return [options, lookup];
};

export type DeploymentUUIDToSupportedAdapterReposMap = {
    [key: deployment["uuid"]]: { [key: string]: adapter[] };
};
export const generateDeploymentToAdapterRepoMap = (
    deployments: deployment[] | undefined | null, // TODO: Remove null when we fix gateway middleware!,
    adapters: adapter[] | undefined,
) => {
    // TODO: Remove null when we fix gateway middleware!
    if (deployments === undefined || adapters === undefined || adapters === null || deployments === null) {
        return {};
    }

    const deploymentUUIDToSupportedAdapterReposMap: DeploymentUUIDToSupportedAdapterReposMap = {};
    deployments.forEach((deployment) => {
        // Find all adapters compatible with the deployment:
        const adaptersCompatibleWithDeployment: adapter[] = [];
        adapters?.forEach((adapter) => {
            if (
                deploymentModelSupportsAdapterModel(deployment.name, adapter.baseModel) ||
                deploymentModelSupportsAdapterModel(deployment.model.name, adapter.baseModel)
            ) {
                adaptersCompatibleWithDeployment.push(adapter);
            }
        });

        // Group adapters by repo:
        const adapterRepos: { [key: adapter["repo"]]: adapter[] } = {};
        adaptersCompatibleWithDeployment.forEach((adapter) => {
            const repoName = adapter.repo;
            if (repoName in adapterRepos) {
                adapterRepos[repoName].push(adapter);
            } else {
                adapterRepos[repoName] = [adapter];
            }
        });

        // Sort adapters in each repo by model version:
        for (const repoName in adapterRepos) {
            adapterRepos[repoName] = adapterRepos[repoName].sort((a, b) => {
                if (a.versionTag < b.versionTag) {
                    return -1;
                }
                if (a.versionTag > b.versionTag) {
                    return 1;
                }
                return 0;
            });
        }

        // Map deployment to grouped adapters:
        deploymentUUIDToSupportedAdapterReposMap[deployment.uuid] = adapterRepos;
    });

    return deploymentUUIDToSupportedAdapterReposMap;
};
