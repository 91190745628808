import { Dispatch, SetStateAction } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Header } from "semantic-ui-react";

import { awsVPCRegions, azureVPCRegions } from "@/autogen/openapi";

import { AvailableCloudTypes } from "../../../data";
import { SEMANTIC_BLUE, SEMANTIC_GREY } from "../../../utils/colors";

const SelectCloudButton = (props: {
    selected?: boolean;
    disabled?: boolean;
    setSelectedCloud: Dispatch<SetStateAction<AvailableCloudTypes | undefined>>;
    cloud: AvailableCloudTypes;
    imageLocation: string;
    title: string;
}) => {
    // Parent props:
    const { selected, disabled, cloud, setSelectedCloud, imageLocation, title } = props;

    // CSS adjustments:
    const disabledStyle = disabled ? { filter: "grayscale(100%)", opacity: "0.5" } : {};
    const selectedStyle = selected
        ? { background: "rgba(33,133,208,.1)", border: "1px solid var(--semantic-blue)" }
        : {};

    return (
        <div
            style={{
                margin: "8px",
                padding: "15px",
                border: "1px solid #dededf",
                borderRadius: "4px",
                position: "relative",
                cursor: "pointer",
                ...disabledStyle,
                ...selectedStyle,
            }}
            onClick={() => {
                if (!disabled) {
                    setSelectedCloud(cloud);
                }
            }}
        >
            <FontAwesomeIcon
                icon={"check-circle"}
                style={{
                    width: "1.1428rem",
                    marginRight: "1.1428rem",
                    position: "absolute",
                    top: "16px",
                    right: "0",
                }}
                color={selected ? SEMANTIC_BLUE : "rgb(222, 222, 223, 0.1)"}
            />
            <div style={{ display: "inline-flex" }}>
                <div style={{ width: "25px" }} />
                <img src={imageLocation} width={100} height={100} alt={title} title={title} />
                <div style={{ width: "25px" }} />
            </div>
            <br />
            <div style={{ flexGrow: "1" }}>
                <b>{title}</b>
            </div>
            {disabled && <div style={{ color: SEMANTIC_GREY, fontSize: "12" }}>Coming soon</div>}
        </div>
    );
};

const RegionSelector = (props: {
    availableRegions: string[];
    selectedRegion: string | undefined;
    setSelectedRegion: Dispatch<SetStateAction<string | undefined>>;
}) => {
    // Parent state:
    const { availableRegions: regions, selectedRegion, setSelectedRegion } = props;

    // Derived state:
    const options = regions.map((e: string) => ({ key: e, text: e, value: e }));

    return (
        <Form style={{ padding: "16px", display: "flex", justifyContent: "center" }}>
            <Form.Select
                label={"Cloud Region"}
                options={options}
                placeholder="Select cloud region"
                onChange={(e, { value }) => {
                    setSelectedRegion(value as string);
                }}
                value={selectedRegion ?? ""} // NOTE: Have to fall back to empty string to prevent weird caching behavior
                clearable
            />
        </Form>
    );
};

const CloudAndRegionSelection = (props: {
    selectedCloud: AvailableCloudTypes | undefined;
    setSelectedCloud: Dispatch<SetStateAction<AvailableCloudTypes | undefined>>;
    selectedRegion: string | undefined;
    setSelectedRegion: Dispatch<SetStateAction<string | undefined>>;
    setShowCloudSpecificSetup: Dispatch<SetStateAction<boolean>>;
}) => {
    // Parent state:
    const { selectedCloud, setSelectedCloud, selectedRegion, setSelectedRegion, setShowCloudSpecificSetup } = props;

    return (
        <>
            <Header as="h3" textAlign="center">
                Select your cloud provider
            </Header>
            <div style={{ display: "inline-flex" }}>
                <SelectCloudButton
                    selected={selectedCloud === "aws"}
                    setSelectedCloud={setSelectedCloud}
                    cloud="aws"
                    imageLocation={"/amazon-web-services-2.svg"}
                    title={"Amazon Web Services"}
                />
                <SelectCloudButton
                    selected={selectedCloud === "azure"}
                    setSelectedCloud={setSelectedCloud}
                    cloud="azure"
                    imageLocation={"/azure.svg"}
                    title={"Microsoft Azure"}
                />
                <SelectCloudButton
                    selected={selectedCloud === "gcp"}
                    setSelectedCloud={setSelectedCloud}
                    cloud="gcp"
                    imageLocation={"/google-cloud-1.svg"}
                    title={"Google Cloud Platform"}
                    disabled={true}
                />
            </div>
            <br />
            {selectedCloud !== undefined && (
                <RegionSelector
                    availableRegions={
                        selectedCloud === "aws"
                            ? Object.values(awsVPCRegions)
                            : selectedCloud == "azure"
                              ? Object.values(azureVPCRegions)
                              : []
                    }
                    selectedRegion={selectedRegion}
                    setSelectedRegion={setSelectedRegion}
                />
            )}
            <Button
                primary
                size="large"
                onClick={() => {
                    setShowCloudSpecificSetup(true);
                }}
                disabled={!Boolean(selectedCloud) || !Boolean(selectedRegion)}
            >
                Setup Authorization
            </Button>
        </>
    );
};

export default CloudAndRegionSelection;
