import { JSONSchema7Type } from "json-schema";

import Markdown from "../../../Markdown";
import { StringSchema } from "./StringField";
import { FieldAnnotations } from "./markup/FieldAnnotations";
import { FieldTitle } from "./markup/FieldTitle";

const FieldWrapper = (props: {
    children: React.ReactNode;
    defaultValue?: JSONSchema7Type;
    hideTitle?: boolean;
    title?: string;
    titlePrefix?: string;
    description?: string;
    isTextArea?: boolean;
    path: string;
    schema: StringSchema;
    style?: React.CSSProperties;
}) => {
    const { children, defaultValue, hideTitle, title, titlePrefix, description, isTextArea, path, schema, style } =
        props;

    return (
        <div style={{ marginBottom: `${24 / 14}rem` }} key={`${path}_string`}>
            {hideTitle ? null : <FieldTitle title={title} prefix={titlePrefix} />}
            {description && description.length && <Markdown children={description} secondary={true} />}
            <div style={{ display: "flex", flexDirection: isTextArea ? "column" : "inherit", ...style }}>
                {children}
                <FieldAnnotations path={path} defaultValue={defaultValue} schema={schema} />
            </div>
        </div>
    );
};

export default FieldWrapper;
