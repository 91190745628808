import { Popup, Table } from "semantic-ui-react";

import { SEMANTIC_BLACK } from "../../../utils/colors";
import { formatValueToNumericString, getFormattedPercentage } from "../../../utils/numbers";
import Markdown from "../../Markdown";

/**
 * Renders a binary profile.
 * @param binary_profile
 * @returns
 */
function getBinaryProfile(binary_profile: any) {
    return (
        <p>
            <br />
            <b>Label frequency:</b>
            <br />"{binary_profile.true_label}": {getFormattedPercentage(binary_profile.percent_true)}
            <br />"{binary_profile.false_label}": {getFormattedPercentage(binary_profile.percent_false)}
            <br />
            <br />
            Imbalance ratio: {binary_profile.imbalance_ratio.toFixed(1)}
        </p>
    );
}

/**
 * Renders a category profile with fewer than 6 classes.
 * @param category_profile
 * @returns
 */
function getCategoryProfileFewerThan6(category_profile: any) {
    return (
        <p>
            <br />
            <b>Most frequent classes:</b>
            <br />"{category_profile.most_frequent_items[0].label}":{" "}
            {getFormattedPercentage(category_profile.most_frequent_items[0].percent_occurrence)}
            <br />"{category_profile.most_frequent_items[1].label}":{" "}
            {getFormattedPercentage(category_profile.most_frequent_items[1].percent_occurrence)}
            <br />"{category_profile.most_frequent_items[2].label}":{" "}
            {getFormattedPercentage(category_profile.most_frequent_items[2].percent_occurrence)}
            <br />
            <br />
            <b>Least frequent class:</b>
            <br />"{category_profile.least_frequent_items[0].label}":{" "}
            {getFormattedPercentage(category_profile.least_frequent_items[0].percent_occurrence)}
            <br />
            <br />
            Majority/minority ratio: {category_profile.majority_minority_ratio.toFixed(1)}
        </p>
    );
}

/**
 * Renders a category profile with exactly 3 classes.
 * @param category_profile
 * @returns
 */
function getCategoryProfileExactly3(category_profile: any) {
    return (
        <p>
            <br />
            <b>Class frequency:</b>
            <br />"{category_profile.most_frequent_items[0].label}":{" "}
            {getFormattedPercentage(category_profile.most_frequent_items[0].percent_occurrence)}
            <br />"{category_profile.most_frequent_items[1].label}":{" "}
            {getFormattedPercentage(category_profile.most_frequent_items[1].percent_occurrence)}
            <br />"{category_profile.most_frequent_items[2].label}":{" "}
            {getFormattedPercentage(category_profile.most_frequent_items[2].percent_occurrence)}
            <br />
            <br />
            Majority/minority ratio: {category_profile.majority_minority_ratio.toFixed(1)}
        </p>
    );
}

/**
 * Renders a category profile with more than 6 classes.
 * @param category_profile
 * @returns
 */
function getCategoryProfileMoreThan6(category_profile: any) {
    return (
        <p>
            <br />
            <b>Most frequent classes:</b>
            <br />"{category_profile.most_frequent_items[0].label}":{" "}
            {getFormattedPercentage(category_profile.most_frequent_items[0].percent_occurrence)}
            <br />"{category_profile.most_frequent_items[1].label}":{" "}
            {getFormattedPercentage(category_profile.most_frequent_items[1].percent_occurrence)}
            <br />"{category_profile.most_frequent_items[2].label}":{" "}
            {getFormattedPercentage(category_profile.most_frequent_items[2].percent_occurrence)}
            <br />
            <br />
            <b>Least frequent classes:</b>
            <br />"{category_profile.least_frequent_items[0].label}":{" "}
            {getFormattedPercentage(category_profile.least_frequent_items[0].percent_occurrence)}
            <br />"{category_profile.least_frequent_items[1].label}":{" "}
            {getFormattedPercentage(category_profile.least_frequent_items[1].percent_occurrence)}
            <br />"{category_profile.least_frequent_items[2].label}":{" "}
            {getFormattedPercentage(category_profile.least_frequent_items[2].percent_occurrence)}
            <br />
            <br />
            Majority/minority ratio: {category_profile.majority_minority_ratio.toFixed(1)}
        </p>
    );
}

/**
 * Renders a category profile. Dispatches to other helpers.
 * @param category_profile
 * @returns
 */
function getCategoryProfile(category_profile: any, numUniqueValues: number) {
    if (numUniqueValues > 6) {
        // For more than 6 unique values, list top 3 most and top 3 least common.
        return getCategoryProfileMoreThan6(category_profile);
    }
    if (numUniqueValues === 3) {
        // For exactly 3 unique values, list exactly 3 classes.
        // NOTE: There are never fewer than 3 unique values for a category type.
        // 2 --> binary.
        // 1 --> text (and disabled).
        return getCategoryProfileExactly3(category_profile);
    }
    // For fewer than 6 unique values, list all classes.
    return getCategoryProfileFewerThan6(category_profile);
}

/**
 * Renders a number with the specified fixed digits, defaulting to "0" if absent.
 *
 * Particularly useful for number fields in proto-originated types, for which absent number fields and fields with the
 * the default value of "0" are not distinguished.
 * @param value
 * @param numFixDigits
 * @returns
 */
function getProfileNumber(value: number, numFixDigits: number): string {
    if (!value) {
        return "0";
    }
    // TODO: See if global number format works.
    return value.toFixed(numFixDigits);
}

/**
 * Renders the number profile.
 * @param number_profile
 * @returns
 */
function getNumberProfile(number_profile: any) {
    return (
        <p>
            <br />
            <b>Distribution:</b>
            <br />
            Mean: {formatValueToNumericString(number_profile.distribution.mean)}
            <br />
            Stdev: {formatValueToNumericString(number_profile.distribution.stdev)}
            <br />
            Min: {formatValueToNumericString(number_profile.distribution.min)}
            <br />
            25%: {formatValueToNumericString(number_profile.distribution.p25)}
            <br />
            50%: {formatValueToNumericString(number_profile.distribution.median)}
            <br />
            75%: {formatValueToNumericString(number_profile.distribution.p75)}
            <br />
            Max: {formatValueToNumericString(number_profile.distribution.max)}
            <br />
            {/* number_profile.distribution.percentage_outliers added May 22, 2023. */}
            {number_profile.distribution?.percentage_outliers &&
                "Outliers (2σ): " + getFormattedPercentage(number_profile.distribution?.percentage_outliers)}
        </p>
    );
}

/**
 * Renders the text profile.
 * @param text_profile
 * @returns
 */
function getTextProfile(text_profile: any) {
    return (
        <p>
            <br />
            <b>Sequence length distribution:</b>
            <br />
            Mean: {getProfileNumber(text_profile.word_length_distribution.mean, 1)}
            <br />
            Stdev: {getProfileNumber(text_profile.word_length_distribution.stdev, 1)}
            <br />
            Min: {getProfileNumber(text_profile.word_length_distribution.min, 1)}
            <br />
            Max: {getProfileNumber(text_profile.word_length_distribution.max, 1)}
            <br />
            <br />
            Detected language: {text_profile.detected_language}
        </p>
    );
}

/**
 *
 */
function getChatProfile(chat_profile: any) {
    return (
        <p>
            <br />
            <b>Number of turns distribution:</b>
            <br />
            Mean: {getProfileNumber(chat_profile.num_turns_distribution.mean, 1)}
            <br />
            Stdev: {getProfileNumber(chat_profile.num_turns_distribution.stdev, 1)}
            <br />
            Min: {getProfileNumber(chat_profile.num_turns_distribution.min, 1)}
            <br />
            Max: {getProfileNumber(chat_profile.num_turns_distribution.max, 1)}
            <br />
            <br />
            <b>Number of words distribution:</b>
            <br />
            Mean: {getProfileNumber(chat_profile.num_words_distribution.mean, 1)}
            <br />
            Stdev: {getProfileNumber(chat_profile.num_words_distribution.stdev, 1)}
            <br />
            Min: {getProfileNumber(chat_profile.num_words_distribution.min, 1)}
            <br />
            Max: {getProfileNumber(chat_profile.num_words_distribution.max, 1)}
            <br />
            <br />
        </p>
    );
}

/**
 * Returns a full "Unique values" string.
 * @param type_agnostic_profile Type-agnostic profile.
 * @returns
 */
function getPercentUniqueValues(type_agnostic_profile: any) {
    if (type_agnostic_profile.percent_unique_values === 1) {
        return "Unique values: 100%";
    }
    if (type_agnostic_profile.percent_unique_values < 0.05 || type_agnostic_profile.num_unique_values < 100) {
        return "Unique values (#): " + type_agnostic_profile.num_unique_values;
    }
    return "Unique values: " + (type_agnostic_profile.percent_unique_values * 100).toFixed(1) + "%";
}

/**
 * Returns a formatted (correct significant digits) percent of missing values. Includes the "%" symbol.
 * @param type_agnostic_profile Type-agnostic profile.
 * @returns
 */
function getMissingValues(type_agnostic_profile: any): string {
    if (type_agnostic_profile.percent_missing_values !== 0) {
        return getFormattedPercentage(type_agnostic_profile.percent_missing_values);
    }
    return "0%";
}

/**
 * Renders the type-specific portion of the dataset profile cell.
 * @param feature_profile
 * @returns
 */
function getTypeSpecificProfile(feature_profile: any) {
    if (feature_profile.inferred_type === "binary") {
        return getBinaryProfile(feature_profile.binary_profile);
    }
    // Category features with 1 unique value have no category profile.
    if (feature_profile.inferred_type === "category" && feature_profile.category_profile) {
        return getCategoryProfile(
            feature_profile.category_profile,
            feature_profile.type_agnostic_profile.num_unique_values,
        );
    }
    if (feature_profile.inferred_type === "number") {
        return getNumberProfile(feature_profile.number_profile);
    }
    if (feature_profile.inferred_type === "text") {
        return getTextProfile(feature_profile.text_profile);
    }
    if (feature_profile.inferred_type === "chat") {
        return getChatProfile(feature_profile.chat_profile);
    }
}

/**
 * Renders the type agnostic profile section of the dataset previewer.
 * @param feature_profile
 * @returns
 */
function getTypeAgnosticProfile(feature_profile: any) {
    var type_agnostic_profile: any = feature_profile.type_agnostic_profile;
    return (
        <div>
            Inferred type:{" "}
            <Popup
                trigger={
                    <b
                        style={{
                            color: SEMANTIC_BLACK,
                            textDecorationLine: "underline",
                            textDecorationStyle: "dotted",
                            textUnderlineOffset: "2px",
                        }}
                    >
                        {feature_profile.inferred_type}
                    </b>
                }
                position="top center"
            >
                <Markdown children={feature_profile.inferred_type_reasoning} />
            </Popup>
            <br />
            Enabled by default:{" "}
            {!feature_profile.should_enable ? (
                <Popup
                    trigger={
                        <b
                            style={{
                                color: SEMANTIC_BLACK,
                                textDecorationLine: "underline",
                                textDecorationStyle: "dotted",
                                textUnderlineOffset: "2px",
                            }}
                        >
                            false
                        </b>
                    }
                    position="top center"
                >
                    <Markdown children={feature_profile.should_enable_reasoning} />
                </Popup>
            ) : (
                "true"
            )}
            <br />
            {getPercentUniqueValues(type_agnostic_profile)}
            <br />
            Missing values: {getMissingValues(type_agnostic_profile)}
        </div>
    );
}

function ProfileCell(props: { columnName: string; columnIndex: number; datasetProfile: any }) {
    return (
        <Table.Cell singleLine key={props.columnIndex} verticalAlign="top">
            {/* Type-agnostic profile. */}
            {getTypeAgnosticProfile(props.datasetProfile.feature_profiles[props.columnName])}

            {/* Type-specific profiling. */}
            {getTypeSpecificProfile(props.datasetProfile.feature_profiles[props.columnName])}
        </Table.Cell>
    );
}

export default ProfileCell;
