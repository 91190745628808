import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { validateInviteResponse, validateUserResponse } from "@/autogen/openapi";

import { useAuth0TokenOptions } from "../../data";
import { validateInviteToken, validateUser } from "./data";

export const VALIDATE_INVITE_TOKEN_QUERY_KEY = (inviteToken?: string) => ["inviteToken", inviteToken];
export const useValidateInviteTokenQuery = (
    inviteToken?: string,
    options?: Partial<UseQueryOptions<validateInviteResponse>>,
) => {
    return useQuery({
        queryKey: VALIDATE_INVITE_TOKEN_QUERY_KEY(inviteToken ?? ""),
        queryFn: () => validateInviteToken(inviteToken ?? ""),
        enabled: Boolean(inviteToken),
        retry: false,
        ...options,
    });
};

export const VALIDATE_USER_QUERY_KEY = (inviteToken?: string) => ["auth", inviteToken];
// NOTE: This is actually a POST API call instead of a GET API call. While this breaks REST principles, it's safer from a security perspective
// since we're not leaking any tokens in the URL.
export const useValidateUserQuery = (
    inviteToken?: string,
    options?: Partial<UseQueryOptions<validateUserResponse>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery({
        queryKey: VALIDATE_USER_QUERY_KEY(inviteToken),
        queryFn: () => validateUser(inviteToken, auth0TokenOptions),
        retry: false,
        ...options,
    });
};
