import { getTraceId } from "../api/trace";
import { Auth0TokenOptions } from "../data";
import metrics from "../metrics/metrics";
import { createV1APIServer, redirectIfSessionInvalid } from "../utils/api";
import { getErrorMessage } from "../utils/errors";

export const createSystemAPIToken = async (engineID: number, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "users/token/system/create";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .post<string>(
            endpoint,
            {
                id: engineID,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        )
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                properties: {
                    engineID,
                },
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getEngines = async (auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "engines";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get<GetEnginesResponse>(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getEngineSchema = async (auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "engines/schema";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get<EngineSchema>(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const startEngine = async (engine: Engine, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `engines/start`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .post<Engine>(endpoint, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                properties: {
                    engineName: engine.name,
                    engineID: engine.id,
                },
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const stopEngine = async (engine: Engine, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `engines/stop`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return (
        apiServer
            // TODO: better type available?
            .post<undefined>(endpoint, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                const errorMsg = getErrorMessage(error) ?? "";
                metrics.captureError("api_error", errorMsg, {
                    method: "POST",
                    endpoint,
                    properties: {
                        engineName: engine.name,
                        engineID: engine.id,
                    },
                    trace_id: getTraceId(error),
                });
                redirectIfSessionInvalid(errorMsg);
                throw errorMsg;
            })
    );
};

export const updateCurrentDefaultEngine = async (
    engineType: DefaultEngineType,
    engineID: number,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `engines/current/${engineType}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .put<Engine>(
            endpoint,
            { id: engineID },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        )
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "PUT",
                endpoint,
                properties: {
                    engineID,
                },
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const createEngine = async (engineSpec: EngineSpec, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "engines";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .post<Engine>(endpoint, engineSpec)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                properties: {
                    engineName: engineSpec.name,
                    engineTemplateID: engineSpec.templateID,
                },
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const updateEngineSpec = async (engineSpec: EngineSpec, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `engines/${engineSpec.id}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .put<Engine>(endpoint, engineSpec)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "PUT",
                endpoint,
                properties: {
                    engineName: engineSpec.name,
                    engineTemplateID: engineSpec.templateID,
                },
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const deleteEngine = async (engineID: number, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `engines/${engineID}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .delete(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "DELETE",
                endpoint,
                properties: {
                    engineID,
                },
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const detectEngines = async (
    datasetID: number,
    modelConfig: any | undefined,
    engineID: number,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = "engines/detect";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .post<EngineTemplate>(
            endpoint,
            {
                datasetID,
                modelConfig,
                engineID,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        )
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                properties: {
                    datasetID,
                    modelConfig,
                    engineID,
                },
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};
