import { Dispatch, SetStateAction, useState } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Divider, Message } from "semantic-ui-react";
import urlJoin from "url-join";

import { azureVPCRegions, vpcCloudType } from "@/autogen/openapi";

import { useAuth0TokenOptions } from "../../../../data";
import { GET_ENVIRONMENT_QUERY_KEY } from "../../../../query";
import { getDocsHome } from "../../../../utils/api";
import { SEMANTIC_GREY } from "../../../../utils/colors";
import { getErrorMessage } from "../../../../utils/errors";
import { setupVPC } from "../../data";
import { cleanCloudSetupErrorMessage } from "../utils";
import AzureDetailsForm from "./AzureDetailsForm";

const AzureSetupView = (props: {
    envShortCode: string;
    envRegion?: azureVPCRegions;
    setShowCloudSpecificSetup: Dispatch<SetStateAction<boolean>>;
}) => {
    // Parent state:
    const { envShortCode, envRegion, setShowCloudSpecificSetup } = props;

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Local state:
    const [appID, setAppID] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [tenantID, setTenantID] = useState<string>("");
    const [subscriptionID, setSubscriptionID] = useState<string>("");
    const [resourceGroup, setResourceGroup] = useState<string>("predibase-" + envShortCode);
    const [errorMsg, setErrorMsg] = useState<string | null>("");
    const [loading, setLoading] = useState<boolean>(false);

    // Query state:
    const queryClient = useQueryClient();
    const { mutate: mutateSetupVPC } = useMutation({
        mutationFn: () =>
            setupVPC(
                {
                    envShortcode: envShortCode,
                    cloudType: vpcCloudType.AZURE,
                    region: envRegion!,
                    appID,
                    password,
                    azureTenantID: tenantID,
                    subscriptionID,
                    resourceGroup,
                },
                auth0TokenOptions,
            ),
        onError: (error) => {
            setErrorMsg(getErrorMessage(error));
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: GET_ENVIRONMENT_QUERY_KEY(envShortCode) });
            setShowCloudSpecificSetup(false);
        },
        onSettled: () => {
            setLoading(false);
        },
    });

    if (envRegion === undefined || !Object.values(azureVPCRegions).includes(envRegion)) {
        return null;
    }

    return (
        <>
            <AzureDetailsForm
                region={envRegion}
                envShortCode={envShortCode}
                setAppID={setAppID}
                setPassword={setPassword}
                setTenantID={setTenantID}
                setSubscriptionID={setSubscriptionID}
                setResourceGroup={setResourceGroup}
            />
            <div style={{ color: SEMANTIC_GREY, fontSize: "12" }}>
                <Button
                    loading={loading}
                    disabled={!appID || !password || !tenantID || !subscriptionID || !resourceGroup}
                    primary
                    style={{ width: "100%" }}
                    fluid
                    size="large"
                    name="method"
                    type="submit"
                    onClick={() => {
                        setLoading(true);
                        mutateSetupVPC();
                    }}
                >
                    Create Predibase Environment
                </Button>
                {errorMsg && (
                    <Message negative>
                        <Message.Header>Error</Message.Header>
                        <div>{cleanCloudSetupErrorMessage(errorMsg)}</div>
                    </Message>
                )}
            </div>
            <Divider hidden />
            For more detailed instructions/screenshots, see our{" "}
            {
                // eslint-disable-next-line react/jsx-no-target-blank
                <a
                    href={urlJoin(getDocsHome(), "/user-guide/admin/vpc-provisioning/azure")}
                    target="_blank"
                    rel="noopener"
                >
                    docs
                </a>
            }
            .
        </>
    );
};

export default AzureSetupView;
