import { Dispatch, SetStateAction } from "react";

import AceEditor from "react-ace";

// ! ace-builds must ALWAYS be imported AFTER react-ace
// TODO: Why tho... (https://stackoverflow.com/questions/69545499/referenceerror-ace-is-not-defined)
import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/mode-sh";
import "ace-builds/src-noconflict/theme-chrome";

import { Divider, Form, Header } from "semantic-ui-react";

import { cloudDetailsTitleStyling } from "../utils";

const AzureDetailsForm = (props: {
    envShortCode: string;
    region: string;
    setSubscriptionID: Dispatch<SetStateAction<string>>;
    setResourceGroup: Dispatch<SetStateAction<string>>;
    setAppID: Dispatch<SetStateAction<string>>;
    setPassword: Dispatch<SetStateAction<string>>;
    setTenantID: Dispatch<SetStateAction<string>>;
}) => {
    const { envShortCode, region, setSubscriptionID, setResourceGroup, setAppID, setPassword, setTenantID } = props;

    return (
        <>
            <Header as="h3" textAlign="center">
                Configure your Azure account
            </Header>
            <b>Selected region:</b> {region}
            <Divider hidden />
            Please follow the steps below to create a Service Principal that will be used to deploy a Predibase
            environment within your Azure Account
            <br />
            <b>NOTE: All the listed steps must be executed in the same terminal session</b>
            <Form style={{ padding: "24px 0" }}>
                <ol style={{ textAlign: "left", paddingLeft: "20px", marginTop: "16px" }}>
                    <li>
                        Download and install the Azure CLI by following these{" "}
                        {
                            <a
                                href="https://learn.microsoft.com/en-us/cli/azure/install-azure-cli"
                                target="_blank"
                                rel="noopener"
                            >
                                instructions
                            </a>
                        }
                    </li>
                    <br />
                    <li>
                        Log onto the Azure CLI
                        <AceEditor
                            mode="sh"
                            style={{ marginTop: "16px" }}
                            theme="chrome"
                            name="azure-step-2"
                            value="az login"
                            height="2em"
                            width="40em"
                            fontSize="14px"
                            showGutter={true}
                            highlightActiveLine={false}
                            showPrintMargin={true}
                            readOnly={true}
                            setOptions={{
                                showLineNumbers: false,
                                tabSize: 4,
                            }}
                        />
                    </li>
                    <br />
                    <li>
                        Fetch the default subscription ID and store it into a local variable
                        <AceEditor
                            mode="sh"
                            style={{ marginTop: "16px" }}
                            theme="chrome"
                            name="azure-step-3"
                            value={`# Fetch the subscription ID \n export AZ_SUBSCRIPTION_ID="$(az account list --query "[?isDefault].id" -o tsv)" \n\n #NOTE: If you'd like to use a different subscription ID, uncomment the line below and export that ID instead.\n #export AZ_SUBSCRIPTION_ID="YOUR_ID" \n\n # View the subscription ID \n echo $AZ_SUBSCRIPTION_ID`}
                            height="11em"
                            width="40em"
                            fontSize="14px"
                            showGutter={true}
                            highlightActiveLine={false}
                            showPrintMargin={true}
                            readOnly={true}
                            setOptions={{
                                showLineNumbers: false,
                                tabSize: 4,
                            }}
                        />
                    </li>
                    <br />
                    <label style={{ ...cloudDetailsTitleStyling }}>Paste the Subscription ID</label>
                    <Form.Input
                        name="SubscriptionID"
                        style={{ width: "100%" }}
                        onChange={(event) => setSubscriptionID(event.target.value)}
                    />
                    <li>
                        Create a new resource group where Predibase will provision resources
                        <AceEditor
                            mode="sh"
                            style={{ marginTop: "16px" }}
                            theme="chrome"
                            name="azure-step-4"
                            value={`# Set the resource group name \n export RESOURCE_GROUP_NAME="predibase-${envShortCode}" \n\n # Create the resource group \n az group create --name $RESOURCE_GROUP_NAME --location ${region
                                .toLowerCase()
                                .replaceAll(" ", "")} --subscription $AZ_SUBSCRIPTION_ID`}
                            height="8em"
                            width="40em"
                            fontSize="14px"
                            showGutter={true}
                            highlightActiveLine={false}
                            showPrintMargin={true}
                            readOnly={true}
                            setOptions={{
                                showLineNumbers: false,
                                tabSize: 4,
                            }}
                        />
                        <br />
                        <strong>
                            NOTE: If you would like to use an existing resource group, export the env variable with that
                            value instead
                        </strong>
                    </li>
                    <br />
                    <label style={{ ...cloudDetailsTitleStyling }}>Paste the Resource Group (if changed)</label>
                    <Form.Input
                        name="ResourceGroup"
                        defaultValue={"predibase-" + envShortCode}
                        style={{ width: "100%" }}
                        onChange={(event) => setResourceGroup(event.target.value)}
                    />
                    <li>
                        Create a new service principal with scoped access to the resource group created in step 4
                        <AceEditor
                            mode="sh"
                            theme="chrome"
                            style={{ marginTop: "16px" }}
                            name="azure-step-5"
                            value={`# Set the permissions scope to be limited to the predibase resource group \n export USER_SCOPE="/subscriptions/$AZ_SUBSCRIPTION_ID/resourceGroups/$RESOURCE_GROUP_NAME" \n\n # Create the service principal \n az ad sp create-for-rbac --name predibase-${envShortCode} --role Contributor --scopes $USER_SCOPE > creds.json \n\n # View the credentials JSON \n cat creds.json`}
                            height="12em"
                            width="40em"
                            fontSize="14px"
                            showGutter={true}
                            highlightActiveLine={false}
                            showPrintMargin={true}
                            readOnly={true}
                            setOptions={{
                                showLineNumbers: false,
                                tabSize: 4,
                            }}
                        />
                    </li>
                    <br />
                    <label style={{ ...cloudDetailsTitleStyling }}>Paste the "appId" value from the JSON</label>
                    <Form.Input
                        name="AppID"
                        style={{ width: "100%" }}
                        onChange={(event) => setAppID(event.target.value)}
                    />
                    <label style={{ ...cloudDetailsTitleStyling }}>Paste the "password" value from the JSON</label>
                    <Form.Input
                        name="Password"
                        type="password"
                        style={{ width: "100%" }}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                    <label style={{ ...cloudDetailsTitleStyling }}>Paste the "tenant" value from the JSON</label>
                    <Form.Input
                        name="TenantID"
                        style={{ width: "100%" }}
                        onChange={(event) => setTenantID(event.target.value)}
                    />
                </ol>
            </Form>
        </>
    );
};

export default AzureDetailsForm;
