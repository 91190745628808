import { useMemo, useState } from "react";

import { Link } from "react-router-dom";
import {
    Button,
    Divider,
    Header,
    Icon,
    Progress,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from "semantic-ui-react";

import { computeReservation, deployment, deploymentType } from "@/autogen/openapi";

import Chip from "../../../components/Chip";
import { useReservationsQuery } from "../../../deployments/data/query";
import DeploymentStatusChip from "../../../deployments/misc/DeploymentStatusChip";
import { formatAcceleratorID } from "../../../deployments/misc/utils";
import { useDeploymentsQuery } from "../../../query";
import { SEMANTIC_GREEN, SEMANTIC_GREEN_ACTIVE, SEMANTIC_GREY } from "../../../utils/colors";
import dayjsExtended from "../../../utils/dayjs";

import "./ReservationsTab.css";

const ReservationStatusChip = (props: { active?: boolean }) => {
    // Parent props:
    const { active } = props;

    return (
        <Chip
            color={active ? SEMANTIC_GREEN : SEMANTIC_GREY}
            text={active ? "Active" : "Expired"}
            textColor={active ? SEMANTIC_GREEN_ACTIVE : undefined}
            opacity={0.1}
            width={`${100 / 14}rem`}
        />
    );
};

const ReservationsTableRow = (props: { reservation: computeReservation; deployments?: deployment[] }) => {
    // Parent props:
    const { reservation, deployments } = props;

    // Local state:
    const [expanded, setExpanded] = useState(false);

    // Derived state:
    const usage = reservation.usedCapacity / reservation.reservedCapacity;
    const expirationDate = dayjsExtended(reservation.expirationDate);

    return (
        <>
            <TableRow>
                <TableCell>
                    <Button basic icon style={{ border: "none" }} onClick={() => setExpanded((expanded) => !expanded)}>
                        <Icon name={expanded ? "angle down" : "angle right"} />
                    </Button>
                </TableCell>
                <TableCell>{formatAcceleratorID(reservation.accelerator)}</TableCell>
                <TableCell>
                    <ReservationStatusChip active={expirationDate.isAfter(dayjsExtended())} />
                </TableCell>
                <TableCell>
                    {
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Progress
                                name="usage-bar"
                                percent={usage * 100}
                                indicating
                                size="tiny"
                                style={{ marginBottom: 0, marginRight: "1rem", width: "25%" }}
                            />
                            <span style={{ whiteSpace: "nowrap" }}>
                                {reservation.usedCapacity} of {reservation.reservedCapacity} replicas
                            </span>
                        </div>
                    }
                </TableCell>
                <TableCell>{dayjsExtended(reservation.expirationDate).format("YYYY MMMM DD, h:mm a")}</TableCell>
                <TableCell>
                    <code
                        style={{
                            backgroundColor: "#f6f7f8",
                            padding: "0.5rem",
                            borderRadius: "0.25rem",
                            fontSize: "0.9rem",
                        }}
                    >
                        {reservation.accelerator}
                    </code>
                </TableCell>
            </TableRow>
            {expanded && (
                <TableRow style={{ backgroundColor: "#f6f7f8" }}>
                    <TableCell colSpan={2} />
                    <TableCell colSpan={3}>
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHeaderCell>Deployment</TableHeaderCell>
                                    <TableHeaderCell>Status</TableHeaderCell>
                                    <TableHeaderCell>Current Replicas</TableHeaderCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {deployments?.map((deployment) => {
                                    return (
                                        <TableRow key={deployment.uuid}>
                                            <TableCell>
                                                <Link
                                                    to={`/deployments/${deployment.uuid}`}
                                                    style={{ paddingRight: 10 }}
                                                >
                                                    {deployment.name}
                                                </Link>
                                            </TableCell>
                                            <TableCell>{<DeploymentStatusChip status={deployment.status} />}</TableCell>
                                            <TableCell>{deployment.currentReplicas}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableCell>
                    <TableCell colSpan={1} />
                </TableRow>
            )}
        </>
    );
};

type ReservationLookup = { [key in computeReservation["accelerator"]]?: computeReservation };
type ReservedAccleratorToDeploymentsMap = { [key in computeReservation["accelerator"]]?: deployment[] };

const generateReservationMaps = (reservations?: computeReservation[] | null, deployments?: deployment[]) => {
    const reservationLookup: ReservationLookup = {};
    const reservedAccleratorToDeploymentsMap: ReservedAccleratorToDeploymentsMap = {};

    // TODO: Simplifying assumption for now: we will only ever have one reservation per accelerator. If not, then we
    // either need to display individual reservations for the same accelerator as their own rows or otherwise first
    // group them by accelerator type:
    reservations?.forEach((reservation) => {
        reservationLookup[reservation.accelerator] = reservation;

        const matchingDeployments = deployments?.filter(
            // TODO: Do I actually have to check for usesGuaranteedCapacity here?
            (d) => d.usesGuaranteedCapacity && d.accelerator.id === reservation.accelerator,
        );
        reservedAccleratorToDeploymentsMap[reservation.accelerator] = matchingDeployments;
    });

    return { reservationLookup, reservedAccleratorToDeploymentsMap };
};

const ReservationsTab = () => {
    // Query state:
    const { data: reservations } = useReservationsQuery();
    const { data: deployments } = useDeploymentsQuery();

    // Derived state:
    const dedicatedDeployments = deployments?.filter((d) => d.type === deploymentType.DEDICATED);
    const { reservedAccleratorToDeploymentsMap } = useMemo(() => {
        return generateReservationMaps(reservations, dedicatedDeployments);
    }, [reservations, dedicatedDeployments]);

    return (
        <Table>
            <TableHeader>
                <TableRow>
                    <TableHeaderCell /> {/* Empty cell for the accordion icon */}
                    <TableHeaderCell>Accelerator</TableHeaderCell>
                    <TableHeaderCell>Status</TableHeaderCell>
                    <TableHeaderCell>Usage</TableHeaderCell>
                    <TableHeaderCell>End Date</TableHeaderCell>
                    <TableHeaderCell>Type</TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {reservations === null || reservations?.length === 0 ? (
                    <Table.Row>
                        <Table.Cell colSpan={6} textAlign={"center"} verticalAlign={"middle"}>
                            <Divider hidden />
                            <Header as="h2" size={"medium"} style={{ marginBottom: "0.5rem" }}>
                                Looks like you don't have any reserved accelerators yet!
                            </Header>
                            <span style={{ fontSize: "0.9em" }}>
                                Contact us at <a href="mailto:support@predibase.com">support@predibase.com</a> to learn
                                more.
                            </span>
                            <Divider hidden />
                        </Table.Cell>
                    </Table.Row>
                ) : (
                    reservations?.map((reservation) => {
                        return (
                            <ReservationsTableRow
                                key={reservation.accelerator}
                                reservation={reservation}
                                deployments={reservedAccleratorToDeploymentsMap[reservation.accelerator]}
                            />
                        );
                    })
                )}
            </TableBody>
        </Table>
    );
};

export default ReservationsTab;
