import { tier } from "@/autogen/openapi";

export const formatSubscription = (subscriptionTier?: tier) => {
    if (!subscriptionTier) {
        return null;
    }

    switch (subscriptionTier) {
        case tier.FREE:
            return "Free";
        case tier.PREMIUM:
            return "Developer (Predibase Cloud)";
        case tier.ENTERPRISE_VPC:
            return "Enterprise (VPC)";
        case tier.ENTERPRISE_SAAS:
            return "Enterprise (Predibase Cloud)";
        default:
            return "N/A";
    }
};

export const getComputeSubscriptionStatus = (computeSubscriptionStatus: any, freeTrialTesting: Boolean) => {
    return freeTrialTesting ? freeTrialTesting : computeSubscriptionStatus;
};
