import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { createRoot } from "react-dom/client";

import App from "./App";
import metrics from "./metrics/metrics";
import * as serviceWorker from "./serviceWorker";
import { initializeSentryIO } from "./utils/sentryio";

import "./index.css";

// TODO: Wonder if we could move more of this into App.tsx, gets confusing to keep track of. For instance, we need to
// ensure that Sentry is initialized before the app? (which this does, but still...)
// link to highlight: https://arc.net/l/quote/mhlrbsar
posthog.init("phc_1vpQG5V8FzGjFAqnLkex0tqT9Oxf4MvrLcR988WCvyB", {
    api_host: "https://d3nin9q4srka1p.cloudfront.net",
    autocapture: metrics.enableMetrics(),
    // Since we're an SPA, we don't want to capture duplicate pageviews
    // https://posthog.com/docs/libraries/js#single-page-apps-and-pageviews
    // Yet, we should keep it enabled for now to capture the initial pageview:
    // https://posthog.com/tutorials/single-page-app-pageviews
    capture_pageview: metrics.enableMetrics(),
    disable_session_recording: metrics.disableSessionRecording(),
    opt_in_site_apps: true,
});

try {
    initializeSentryIO(posthog);
} catch (error) {
    console.log("Sentry unable to be initalized.");
    console.log(error);
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
    // TODO: Enable strict mode when we can:
    // <React.StrictMode>
    <PostHogProvider client={posthog}>
        <App />
    </PostHogProvider>,
    // {/* </React.StrictMode> */}
);

// TODO: Why do you have to unregister if it was never registered?
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
