import { useEffect } from "react";

import { init, reactRouterV6BrowserTracingIntegration, replayIntegration } from "@sentry/react";
import type { Integration } from "@sentry/types";
import { PostHog } from "posthog-js";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

import { isProduction, isStaging } from "./environment";

export const initializeSentryIO = (posthog: PostHog) => {
    // Disable Sentry on locals:
    if (!process.env.REACT_APP_SENTRY_DSN) {
        return;
    }

    const integrations: Integration[] = [
        reactRouterV6BrowserTracingIntegration({
            useEffect: useEffect,
            useLocation: useLocation,
            // TODO: Not clear to me we have to map all of them?
            useNavigationType: useNavigationType,
            createRoutesFromChildren: createRoutesFromChildren,
            matchRoutes: matchRoutes,
        }),
    ];

    // Enable replays only in staging and prod.
    if (isProduction() || isStaging()) {
        integrations.push(
            replayIntegration({
                maskAllText: true,
                blockAllMedia: true,
                useCompression: true,
            }),
        );
    }

    // Enable PostHog integration.
    const organization = "predibase";
    const projectId = Number(process.env.REACT_APP_SENTRY_DSN.split("/").at(-1));
    if (projectId > 0) {
        integrations.push(new posthog.SentryIntegration(posthog, organization, projectId));
    }

    init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations,
        release: process.env.REACT_APP_PREDIBASE_RELEASE_TAG || "v1.0.0",
        environment: process.env.REACT_APP_PREDIBASE_RELEASE_STAGE || "development",
        denyUrls: [],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        // 100% sampling in PROD for error replays, 10% in staging.
        replaysOnErrorSampleRate: isProduction() ? 1.0 : 0.1,
    });
};
