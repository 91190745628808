import { Icon, SemanticCOLORS, Table } from "semantic-ui-react";

import GrayDash from "../components/GrayDash";
import { isNotProduction } from "../utils/environment";
import { capitalize } from "../utils/strings";
import { getEngineStatusInfo } from "./EngineStatus";

const EngineTableRow = (props: { engine: Engine; templates?: Map<number, string> }) => {
    const { engine, templates } = props;
    const displayName = isNotProduction() ? `${engine.name} (${engine.instanceID || <GrayDash />})` : engine.name;

    return (
        <Table.Row key={engine.id}>
            <Table.Cell>
                {" "}
                <span style={{ display: "flex", alignItems: "center", minHeight: `${36 / 14}rem` }}>{displayName}</span>
            </Table.Cell>
            <Table.Cell>{templates && engine?.templateID && templates.get(engine.templateID)}</Table.Cell>
            <Table.Cell>
                <Icon name="circle" color={getEngineStatusInfo(engine.engineStatus)?.color as SemanticCOLORS} />
                {capitalize(engine.engineStatus)}
            </Table.Cell>
        </Table.Row>
    );
};

const ServingEnginesTable = (props: { engines: Engine[]; templates?: Map<number, string> }) => {
    const { engines, templates } = props;

    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Template</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {engines.map((engine, idx) => (
                    <EngineTableRow engine={engine} templates={templates} />
                ))}
            </Table.Body>
        </Table>
    );
};

export default ServingEnginesTable;
