import React, { FC } from "react";

import { Form } from "semantic-ui-react";

import { SEMANTIC_GREY } from "../utils/colors";
import { snakeToTitle } from "../utils/config";

interface StackedFormProps {
    name: string;
    value: any;
    setState: (value: React.SetStateAction<string>) => void;
    label?: string;
    description?: JSX.Element | string;
    disabled?: boolean;
    placeholder?: string;
    autoFocus?: boolean;
    autoComplete?: string;
    password?: boolean;
}

export const SingularField: FC<StackedFormProps> = (props: StackedFormProps) => {
    // Only pass autocomplete if its set:
    const extraProps = props.autoComplete !== undefined ? { autoComplete: props.autoComplete } : {};
    return (
        <StackedFormGroup>
            <Form.Field style={{ paddingLeft: "0px" }}>
                <label style={{ paddingLeft: "7px" }}>{props.label ?? snakeToTitle(props.name)}</label>
                <p style={{ color: SEMANTIC_GREY, fontSize: "0.9em", marginBottom: "5px", paddingLeft: "7px" }}>
                    {props.description}
                </p>
                <Form.Input
                    autoFocus={props.autoFocus}
                    disabled={props.disabled}
                    type={props.password ? "password" : "text"}
                    name={props.name}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={(event, data) => props.setState(event.target.value)}
                    width={8}
                    {...extraProps}
                />
            </Form.Field>
        </StackedFormGroup>
    );
};

export const SideBySideField: FC<StackedFormProps> = (props) => {
    // Only pass autocomplete if its set:
    const extraProps = props.autoComplete !== undefined ? { autoComplete: props.autoComplete } : {};
    return (
        <Form.Field>
            <label>{props.label ?? snakeToTitle(props.name)}</label>
            <p style={{ color: SEMANTIC_GREY, fontSize: "0.9em", marginBottom: "5px" }}>{props.description}</p>
            <Form.Input
                type={props.password ? "password" : undefined}
                autoFocus={props.autoFocus}
                disabled={props.disabled}
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(event, data) => props.setState(event.target.value)}
                {...extraProps}
            />
        </Form.Field>
    );
};

export const StackedFormGroup: FC<{ [x: string]: any }> = ({ children, ...props }) => {
    return (
        <Form.Group widths="equal" style={{ marginBottom: `${24 / 14}rem` }}>
            {children}
        </Form.Group>
    );
};
