import { useEffect, useState } from "react";

import { UseQueryOptions, useQuery, useQueryClient } from "@tanstack/react-query";
import Sockette from "sockette";

import { finetuningJob, grpoJobMetrics, repo, sftMetricsPayload } from "@/autogen/openapi";

import metrics from "../../../../metrics/metrics";
import { getWebSocketEndpointV2, useAuth0Token } from "../../../../utils/api";
import { JOBS_CONSTANT } from "../../../query";
import { deduplicateArray } from "./sft_util";

// Websockets:
// TODO: Maybe worth just keeping it all in the same cache, not a separate "live" cache:
export const GET_LIVE_JOB_METRICS_QUERY_KEY = (jobUUID: finetuningJob["uuid"]) => [
    JOBS_CONSTANT,
    jobUUID,
    "metrics",
    "live",
];

export const useLiveJobMetricsQuery = (
    jobUUID: finetuningJob["uuid"],
    options?: Partial<UseQueryOptions<sftMetricsPayload[] | grpoJobMetrics[]>>,
) => {
    return useQuery<sftMetricsPayload[] | grpoJobMetrics[]>({
        queryKey: GET_LIVE_JOB_METRICS_QUERY_KEY(jobUUID),
        ...options,
    });
};

// The hook that subscribes to the websocket and updates the metrics cache:
const useLiveJobMetricsWebsocket = async (
    jobUUID: finetuningJob["uuid"],
    repoUUID: repo["uuid"],
    versionTag: number,
    enabled: boolean = true,
) => {
    // TODO: This function works but revisit these rules-of-hooks errors:
    // eslint-disable-next-line react-hooks/rules-of-hooks -- cannot call hooks in async functions
    const queryClient = useQueryClient();
    // eslint-disable-next-line react-hooks/rules-of-hooks -- cannot call hooks in async functions
    const [isReconnected, setIsReconnected] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks -- cannot call hooks in async functions
    const bearerToken = useAuth0Token();

    // eslint-disable-next-line react-hooks/rules-of-hooks -- cannot call hooks in async functions
    useEffect(() => {
        const websocketServerAddress = getWebSocketEndpointV2();
        const endpoint = websocketServerAddress + `/finetuning/jobs/${jobUUID}/metrics/stream`;

        const captureError = (e: Event, type: string) => {
            const code = e instanceof CloseEvent ? e.code : undefined;
            metrics.captureError("ws_error", String(code), {
                type,
                jobUUID,
                endpoint,
            });
        };

        if (enabled) {
            const websocket = new Sockette(endpoint, {
                protocols: bearerToken ? ["predibase", bearerToken] : ["predibase"],
                timeout: 5e3,
                maxAttempts: 3,
                onmaximum: (e) => captureError(e, "onmaximum"),
                onerror: (e) => captureError(e, "onerror"),
                onreconnect: (e) => {
                    setIsReconnected(true);
                    captureError(e, "onreconnect");
                },
                onmessage: (e: MessageEvent<string>) => {
                    const liveData: sftMetricsPayload = JSON.parse(e.data);
                    queryClient.setQueryData<sftMetricsPayload[]>(
                        GET_LIVE_JOB_METRICS_QUERY_KEY(jobUUID),
                        (prev: sftMetricsPayload[] | undefined) => {
                            if (prev === undefined) {
                                // The first message will not have a previous value
                                return [liveData];
                            }

                            // If we've reconnected, we want to reset the cache:
                            if (isReconnected) {
                                prev = [];
                                setIsReconnected(false);
                            }

                            // TODO: Sometimes the websocket sends duplicate final events?
                            // Probably because of: https://predibase.slack.com/archives/C03HRSHQBA6/p1714690478934529?thread_ts=1714689528.910759&cid=C03HRSHQBA6
                            const last = prev.at(-1);
                            if (last !== undefined && !last.meta.is_completed) {
                                return deduplicateArray([...prev, liveData]);
                            }
                        },
                    );
                },
            });
            return () => {
                websocket.close();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobUUID, enabled, repoUUID, versionTag, bearerToken]);
};

// Custom hooks:

export const useLiveJobMetrics = (
    jobUUID: finetuningJob["uuid"],
    repoUUID: repo["uuid"],
    versionTag: number,
    options?: Partial<UseQueryOptions<sftMetricsPayload[] | grpoJobMetrics[]>>,
) => {
    useLiveJobMetricsWebsocket(jobUUID, repoUUID, versionTag, options?.enabled);
    return useLiveJobMetricsQuery(jobUUID, options);
};
