import { useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Icon, Message, Modal } from "semantic-ui-react";

import { augmentDatasetRequest } from "@/autogen/openapi";

import { DropdownField, IntegerInputField, StringInputField } from "../../components/modal-utils";
import { useAuth0TokenOptions } from "../../data";
import { getErrorMessage } from "../../utils/errors";
import { augmentDataset } from "../data";

import "./AugmentDatasetModal.css";

const baseModelOptions = [
    { key: "gpt-4-turbo", value: "gpt-4-turbo", text: "OpenAI GPT-4 Turbo", rawtext: "OpenAI GPT-4 Turbo" },
    {
        key: "gpt-4-0125-preview",
        value: "gpt-4-0125-preview",
        text: "OpenAI GPT-4 0125 Preview",
        rawtext: "OpenAI GPT-4 0125 Preview",
    },
    {
        key: "gpt-4-1106-preview",
        value: "gpt-4-1106-preview",
        text: "OpenAI GPT-4 1106 Preview",
        rawtext: "OpenAI GPT-4 1106 Preview",
    },
    { key: "gpt-4o", value: "gpt-4o", text: "OpenAI GPT-4o", rawtext: "OpenAI GPT-4o" },
    {
        key: "gpt-4o-2024-08-06",
        value: "gpt-4o-2024-08-06",
        text: "OpenAI GPT-4o 2024-08-06",
        rawtext: "OpenAI GPT-4o 2024-08-06",
    },
    { key: "gpt-4o-mini", value: "gpt-4o-mini", text: "OpenAI GPT-4o-mini", rawtext: "OpenAI GPT-4o-mini" },
];

const augmentationStrategyOptions = [
    { key: "mixture_of_agents", value: "mixture_of_agents", text: "Mixture of Agents", rawtext: "Mixture of Agents" },
    { key: "single_pass", value: "single_pass", text: "Single Pass", rawtext: "Single Pass" },
];

const AugmentDatasetModal = (props: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    datasetUUID: string | undefined;
    datasetName: string | undefined;
}) => {
    const navigate = useNavigate();

    const { open, setOpen, datasetUUID, datasetName } = props;

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Local state:
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // Defaults
    const baseModelDefault = "gpt-4-turbo";
    const numSamplesToGenerateDefault = "1000";
    const augmentationStrategyDefault = "mixture_of_agents";

    // Required variables:
    const [name, setName] = useState<string>("");
    const [baseModel, setBaseModel] = useState<string>(baseModelDefault);
    const [openaiApiKey, setOpenaiApiKey] = useState<string>("");
    const [numSamplesToGenerate, setNumSamplesToGenerate] = useState<string>(numSamplesToGenerateDefault);
    const [taskContext, setTaskContext] = useState<string>("");
    const [augmentationStrategy, setAugmentationStrategy] = useState<string>(augmentationStrategyDefault);

    const { mutate: mutateAugmentDataset, reset: resetMutation } = useMutation({
        mutationFn: (config: augmentDatasetRequest) => augmentDataset(datasetUUID ?? "", config, auth0TokenOptions),
        onSuccess: (resp) => {
            setOpen(false);
            navigate(`/data/datasets/${resp.datasetId}`);
        },
        onError: (error) => {
            setErrorMessage(getErrorMessage(error));
        },
    });

    const canAugment = baseModel && openaiApiKey && numSamplesToGenerate && augmentationStrategy;

    return (
        <Modal
            name="augmentDatasetModal"
            onOpen={() => {
                setOpen(true);
                setErrorMessage(null);
                setBaseModel(baseModelDefault);
                setOpenaiApiKey("");
                setNumSamplesToGenerate(numSamplesToGenerateDefault);
                setTaskContext("");
                setAugmentationStrategy(augmentationStrategyDefault);
            }}
            onClose={() => {
                setOpen(false);
                resetMutation();
            }}
            open={open}
        >
            <Modal.Header>Augment {datasetName ?? ""}</Modal.Header>
            <Modal.Content>
                <span>
                    Augment your dataset with additional synthetic examples distilled from a strong teacher model. This
                    will create a new dataset.
                </span>
                <Divider hidden />

                <StringInputField
                    name="name"
                    placeholder="Generated dataset name"
                    value={name}
                    setValue={setName}
                    header="Name"
                    description="Provide a name for the generated dataset."
                />

                <DropdownField
                    name="augmentationStrategy"
                    placeholder="Augmentation strategy"
                    value={augmentationStrategy}
                    setValue={setAugmentationStrategy}
                    header="Augmentation strategy"
                    options={augmentationStrategyOptions}
                    description="Select the strategy for generating new synthetic examples."
                />

                <DropdownField
                    name="baseModel"
                    placeholder="Model name"
                    value={baseModel}
                    setValue={setBaseModel}
                    header="Teacher model"
                    options={baseModelOptions}
                    description="Pretrained model used to generate new synthetic examples from the existing dataset."
                />

                <StringInputField
                    name="openaiApiKey"
                    placeholder="API Key"
                    type="password"
                    value={openaiApiKey}
                    setValue={setOpenaiApiKey}
                    header="OpenAI API key"
                    description="(Required) Provide your OpenAI API key to access OpenAI's collection of teacher models."
                />

                <IntegerInputField
                    name="numSamplesToGenerate"
                    placeholder="Number of samples"
                    header="Number of samples to generate"
                    description="Specify the number of new synthetic examples to generate. These will be appended to the existing examples in the new dataset."
                    value={numSamplesToGenerate}
                    setValue={setNumSamplesToGenerate}
                />

                {/* // TODO: make this more of a text area. */}
                <StringInputField
                    name="taskContext"
                    placeholder="Context"
                    value={taskContext}
                    setValue={setTaskContext}
                    header="Task context"
                    description="(Optional) Provide a description of the task to help the teacher model generate relevant examples. This will be inferred from the dataset if not provided."
                />

                {errorMessage && <Message negative visible header={"Error"} content={errorMessage} />}
            </Modal.Content>

            {/* // Bottom bar with buttons: */}
            <Modal.Actions>
                <Button
                    onClick={() => {
                        setOpen(false);
                        resetMutation();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    icon
                    color={"green"}
                    labelPosition={"right"}
                    size={"small"}
                    disabled={!canAugment}
                    onClick={() => {
                        resetMutation();
                        mutateAugmentDataset({
                            name: name,
                            openaiApiKey: openaiApiKey,
                            config: {
                                base_model: baseModel,
                                num_samples_to_generate: numSamplesToGenerate
                                    ? Number(numSamplesToGenerate)
                                    : undefined,
                                augmentation_strategy: augmentationStrategy,
                                task_context: taskContext,
                            },
                        });
                    }}
                >
                    Augment
                    <Icon inverted name="expand arrows alternate" color={"green"} />
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default AugmentDatasetModal;
