import axios from "axios";
import { detect } from "detect-browser";

import { CurrentUser } from "../types/user";
import { isNotProduction } from "../utils/environment";

const juneProductionWriteKey = "etYI9zhhR1Mtamd9";
const juneStagingWriteKey = "vtmOiWpXD3veM2IN";
const headers = {
    "Content-Type": "application/json",
    Authorization: `Basic ${isNotProduction() ? juneStagingWriteKey : juneProductionWriteKey}`,
};

export const identify = (userContext: CurrentUser, email?: string) => {
    const endpoint = "https://api.june.so/sdk/identify";
    const subscriptionTier = userContext.tenant.tier;
    const shortCode = userContext.tenant.shortcode;
    // const now = new Date();
    return axios.post(
        endpoint,
        {
            userId: userContext.uuid,
            traits: {
                email,
                username: userContext.username,
                subscription: subscriptionTier,
                tenantShortcode: shortCode,
            },
            timestamp: new Date().toISOString(),
        },
        {
            headers,
        },
    );
};

export const identifyGroup = (userContext: CurrentUser, otherTraits?: any) => {
    const endpoint = "https://api.june.so/sdk/group";
    const shortCode = userContext.tenant.shortcode;
    return axios.post(
        endpoint,
        {
            userId: userContext.uuid,
            groupId: shortCode,
            traits: {
                tenantName: userContext.tenant.name,
                name: userContext.tenant.name,
                ...otherTraits,
            },
            timestamp: new Date().toISOString(),
        },
        {
            headers,
        },
    );
};

export const track = (userContext: CurrentUser, event: string, extraProperties?: any) => {
    const endpoint = "https://api.june.so/sdk/track";
    const shortCode = userContext.tenant.shortcode;
    return axios.post(
        endpoint,
        {
            userId: userContext.uuid,
            event,
            properties: {
                source: "ui",
                browser: detect(),
                ...extraProperties,
            },
            context: {
                groupId: shortCode,
            },
            timestamp: new Date().toISOString(),
        },
        {
            headers,
        },
    );
};
