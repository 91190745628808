export const handleLocalState = (
    path: string,
    value: number | string | string[] | boolean | null | undefined,
    setLocalState: (path: string, value: any) => void,
    setConfig: (path: string, typedValue: any) => void,
) => {
    const [localValue, configValue] = convertUserInputToType(value);

    setLocalState(path, localValue);
    setConfig(path, configValue);
};

/**
 * Converts the user input to a valid JavaScript type.
 * @param value The value the user entered in the text field
 * @returns A tuple containing the value to display in the text field and the value to pass to the reducer
 */
export const convertUserInputToType = (value: number | string | string[] | boolean | null | undefined) => {
    const scientificNotationRegex = /^[+-]?(?=\.\d|\d)(?:0|[1-9]\d*)?(?:\.\d*)?(?:\d[eE][+-]?\d+)?$/;

    if (typeof value !== "string") {
        return [value, value];
    }

    // @TODO: do we need to handle undefined?

    if (value === "true") {
        return [value, true];
    }

    if (value === "false") {
        return [value, false];
    }

    if (value === "null") {
        return [value, null];
    }

    if (scientificNotationRegex.test(value)) {
        const convertedNumber = Number(value);
        const isNotAValidNumber = isNaN(convertedNumber);

        const isValidDecimal = !isNotAValidNumber && value.includes(".");
        const isValidScientificNotation = !isNotAValidNumber && (value.includes("e") || value.includes("E"));
        const isValidPartialNumber =
            !isNotAValidNumber &&
            (value.at(-1) === "0" || value.at(-1) === "." || value.at(-1) === "e" || value.at(-1) === "E");

        // If the value is a valid number, convert it to pass to the reducer...
        if (!isNotAValidNumber) {
            // If the value is also a partial or in scientific notation then keep it as a string for displaying in the form:
            const textFieldValue =
                isValidScientificNotation || isValidDecimal || isValidPartialNumber ? value : convertedNumber;
            return [textFieldValue, convertedNumber];
        }
    }

    return [value, value];
};
