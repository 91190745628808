import React from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Message, Modal } from "semantic-ui-react";

import { adapterVersion } from "@/autogen/openapi";

import { useAuth0TokenOptions } from "../../../data";
import metrics from "../../../metrics/metrics";
import { getErrorMessage } from "../../../utils/errors";
import { cancelFinetuningJob } from "../../data";
import { GET_ADAPTER_REPOS_QUERY_KEY, useAdapterVersionQuery } from "../../query";

const CancelFinetuningJobModal = (props: {
    // If set, repo UUID and version number props are unncessary (and vice-versa for adapterVersion if they are set)
    adapterVersion?: adapterVersion;
    adapterRepoUuid?: string;
    adapterVersionNumber?: number;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    // Parent state:
    const { adapterVersion, adapterRepoUuid, adapterVersionNumber, open, setOpen } = props;

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Query state:
    const queryClient = useQueryClient();

    const { data: adapterVersionLoaded } = useAdapterVersionQuery(adapterRepoUuid ?? "", adapterVersionNumber ?? 0, {
        enabled:
            open &&
            adapterVersion === undefined &&
            typeof adapterVersionNumber === "number" &&
            adapterVersionNumber > 0,
        refetchOnWindowFocus: false,
        retry: false,
    });

    const {
        mutate: cancelAdapterVersion,
        reset: resetMutation,
        error: cancelAdapterVersionError,
    } = useMutation({
        mutationFn: (uuid: string) => cancelFinetuningJob(uuid, auth0TokenOptions),
        onSuccess: () => {
            metrics.capture("adapter_version_cancelled");
            queryClient.invalidateQueries({ queryKey: GET_ADAPTER_REPOS_QUERY_KEY() });
            setOpen(false);
            resetMutation();
        },
    });

    const adapterVersionLocal = adapterVersion ? adapterVersion : adapterVersionLoaded;
    const errorMessage = getErrorMessage(cancelAdapterVersionError);

    return (
        <Modal
            open={open}
            size="mini"
            onClose={() => {
                resetMutation();
            }}
        >
            <Modal.Header>Stop adapter{adapterVersionLocal ? `#${adapterVersionLocal?.tag}` : null}</Modal.Header>
            <Modal.Content>
                {errorMessage && (
                    <Message negative>
                        <Message.Header>Failed to stop adapter</Message.Header>
                        <p>{errorMessage}</p>
                    </Message>
                )}
                <p>Are you sure?</p>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => {
                        setOpen(false);
                        resetMutation();
                    }}
                >
                    Close
                </Button>
                <Button
                    negative
                    onClick={() => {
                        cancelAdapterVersion(adapterVersionLocal?.finetuningJob?.uuid ?? "");
                    }}
                >
                    Confirm
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default CancelFinetuningJobModal;
