import { useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import { Button, Divider, Header, Input, Message, Modal, Tab } from "semantic-ui-react";

import { integration, role } from "@/autogen/openapi";

import { useAuth0TokenOptions } from "../../../data";
import { track } from "../../../metrics/june";
import metrics from "../../../metrics/metrics";
import { useEnvironmentsQuery } from "../../../query";
import { SESSION_STATE, USER_STATE } from "../../../state/global";
import { SEMANTIC_GREY, SEMANTIC_RED_ACTIVE } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/errors";
import { useGetSignOut } from "../../../utils/signout";
import { deleteAccountV2, deprovisionAccount } from "../../data";
import { useUserRole } from "../../query";
import APITokens from "./APITokens";
import ThirdPartyIntegrationToken from "./ThirdPartyIntegrationToken";
import UserInfo from "./UserInfo";

const DeleteUserModal = (props: { username: string; deleteAccount: () => void; reset: () => void }) => {
    const [usernameInput, setUsernameInput] = useState("");

    return (
        <Modal
            onClose={() => {
                setUsernameInput("");
                props.reset();
            }}
            trigger={
                <Button
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    content="Delete Your Account"
                    icon="trash"
                    negative
                    style={{ marginBottom: `${10 / 14}rem` }}
                />
            }
            header={"Delete your account"}
            content={
                <Modal.Content>
                    <pre>{props.username}</pre>
                    Are you sure you'd like to delete your account? All contributions (models, datasets, queries,
                    deployments, etc.) will continue to exist in your organization under “Deleted User".
                    <Divider hidden />
                    <span>Enter your username to confirm deletion:</span>
                    <Input
                        style={{ paddingTop: "0.5rem" }}
                        autoFocus
                        value={usernameInput}
                        onChange={(e, { value }) => setUsernameInput(value)}
                        placeholder={props.username}
                    />
                </Modal.Content>
            }
            size="mini"
            actions={[
                { key: "cancel", content: "Cancel", onClick: props.reset },
                {
                    key: "delete",
                    content: "Delete",
                    negative: true,
                    onClick: props.deleteAccount,
                    disabled: usernameInput !== props.username,
                },
            ]}
        />
    );
};

const DeprovisionUserModal = (props: {
    envShortcode: string;
    deprovisionEnvironment: () => void;
    reset: () => void;
}) => {
    const [deprovisionConfirmation, setDeprovisionConfirmation] = useState("");

    return (
        <Modal
            onClose={() => {
                setDeprovisionConfirmation("");
                props.reset();
            }}
            trigger={
                <Button
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    content="Deprovision VPC environment"
                    icon="trash"
                    negative
                    style={{ marginBottom: `${10 / 14}rem` }}
                />
            }
            header={"Deprovision VPC environment"}
            content={
                <Modal.Content>
                    <p>
                        Are you sure you'd like to deprovision this environment? This will delete the Kubernetes cluster
                        and any compute resources.
                    </p>
                    <p>
                        All of your storage data (models, datasets, queries, deployments, etc.) will still be preserved.
                        You can always re-provision your environment if your predibase account is active and in good
                        standing.
                    </p>
                    <Divider hidden />
                    <span>
                        Type <strong>confirm</strong> to proceed:
                    </span>
                    <Input
                        style={{ paddingTop: "0.5rem" }}
                        autoFocus
                        value={deprovisionConfirmation}
                        onChange={(e, { value }) => setDeprovisionConfirmation(value)}
                        placeholder="confirm"
                    />
                </Modal.Content>
            }
            size="mini"
            actions={[
                { key: "cancel", content: "Cancel", onClick: props.reset },
                {
                    key: "deprovision",
                    content: "Deprovision",
                    negative: true,
                    onClick: props.deprovisionEnvironment,
                    disabled: deprovisionConfirmation !== "confirm",
                },
            ]}
        />
    );
};

const UserProfileTab = () => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Recoil state:
    const [userContext] = useRecoilState(USER_STATE);
    const [session] = useRecoilState(SESSION_STATE);
    const userName = session?.nickname;

    // Route state:
    const signout = useGetSignOut();

    // Query state:
    const userRole = useUserRole();

    const {
        mutate: mutateDeleteAccount,
        error: deleteAccountError,
        reset: resetDeletionMutation,
    } = useMutation({
        mutationFn: () => deleteAccountV2(userContext?.uuid ?? "", auth0TokenOptions),
        onSuccess: () => {
            metrics.capture("delete_account");
            userContext && track(userContext, "delete_account", {});
            window.location.href = "/auth/signin";
        },
    });

    const {
        mutate: mutateDeprovisionAccount,
        error: deprovisionAccountError,
        reset: resetDeprovisionMutation,
    } = useMutation({
        mutationFn: (envShortcode: string) => deprovisionAccount(envShortcode, auth0TokenOptions),
    });

    // Fetch the environment info
    const { data: environments, error: environmentsError, isLoading: isEnvironmentsLoading } = useEnvironmentsQuery();

    let errorMessage =
        getErrorMessage(environmentsError) ||
        getErrorMessage(deleteAccountError) ||
        getErrorMessage(deprovisionAccountError);

    const envType = environments?.[0]?.type;
    const envShortcode = environments?.[0]?.shortCode;
    if (!isEnvironmentsLoading && (envType === undefined || envShortcode === undefined)) {
        errorMessage = "Unable to fetch tenant information. Please try again later.";
    }

    return (
        <Tab.Pane className={"model-tab"}>
            {errorMessage ? (
                <>
                    <div className="row">
                        <Message negative>
                            <Message.Header>Error</Message.Header>
                            <p>{errorMessage}</p>
                        </Message>
                    </div>
                    <Divider hidden />
                </>
            ) : null}
            <Header as={"h2"} size={"medium"}>
                Sign Out
            </Header>
            <Button id="signout" onClick={signout} style={{ marginBottom: `${10 / 14}rem` }}>
                Sign Out
            </Button>
            <Divider />

            <UserInfo />
            <Divider />

            {userRole !== role.READONLY && (
                <>
                    <APITokens />
                    <Divider />

                    <ThirdPartyIntegrationToken integration={integration.WANDB} displayName="Weights & Biases" />
                    <ThirdPartyIntegrationToken integration={integration.COMET} displayName="Comet" />
                    <Divider />
                </>
            )}

            {envType === "remote" && envShortcode !== "" && (
                <>
                    <Header as={"h2"} size={"medium"} style={{ color: SEMANTIC_RED_ACTIVE, marginBottom: 0 }}>
                        Deprovision Environment
                    </Header>
                    <span
                        style={{
                            color: SEMANTIC_GREY,
                            paddingBottom: `${10 / 14}rem`,
                            display: "block",
                            fontSize: "0.9em",
                        }}
                    >
                        This action will delete all of predibase compute resources from your cloud environment. All your
                        storage data (models, datasets, queries, deployments, etc.) will continue to exist in your cloud
                        environment (S3, Blob Storage etc.).
                    </span>
                    <DeprovisionUserModal
                        envShortcode={envShortcode ?? ""}
                        deprovisionEnvironment={() => {
                            resetDeprovisionMutation();
                            mutateDeprovisionAccount(envShortcode ?? "");
                        }}
                        reset={() => resetDeprovisionMutation()}
                    />
                    <Divider />
                </>
            )}
            {/* Testing if this works in staging. */}
            <Header as={"h2"} size={"medium"} style={{ color: SEMANTIC_RED_ACTIVE, marginBottom: 0 }}>
                Delete Account
            </Header>
            <span
                style={{
                    color: SEMANTIC_GREY,
                    paddingBottom: `${10 / 14}rem`,
                    display: "block",
                    fontSize: "0.9em",
                }}
            >
                This action is final and cannot be undone. All contributions (models, datasets, queries, deployments,
                etc.) will continue to exist in your organization under “Deleted User”.
            </span>
            <DeleteUserModal
                username={userName ?? ""}
                deleteAccount={() => {
                    resetDeletionMutation();
                    mutateDeleteAccount();
                }}
                reset={() => resetDeletionMutation()}
            />
            {/* </>)} */}
        </Tab.Pane>
    );
};

export default UserProfileTab;
