import { useState } from "react";

import { keepPreviousData } from "@tanstack/react-query";
import { Button, Divider, Icon, Loader } from "semantic-ui-react";

import { role } from "@/autogen/openapi";

import { useUserRole } from "../../../settings/query";
import { useAdapterReposQuery } from "../../query";
import CreateAdapterRepoModal from "./../modals/CreateAdapterRepoModal";
import AdapterReposTable from "./AdapterReposTable";

const AdapterReposView = () => {
    // Local state:

    // Modals:
    const [createRepoModalOpen, setCreateRepoModalOpen] = useState<boolean>(false);

    // Pagination:
    const [searchState, setSearchState] = useState<string>("");
    const [offset, setOffset] = useState<number>(0);

    // Query state:
    const userRole = useUserRole();

    // Fetch adapter repos (paginated):
    const {
        data: listAdapterReposResponse,
        // isLoading: adapterReposAreLoading,
        isFetching: adapterReposAreFetching,
        error: getAdapterReposQueryError,
    } = useAdapterReposQuery(
        {
            name: searchState ?? undefined,
            offset,
        },
        {
            placeholderData: keepPreviousData,
            refetchInterval: 30 * 1000, // 30 seconds
        },
    );

    // Derived state:
    const repos = listAdapterReposResponse?.repos;
    // const reposOffset = listAdapterReposResponse?.offset; // TODO: Would this ever be different from the requested offset?
    const reposTotal = listAdapterReposResponse?.total;

    // Loaders and errors:
    const isLoading = adapterReposAreFetching;
    if (getAdapterReposQueryError) {
        // TODO: Do something.
    }

    return (
        <>
            {isLoading && listAdapterReposResponse === undefined && (
                <div className="loading-overlay">
                    <Loader active />
                </div>
            )}
            <div style={{ padding: "1.71rem" }}>
                {userRole !== role.READONLY && (
                    <div className="responsive-header">
                        <Button primary icon labelPosition="left" onClick={() => setCreateRepoModalOpen(true)}>
                            <Icon name="plus" />
                            New Adapter Repository
                        </Button>
                    </div>
                )}
                <Divider hidden />
                <AdapterReposTable
                    repos={repos}
                    paginationOffset={offset}
                    setPaginationOffset={setOffset}
                    paginationTotal={reposTotal}
                    searchState={searchState}
                    setSearchState={setSearchState}
                />
                <Divider hidden />
            </div>
            <CreateAdapterRepoModal open={createRepoModalOpen} setOpen={setCreateRepoModalOpen} />
        </>
    );
};

export default AdapterReposView;
