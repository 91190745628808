import { useState } from "react";

import { Form } from "semantic-ui-react";

import { SingularField } from "../../../components/StackedForm";
import ConnectorPanel from "../ConnectorPanel";
import { Connector, CreateConnectionEndpoint } from "../util";
import { getAlreadyConnectedMessage } from "./util";

function getName(url: string) {
    const filename = url.substring(url.lastIndexOf("/") + 1);
    // remove any extensions
    const basename = filename.replace(/\.[^/.]+$/, "");
    return basename;
}

function AzureDataConnector(props: ConnectorProps) {
    const [connectionName, setConnectionName] = useState(
        props.connectorConfig.connectionName ? props.connectorConfig.connectionName : "",
    );

    const [accountKey, setAccountKey] = useState(
        props.connectorConfig.accountKey ? props.connectorConfig.accountKey : "",
    );
    const [connectionString, setConnectionString] = useState(
        props.connectorConfig.connectionString ? props.connectorConfig.connectionString : "",
    );

    const alreadyConnected = props.connection !== undefined;

    const reset = () => {
        setAccountKey("");
        setConnectionString("");
    };

    const getAddRequest = () => {
        const config = {
            protocol: "abfs",
            accountKey: accountKey.trim(),
            connectionString: connectionString.trim(),
        };

        return {
            endpoint: CreateConnectionEndpoint,
            body: {
                connection: {
                    type: "adls",
                    name: connectionName.trim(),
                },
                secret: {
                    secret: config,
                },
            },
            headers: {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        };
    };

    const getUpdateRequest = () => {
        // const name = getName(url);
        const config = {
            protocol: "abfs",
            accountKey: accountKey,
            connectionString: connectionString,
        };

        return {
            endpoint: "sources/update",
            body: {
                connection: {
                    id: props.connection!.id,
                    type: props.connection!.type,
                    name: connectionName,
                },
                secret: {
                    secret: config,
                },
            },
            headers: {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        };
    };

    const isDisabled = () => {
        return !accountKey || !connectionString || !connectionName;
    };

    const getModalContent = () => {
        return (
            <>
                {getAlreadyConnectedMessage(alreadyConnected, !props.isEditView)}
                <Form>
                    <SingularField
                        autoFocus={true}
                        disabled={alreadyConnected && !props.isEditView}
                        name="name"
                        label="Connection Name"
                        placeholder="azure_datasets"
                        description="Choose a name for your dataset in Predibase"
                        value={connectionName}
                        setState={setConnectionName}
                    />
                    <SingularField
                        disabled={alreadyConnected && !props.isEditView}
                        name="accountKey"
                        label="Account Key"
                        placeholder="az_account_key"
                        description={
                            <p>
                                Key used to authenticate.&nbsp;
                                <a
                                    href="https://learn.microsoft.com/en-us/azure/storage/common/storage-account-keys-manage?tabs=azure-portal#view-account-access-keys"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    See Microsoft documentation for further details.
                                </a>
                            </p>
                        }
                        value={accountKey}
                        setState={setAccountKey}
                    />
                    <SingularField
                        disabled={alreadyConnected && !props.isEditView}
                        autoComplete="off"
                        name="connectionString"
                        label="Connection String"
                        placeholder="az_connection_string"
                        description={
                            <p>
                                Paste the entire connection string value.&nbsp;
                                <a
                                    href="https://learn.microsoft.com/en-us/azure/storage/common/storage-account-keys-manage?tabs=azure-portal#view-account-access-keys"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    See Microsoft documentation for further details.
                                </a>
                            </p>
                        }
                        value={connectionString}
                        setState={setConnectionString}
                    />
                </Form>
            </>
        );
    };

    return (
        <ConnectorPanel
            modalHeader="Connect to Azure Data Lake Storage"
            header="Azure Data Lake"
            editModalHeader="Edit Azure Data Lake Connection"
            submitLabel="Connect"
            name={Connector.ADLS}
            errorHeader="Error in Azure Data Lake connection"
            isDisabled={isDisabled}
            getModalContent={getModalContent}
            getAddRequest={getAddRequest}
            getUpdateRequest={getUpdateRequest}
            reset={reset}
            {...props}
        />
    );
}

export default AzureDataConnector;
