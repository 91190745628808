import React from "react";

import CreateDatasetsView from "./CreateDatasetsView";
import CreateFileDatasetsView from "./CreateFileDatasetsView";
import AzureDataConnector from "./connectors/AzureDataConnector";
import BigQueryConnector from "./connectors/BigQueryConnector";
import DatabricksConnector from "./connectors/DatabricksConnector";
import FileConnector from "./connectors/FileConnector";
import GCSConnector from "./connectors/GCSConnector";
import MySQLConnector from "./connectors/MySQLConnector";
import PostgresConnector from "./connectors/PostgresConnector";
import PublicDatasetsConnector from "./connectors/PublicDatasetsConnector";
import RedshiftConnector from "./connectors/RedshiftConnector";
import S3Connector from "./connectors/S3Connector";
import SnowflakeConnector from "./connectors/SnowflakeConnector";

export enum Connector {
    ADLS = "adls",
    GCS = "gcs",
    S3 = "s3",
    MYSQL = "mysql",
    POSTGRESQL = "postgresql",
    SNOWFLAKE = "snowflake",
    REDSHIFT = "redshift",
    BIGQUERY = "bigquery",
    DATABRICKS = "databricks",
    FILE = "file",
    PUBLIC_DATASETS = "public_datasets",

    // Disabled
    PRESTO = "presto",
    DREMIO = "dremio",
    DRUID = "druid",
    VERTICA = "vertica",
    DELTALAKE = "delta_lake",
    HIVE = "hive",
}

const selectConnectorMap: Partial<Record<Connector, React.FC<ConnectorProps>>> = {
    [Connector.FILE]: FileConnector,
    [Connector.PUBLIC_DATASETS]: PublicDatasetsConnector,
    [Connector.SNOWFLAKE]: SnowflakeConnector,
    [Connector.MYSQL]: MySQLConnector,
    [Connector.POSTGRESQL]: PostgresConnector,
    [Connector.REDSHIFT]: RedshiftConnector,
    [Connector.BIGQUERY]: BigQueryConnector,
    [Connector.DATABRICKS]: DatabricksConnector,

    [Connector.S3]: S3Connector,
    [Connector.ADLS]: AzureDataConnector,
    [Connector.GCS]: GCSConnector,
};

const selectDatasetMap: Partial<Record<Connector, React.FC<DatasetCreatorProps>>> = {
    [Connector.S3]: CreateFileDatasetsView,
    [Connector.ADLS]: CreateFileDatasetsView,
    [Connector.GCS]: CreateFileDatasetsView,
    [Connector.PUBLIC_DATASETS]: PublicDatasetsConnector,
};

export const getConnectorFromName = (connector: string) => {
    return selectConnectorMap[connector as Connector];
};

export const getDatasetCreatorFromName = (connector: string) => {
    return selectDatasetMap.hasOwnProperty(connector) ? selectDatasetMap[connector as Connector] : CreateDatasetsView;
};

export const CreateConnectionEndpoint = "/connections";
