import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { useAuth0TokenOptions } from "../data";
import { getEngineSchema, getEngines } from "./data";

// Constants:
export const ENGINES_CONSTANT = "engines";

// Queries:
export const GET_ENGINES_QUERY_KEY = [ENGINES_CONSTANT];
export const useEnginesQuery = (options?: Partial<UseQueryOptions<GetEnginesResponse>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<GetEnginesResponse>({
        queryKey: GET_ENGINES_QUERY_KEY,
        queryFn: () => getEngines(auth0TokenOptions),
        ...options,
    });
};

export const GET_ENGINE_SCHEMAS_QUERY_KEY = [ENGINES_CONSTANT, "schemas"];
export const useEngineSchemaQuery = (options?: Partial<UseQueryOptions<EngineSchema>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<EngineSchema>({
        queryKey: GET_ENGINE_SCHEMAS_QUERY_KEY,
        queryFn: () => getEngineSchema(auth0TokenOptions),
        ...options,
    });
};
