import React from "react";

import { useRecoilState } from "recoil";
import { Button, Divider, Grid, Header, Icon, Modal, SemanticCOLORS, Table } from "semantic-ui-react";

import { tier } from "@/autogen/openapi";

import { USER_STATE } from "../../state/global";
import { CONTACT_MODAL_STATE } from "../../state/subscription";
import { SEMANTIC_BLUE, SEMANTIC_GREY } from "../../utils/colors";
import UpgradeFreeTrialModalContents from "../UpgradeFreeTrialModalContents";

const CheckMarkCell = (props: { color?: string }) => (
    <Table.Cell collapsing style={{ borderTop: "none", verticalAlign: "baseline" }}>
        <Icon name={"check circle outline"} color={(props.color as SemanticCOLORS) || "grey"} />
    </Table.Cell>
);

const expiredTrialPoints = [
    {
        title: "Build Powerful Models in Minutes",
        body: "We automate the hard stuff like writing complex code so you can move faster. Accelerate ML projects with simplified training on any multi-modal dataset for any use case including state-of-the-art deep learning.",
    },
    {
        title: "Rapidly Iterate with Complete Control",
        body: "Manage, compare and finely tune your models with granular-level control. Smart recommendations guide less experienced users while a robust set of tuning options allow experts to change anything from encoders to transformers and beyond.",
    },
    {
        title: "Operationalize with Ease",
        body: "Deploy ML applications without being an infrastructure expert. Predibase makes it easy to scale models in production for batch or real-time inference. Our adaptive engines right-size compute to meet your project's needs.",
    },
];

export const ExpiredPlanModalBody = () => {
    return (
        <>
            <Header as={"h3"}>Your plan has ended.</Header>
            Contact{" "}
            <a href="mailto:support@predibase.com" style={{ color: SEMANTIC_BLUE }}>
                support@predibase.com
            </a>{" "}
            to continue using
            <br />
            Predibase or if you have any questions!
            <Divider hidden />
            <Grid columns="equal">
                <Grid.Row>
                    <Grid.Column>
                        <Table
                            basic={"very"}
                            compact
                            style={{ maxWidth: `${426 / 14}rem`, display: "block", margin: "auto" }}
                        >
                            <Table.Body>
                                {expiredTrialPoints.map((point) => (
                                    <Table.Row key={point.title}>
                                        <CheckMarkCell color={"blue"} />
                                        <Table.Cell style={{ borderTop: "none", paddingLeft: 0 }}>
                                            <h3
                                                style={{
                                                    fontSize: "1rem",
                                                    marginBottom: `${4 / 14}rem`,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {point.title}
                                            </h3>
                                            <p
                                                style={{
                                                    fontSize: `${12 / 14}rem`,
                                                    marginBottom: `${24 / 14}rem`,
                                                    color: SEMANTIC_GREY,
                                                }}
                                            >
                                                {point.body}
                                            </p>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
};

const SubscriptionExpirationModal = () => {
    const [contactModal, setContactModal] = useRecoilState(CONTACT_MODAL_STATE);
    const [user] = useRecoilState(USER_STATE);
    const [submitting, setSubmitting] = React.useState(false);

    // Derived user state:
    const userTier = user?.tenant.tier;
    return (
        <Modal open={contactModal} onClose={() => setContactModal(false)} size="large">
            <Modal.Content style={{ textAlign: "center" }}>
                {userTier === tier.FREE ? (
                    <UpgradeFreeTrialModalContents
                        submitting={submitting}
                        setOpen={setContactModal}
                        setSubmitting={setSubmitting}
                    />
                ) : (
                    <ExpiredPlanModalBody />
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setContactModal(false)}>Close</Button>
                {userTier === tier.FREE && (
                    <Button type="submit" form="credit-card-form" primary disabled={submitting}>
                        Upgrade to Developer Tier
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
};

export default SubscriptionExpirationModal;
