import React, { useState } from "react";

import { AddressElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Address, StripeError } from "@stripe/stripe-js";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Form, Loader, Message } from "semantic-ui-react";

import { useAuth0TokenOptions } from "../../../data";
import { GET_USER_CONTEXT_QUERY_KEY_V2 } from "../../../query";
import { BillingInfo, TaxIDData } from "../../../types/billing";
import { getClientEndpoint } from "../../../utils/api";
import TaxIDComponent from "./TaxIDComponent";
import { PaymentMethodParams, createPaymentMethod, createSetupIntent } from "./data";
import { GET_STRIPE_CUSTOMER_QUERY_KEY } from "./query";

import "./PaymentForm.css";

// TODO: Revisit this when we can delineate between personal and business users
const isEUCountry = (countryCode: string) =>
    [
        "AT",
        "BE",
        "BG",
        "CY",
        "CZ",
        "DE",
        "DK",
        "EE",
        "EL",
        "ES",
        "FI",
        "FR",
        "GB",
        "GR",
        "HR",
        "HU",
        "IE",
        "IT",
        "LT",
        "LU",
        "LV",
        "MT",
        "NL",
        "PL",
        "PT",
        "RO",
        "SE",
        "SI",
        "SK",
        "UK",
    ].includes(countryCode);

const defaultAddress = (address?: Address | null) => ({
    line1: address?.line1 ?? "",
    line2: address?.line2 ?? "",
    city: address?.city ?? "",
    state: address?.state ?? "",
    postal_code: address?.postal_code ?? "",
    country: address?.country ?? "",
});

const PaymentForm = (props: {
    submitting: boolean;
    setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    billingDetails: BillingInfo;
    taxIds?: TaxIDData[];
}) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    const { submitting, setSubmitting, setOpen, billingDetails, taxIds } = props;
    const latestTaxId = taxIds?.[0];
    const stripe = useStripe();
    const elements = useElements();
    const { mutateAsync: submitSetup, reset: resetSubmitSetup } = useMutation({
        mutationFn: () => createSetupIntent(auth0TokenOptions),
    });
    const { mutateAsync: submitPayment, reset: resetSubmitPayment } = useMutation({
        mutationFn: (options: PaymentMethodParams) => createPaymentMethod(options, auth0TokenOptions),
    });
    const address = defaultAddress(billingDetails?.address);

    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(true);
    const [country, setCountry] = useState(address?.country || "");
    const [taxCountry, setTaxCountry] = useState(latestTaxId?.type || "");
    const [taxID, setTaxID] = useState(latestTaxId?.value || "");

    const queryClient = useQueryClient();

    const handleError = (error: any) => {
        setSubmitting(false);
        setErrorMessage(error?.message || error);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setSubmitting(true);
        setErrorMessage(undefined);

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements?.submit();
        if (submitError) {
            handleError(submitError);
            return;
        }

        const addressElement = elements.getElement("address");
        if (!addressElement) {
            handleError({ message: "It looks like something went wrong on our end. Please try again later." });
            return;
        }

        const { complete, value: addressElVal } = await addressElement.getValue();
        if (!complete) {
            handleError({ message: "Please complete the billing address form" });
            return;
        }

        try {
            resetSubmitSetup();
            const setupIntentResponse = await submitSetup();

            // Confirm the SetupIntent using the details collected by the Payment Element
            // https://stripe.com/docs/payments/save-and-reuse?platform=web&ui=elements#submit-payment-details
            const { error: stripeError, setupIntent } = await stripe.confirmSetup({
                //`Elements` instance that was used to create the Payment Element
                elements,
                clientSecret: setupIntentResponse.clientSecret,
                confirmParams: {
                    // The url the customer is redirected to after they complete the payment.
                    // You can use the following query parameters, payment_intent (the SetupIntent's ID)
                    // or payment_intent_client_secret (the SetupIntent's client secret), to retrieve the
                    // SetupIntent's status. You can also append your own query parameters to the return_url,
                    // which persist through the redirect process.
                    return_url: `${getClientEndpoint()}/settings?tab=billing`,
                },
                redirect: "if_required",
            });

            // Show error to the customer (e.g., insufficient funds)
            // If the authorization fails, the Promise will resolve with
            // an {error} object that describes the failure. When the error
            // type is card_error or validation_error, you can display the
            // error message in error.message directly to the user. An error
            // type of invalid_request_error could be due to an invalid request
            // or 3DS authentication failures.
            // Martin: Based on using the Test Credit Cards, even 3Ds auth failures
            // return a human readable error message, so we can just display that.
            if (stripeError) {
                handleError(stripeError);
                return;
            }

            resetSubmitPayment();
            let taxIDData;
            if (taxID) {
                taxIDData = {
                    type: taxCountry,
                    value: taxID,
                };
            }
            const variables = {
                paymentMethod: setupIntent.payment_method,
                address: addressElVal.address,
                name: addressElVal.name,
                phone: addressElVal.phone,
                taxIDData: taxIDData,
                inMandate: false,
            };
            await submitPayment(variables);
        } catch (error) {
            handleError(error);
            return;
        }

        setSubmitting(false);
        setOpen(false);

        // Make sure Billing View shows the updated credit card!
        await queryClient.invalidateQueries({ queryKey: GET_STRIPE_CUSTOMER_QUERY_KEY });
        // Make sure the user's subscription is updated!
        await queryClient.invalidateQueries({ queryKey: GET_USER_CONTEXT_QUERY_KEY_V2 });
    };

    return (
        // Form must have ID for Stripe to trigger submit events
        <Form id="credit-card-form" onSubmit={handleSubmit}>
            {loading && <Loader active inline="centered" />}
            {submitting && (
                <div className="submitting-overlay-info-modal">
                    <Loader active />
                </div>
            )}
            <div style={{ display: loading ? "none" : "inherit" }}>
                {errorMessage && (
                    <Message negative>
                        <Message.Content>{errorMessage}</Message.Content>
                    </Message>
                )}
                <PaymentElement
                    onReady={() => {
                        setLoading(false);
                    }}
                    onEscape={() => {
                        setOpen(false);
                    }}
                    onLoadError={(event: { elementType: "payment"; error: StripeError }) => {
                        handleError(event.error);
                    }}
                />
                <p style={{ color: "#6d6e78", marginTop: "0.5rem", fontSize: "0.85rem", lineHeight: 1.45 }}>
                    Note: A temporary $100 pre-authorization charge will be applied to verify your credit card.
                </p>
                <AddressElement
                    options={{
                        mode: "billing",
                        defaultValues: {
                            name: billingDetails?.name,
                            phone: billingDetails?.phone,
                            address: address,
                        },
                    }}
                    onEscape={() => {
                        setOpen(false);
                    }}
                    onChange={(e) => {
                        if (e.value.address.country !== country) {
                            setCountry(e.value.address.country);
                        }
                    }}
                />
                <TaxIDComponent
                    taxID={taxID}
                    setTaxID={setTaxID}
                    taxCountry={taxCountry}
                    setTaxCountry={setTaxCountry}
                    taxIdRequired={false}
                />
            </div>
            <p style={{ color: "#6d6e78", marginTop: "0.5rem", fontSize: "0.85rem", lineHeight: 1.45 }}>
                By adding your credit card, you agree to the Predibase{" "}
                <a href="https://predibase.com/privacy-policy" target="_blank" rel="noopener">
                    privacy policy
                </a>{" "}
                and{" "}
                <a href="https://predibase.com/terms-of-service" target="_blank" rel="noopener">
                    terms of service
                </a>
                .
            </p>
        </Form>
    );
};

export default PaymentForm;
