import { ReactNode, useEffect, useState } from "react";

import { Auth0Provider } from "@auth0/auth0-react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { withProfiler } from "@sentry/react";
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useActiveFeatureFlags, usePostHog } from "posthog-js/react";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { RecoilRoot } from "recoil";

import Predibase from "./Predibase";
import { PredibaseURLState, welcomeUrl } from "./auth/auth0/utils";
import SubscriptionExpirationModal from "./components/GlobalHeader/SubscriptionExpirationModal";
import { PostHogPageviewTracker } from "./metrics/utils";
import { FeatureFlags, FeatureFlagsContext, getFeatureFlags } from "./utils/feature-flags";

import "github-markdown-css/github-markdown-light.css";

import "./App.css";

library.add(fas);

const queryClient = new QueryClient();

// Give Stripe the ability to monitor for fraud prevention
// NOTE: Force-cast env var to string to get rid of missing `apiKey` warning, see [1].
// [1]: https://stackoverflow.com/questions/63059353/invalid-value-for-stripe-apikey-should-be-a-string-you-specified-undefined
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

const stripeElementOptions: StripeElementsOptions = {
    mode: "setup",
    setupFutureUsage: "off_session",
    currency: "usd",
};

const Auth0ProviderWithRouterHookAccess = (props: { children: ReactNode }) => {
    const { children } = props;
    const navigate = useNavigate();
    const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;

    return (
        <Auth0Provider
            // TODO: Move to environment variables:
            domain={auth0Domain || ""}
            clientId={auth0ClientId || ""}
            authorizationParams={{
                // * NOTE: react-router does not expose the origin of the URL, so we use window.location.origin:
                redirect_uri: window.location.origin,
                audience: auth0Audience,
            }}
            onRedirectCallback={(incomingAppState: PredibaseURLState | undefined) => {
                // Parse any query params from the returnTo URL if it exists
                const possibleSearchParams = new URLSearchParams();
                let pathname = welcomeUrl;
                if (incomingAppState?.returnTo) {
                    const returnToUrl = new URL(incomingAppState.returnTo, window.location.origin);
                    pathname = returnToUrl.pathname;
                    const returnToSearchParams = new URLSearchParams(returnToUrl.search);
                    // Merge any query params from returnTo into the current URL
                    for (const [key, value] of returnToSearchParams) {
                        possibleSearchParams.set(key, value);
                    }
                }
                // Preserve the invite token in the URL after the redirect:
                if (incomingAppState?.inviteToken) {
                    possibleSearchParams.set("inviteToken", encodeURIComponent(incomingAppState.inviteToken));
                }
                navigate({
                    pathname,
                    search: possibleSearchParams.toString(),
                });
            }}
            useRefreshTokens
            useRefreshTokensFallback
        >
            {children}
        </Auth0Provider>
    );
};

const App = () => {
    const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(getFeatureFlags());
    const posthog = usePostHog();
    const posthogFeatureFlags = useActiveFeatureFlags();

    // React-query devtools:
    const [showRQDevtools, setShowRQDevtools] = useState(false);
    useEffect(() => {
        // Add global toggle function only in production
        if (import.meta.env.PROD) {
            // @ts-expect-error(2339) - Window is not fully typed and we are adding the toggle function to it
            window.toggleRQDevtools = () => setShowRQDevtools((prev) => !prev);
        }
    }, []);

    // Reload PostHog feature flags every 5 minutes:
    useEffect(() => {
        const refreshPHFFInterval = setInterval(
            () => {
                posthog?.reloadFeatureFlags();
            },
            1000 * 60 * 5,
        ); // 5 minutes

        return () => clearInterval(refreshPHFFInterval);
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <RecoilRoot>
                <FeatureFlagsContext.Provider value={{ featureFlags, posthogFeatureFlags, setFeatureFlags }}>
                    {/*
                     * IMPORTANT: we include Stripe globally so that their Fraud Prevention system (Radar) can catch
                     * bots more easily
                     */}
                    <Elements options={stripeElementOptions} stripe={stripePromise}>
                        {/* TODO: At some point we need to migrate to createBrowserRouter */}
                        <BrowserRouter>
                            {/*
                             * NOTE: Important to keep this order of components, as this gives Auth0Provider access to
                             * react-router's hooks:
                             */}
                            <Auth0ProviderWithRouterHookAccess>
                                <PostHogPageviewTracker />
                                <SubscriptionExpirationModal />
                                <Predibase />
                                {/* <FeatureFlagsEditor /> */}
                            </Auth0ProviderWithRouterHookAccess>
                        </BrowserRouter>
                    </Elements>
                </FeatureFlagsContext.Provider>
            </RecoilRoot>

            {/* Show devtools based on environment and toggle state */}
            {(import.meta.env.DEV || (import.meta.env.PROD && showRQDevtools)) && (
                <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
            )}
        </QueryClientProvider>
    );
};

export default withProfiler(App);
