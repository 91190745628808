import { useStripe } from "@stripe/react-stripe-js";
import { SetupIntentResult, Stripe } from "@stripe/stripe-js";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { useAuth0TokenOptions } from "../../../data";
import { StripeCustomerUnionType, getCustomer, getSetupIntent } from "./data";

// Constants:
export const PAYMENTS_CONSTANT = "payments";
export const CUSTOMER_CONSTANT = "customer";

// Queries:
export const GET_STRIPE_CUSTOMER_QUERY_KEY = [PAYMENTS_CONSTANT, CUSTOMER_CONSTANT];
export const useStripeCustomerQuery = (options?: Partial<UseQueryOptions<StripeCustomerUnionType>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<StripeCustomerUnionType>({
        queryKey: GET_STRIPE_CUSTOMER_QUERY_KEY,
        queryFn: () => getCustomer(auth0TokenOptions),
        ...options,
    });
};

export const GET_STRIPE_SETUP_INTENT_QUERY_KEY = (setupIntentClientSecret: string) => [
    PAYMENTS_CONSTANT,
    "intent",
    { setupIntentClientSecret },
];
export const useStripeSetupIntentQuery = (
    stripe: Stripe, // TODO: lol wat?
    setupIntentClientSecret: string,
    options?: Partial<UseQueryOptions<SetupIntentResult>>,
) => {
    return useQuery<SetupIntentResult>({
        queryKey: GET_STRIPE_SETUP_INTENT_QUERY_KEY(setupIntentClientSecret),
        queryFn: () => getSetupIntent(stripe, setupIntentClientSecret),
        ...options,
    });
};

// Custom hooks (built on top of queries):
export const useStripeSetupIntent = (setupIntentClientSecret: string | null) => {
    const stripeJS = useStripe();
    return useStripeSetupIntentQuery(stripeJS!, setupIntentClientSecret ?? "", {
        enabled: !!stripeJS && !!setupIntentClientSecret,
    });
};
