import { useMemo, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Header, Input, Table } from "semantic-ui-react";

import { deployment, deploymentType } from "@/autogen/openapi";

import { SEMANTIC_GREY } from "../../../utils/colors";
import { filterListOnSelectedAttributeWithSearchText } from "../../../utils/search";
import DeploymentStatusChip from "../../misc/DeploymentStatusChip";
import PromptInstructionsModal from "../modals/PromptInstructionsModal";

const ServerlessDeploymentsTable = (props: {
    deployments?: deployment[];
    // deploymentToAdaptersMap: DeploymentShortNameToSupportedAdaptersMap;
}) => {
    // Meta state:
    const navigate = useNavigate();

    // Parent state:
    const { deployments } = props;

    // Local state:
    const [searchState, setSearchState] = useState<string>("");
    const [selectedDeployment, setSelectedDeployment] = useState<deployment | null>(null);
    const [promptInstructionsModalOpen, setPromptInstructionsModalOpen] = useState(false);

    // Computed state:
    const filteredDeployments = useMemo(() => {
        if (deployments) {
            return filterListOnSelectedAttributeWithSearchText<deployment>(deployments!, "name", searchState);
        }
        return null;
    }, [deployments, searchState]);

    return (
        <>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            <div style={{ display: "flex" }}>
                                <span style={{ marginTop: "0.5rem", marginRight: "1rem" }}>Name</span>
                                <Input
                                    autoFocus
                                    size={"small"}
                                    icon={"search"}
                                    onChange={(event, data) => {
                                        setSearchState(data.value);
                                    }}
                                />
                            </div>
                        </Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        {/* <Table.HeaderCell>Fine-tuned adapters</Table.HeaderCell> */}
                        <Table.HeaderCell>Context Window</Table.HeaderCell>
                        {/* <Table.HeaderCell>Parameters</Table.HeaderCell> */}
                        {/* <Table.HeaderCell>Price (per 1m tokens)</Table.HeaderCell> */}
                        {/* // Empty header for kebab dropdown: */}
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {filteredDeployments?.map((deployment) => (
                        <Table.Row key={deployment.name}>
                            <Table.Cell>
                                {<Link to={`/deployments/${deployment.uuid}`}>{deployment.name}</Link>}
                            </Table.Cell>
                            <Table.Cell>
                                <DeploymentStatusChip status={deployment.status} />
                            </Table.Cell>
                            {/* <Table.Cell>{props.deploymentToAdaptersMap[deployment.name]?.length}</Table.Cell> */}
                            <Table.Cell>{deployment.model.maxTotalTokens}</Table.Cell>
                            {/* <Table.Cell>{deployment.model.numParams}</Table.Cell> */}
                            {/* <Table.Cell>{deployment.cost.centsPerMillionTokens}</Table.Cell> */}
                            <Table.Cell collapsing>
                                <Dropdown
                                    closeOnBlur
                                    direction="left"
                                    icon="ellipsis vertical"
                                    className="icon mini basic"
                                    style={{ color: SEMANTIC_GREY }}
                                >
                                    <Dropdown.Menu style={{ borderStyle: "solid" }}>
                                        <Dropdown.Item
                                            onClick={() =>
                                                navigate(`/prompt?model=${encodeURIComponent(deployment?.uuid ?? "")}`)
                                            }
                                        >
                                            Prompt via UI
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                setSelectedDeployment(deployment);
                                                setPromptInstructionsModalOpen(true);
                                            }}
                                        >
                                            Prompt via REST API/SDK/CLI
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            {selectedDeployment && (
                <PromptInstructionsModal
                    deployment={selectedDeployment}
                    open={promptInstructionsModalOpen}
                    setOpen={setPromptInstructionsModalOpen}
                />
            )}
        </>
    );
};

const ServerlessDeployments = (props: {
    deployments: deployment[] | undefined | null;
    // deploymentToAdaptersMap: DeploymentShortNameToSupportedAdaptersMap;
}) => {
    return (
        <>
            <Header as="h3" style={{ marginBottom: 0 }}>
                Shared Serverless Deployments
            </Header>
            <p>
                Shared instances for experimentation and iteration. Prompt your fine-tuned model via LoRAX without
                needing deploy a private instance.
            </p>

            <ServerlessDeploymentsTable
                deployments={props.deployments?.filter(
                    (sharedDeployment) => sharedDeployment.type === deploymentType.SERVERLESS,
                )}
                // deploymentToAdaptersMap={props.deploymentToAdaptersMap}
            />
        </>
    );
};

export default ServerlessDeployments;
