import React from "react";

import ShowMoreText from "react-show-more-text";
import { Message } from "semantic-ui-react";

const ErrorMessage = (props: { errorMessage: string | React.ReactNode; header?: string }) => (
    <Message negative>
        <Message.Header>{props.header}</Message.Header>
        <div style={{ whiteSpace: "pre-wrap" }}>
            <ShowMoreText
                lines={6}
                keepNewLines={props.errorMessage?.toString().includes("\n")}
                anchorClass="show-more-text-link"
            >
                {props.errorMessage}
            </ShowMoreText>
        </div>
    </Message>
);

export default ErrorMessage;
