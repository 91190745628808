import { useEffect } from "react";

import _ from "lodash";
import { Form, Icon, Popup } from "semantic-ui-react";
import urlJoin from "url-join";

import { adapter, deployment } from "@/autogen/openapi";

import DeploymentStatusChip from "../deployments/misc/DeploymentStatusChip";
import metrics from "../metrics/metrics";
import { SEMANTIC_GREY_DISABLED } from "../utils/colors";
import { rawtextSearch } from "../utils/search";
import { MaxTokensSlider, TemperatureSlider, TopPSlider } from "./Sliders";
import { DoSampleSwitch } from "./Switches";
import { useLegacyModelByIDQuery } from "./query";
import {
    AdapterRepoLookup,
    AdapterVersionLookup,
    DeploymentUUIDLookup,
    DropdownItemsArray,
} from "./utils/dropdown-utils";
import { ActionType, useDispatch, usePromptState } from "./utils/reducer";

import "./ControlBar.css";

const InAppDeploymentsPageLink = () => {
    return (
        <a
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "underline" }}
            href={urlJoin(window.location.origin.toString(), "deployments")}
        >
            See current deployments
        </a>
    );
};

const ApplyChatTemplateTooltip = () => {
    return (
        <Popup
            className="transition-scale"
            hoverable
            wide={"very"}
            position={"right center"}
            trigger={<Icon name={"exclamation triangle"} color={"yellow"} />}
            content={
                <span>
                    Warning: If your adapter was trained with “Apply Chat Template”, please be sure to include the
                    <a href="https://docs.predibase.com/user-guide/fine-tuning/chat_templates">
                        {" "}
                        appropriate chat template{" "}
                    </a>
                    when prompting this adapter.
                </span>
            }
        />
    );
};

const DeploymentStatusIndicator = (props: { deploymentUUIDLookup: DeploymentUUIDLookup }) => {
    // Parent state:
    const { deploymentUUIDLookup } = props;
    // Reducer state:
    const { selectedDeployment } = usePromptState();

    // Parent state:
    // deploymentUUIDLookup is regenerated every time the prompt UI polls for deployments:
    const upToDateStatus = selectedDeployment?.uuid
        ? (deploymentUUIDLookup[selectedDeployment.uuid] as deployment | undefined)?.status
        : undefined;

    return (
        <div style={{ fontWeight: 400, fontSize: `${12 / 14}rem`, marginBottom: "2rem", marginTop: `-${10 / 14}rem` }}>
            <span style={{ color: SEMANTIC_GREY_DISABLED }}>Status: </span>
            {upToDateStatus ? (
                <DeploymentStatusChip status={upToDateStatus} />
            ) : (
                <span style={{ color: SEMANTIC_GREY_DISABLED }}>Cannot fetch status at this time</span>
            )}
        </div>
    );
};

const ControlBar = (props: {
    deploymentSelectorOptions: DropdownItemsArray;
    deploymentUUIDLookup: DeploymentUUIDLookup;
    setPromptTemplateVisible: (value: React.SetStateAction<boolean>) => void;
    adapterRepoOptions: DropdownItemsArray;
    adapterVersionOptions: DropdownItemsArray;
    selectedAdapterRepo: string;
    setSelectedAdapterRepo: (value: React.SetStateAction<string>) => void;
    adapterRepoLookup: AdapterRepoLookup;
    adapterVersionLookup: AdapterVersionLookup;
    setUserHasTypedAtLeastOnceWithCurrentDeployment: React.Dispatch<React.SetStateAction<boolean>>;
    stopGenerateQuery: () => void;
}) => {
    // Parent state:
    const {
        deploymentSelectorOptions,
        deploymentUUIDLookup,
        setPromptTemplateVisible,
        adapterRepoOptions,
        adapterVersionOptions,
        selectedAdapterRepo,
        setSelectedAdapterRepo,
        adapterRepoLookup,
        adapterVersionLookup,
        setUserHasTypedAtLeastOnceWithCurrentDeployment,
        stopGenerateQuery,
    } = props;

    // Reducer state:
    const dispatch = useDispatch();
    const { selectedDeployment, selectedAdapter, maxNewTokens } = usePromptState();

    // Query state:
    // If the user selects an adapter with the prompt template, update the reducer:
    const { data: legacyModel } = useLegacyModelByIDQuery(selectedAdapter?.legacyModelId?.toString() ?? "", {
        refetchOnWindowFocus: false,
        enabled: Boolean(selectedAdapter?.legacyModelId?.toString()),
    });
    useEffect(() => {
        if (legacyModel && _.has(legacyModel.config, "prompt.template")) {
            const promptTemplate = _.get(legacyModel.config, "prompt.template") as string;
            if (promptTemplate !== "") {
                dispatch({
                    type: ActionType.UPDATE,
                    promptTemplate,
                });
                setPromptTemplateVisible(true);
            }
        }
    }, [legacyModel, dispatch, setPromptTemplateVisible]);

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
            <Form style={{ width: "100%" }}>
                <Form.Select
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    name="selectedDeployment"
                    label={
                        <label>
                            <Popup
                                className="transition-scale"
                                content={
                                    <>
                                        Includes private serverless deployments and shared serverless deployments
                                        (SaaS-only). <InAppDeploymentsPageLink />.
                                    </>
                                }
                                position={"right center"}
                                wide={true}
                                trigger={<span>LLM Deployment</span>}
                                hoverable
                            />
                        </label>
                    }
                    options={deploymentSelectorOptions}
                    // ! NOTE: Leaving value as undefined may not properly clear selection when user clicks the X. See:
                    // https://github.com/Semantic-Org/Semantic-UI-React/issues/3625#issuecomment-654199235
                    value={selectedDeployment?.uuid || ""}
                    text={deploymentSelectorOptions.find((option) => option.key === selectedDeployment?.uuid)?.rawtext}
                    placeholder="None"
                    onChange={(_, { value }) => {
                        // Update the deployment selection, clear all other state in the reducer:
                        const newDeployment = deploymentUUIDLookup[value as deployment["uuid"]] as
                            | deployment
                            | undefined;

                        if (newDeployment) {
                            dispatch({
                                type: ActionType.UPDATE,
                                selectedDeployment: newDeployment,
                                selectedAdapter: null,
                                maxNewTokens: Math.min(maxNewTokens, newDeployment?.model.maxTotalTokens),
                            });
                        } else {
                            dispatch({
                                type: ActionType.UPDATE,
                                selectedDeployment: null,
                                selectedAdapter: null,
                            });
                        }

                        // Update UI state:
                        setPromptTemplateVisible(false);
                        setSelectedAdapterRepo("");
                        setUserHasTypedAtLeastOnceWithCurrentDeployment(false);

                        // Cancel any running query and clear the current response data:
                        stopGenerateQuery();
                    }}
                    selection
                    fluid
                    search={rawtextSearch}
                />
                {selectedDeployment && <DeploymentStatusIndicator deploymentUUIDLookup={deploymentUUIDLookup} />}

                <div>
                    <Form.Select
                        className={metrics.BLOCK_AUTO_CAPTURE}
                        name="selectedAdapterRepo"
                        style={{ marginBottom: "2rem" }}
                        label={
                            <label>
                                <Popup
                                    className="transition-scale"
                                    content={
                                        adapterRepoOptions.length > 0
                                            ? `Any fine-tuned model is called an "adapter" and is available to prompt with its base LLM immediately after training.`
                                            : "No adapters available for this deployment"
                                    }
                                    position={"right center"}
                                    wide={true}
                                    trigger={<span>Fine-tuned Adapter</span>}
                                    hoverable
                                />
                            </label>
                        }
                        options={adapterRepoOptions}
                        // ! NOTE: Leaving value as undefined may not propery clear selection when user clicks the X. See:
                        // https://github.com/Semantic-Org/Semantic-UI-React/issues/3625#issuecomment-654199235
                        value={selectedAdapterRepo}
                        placeholder="None"
                        onChange={(_, { value }) => {
                            // Update the adapter repo selection:
                            setSelectedAdapterRepo(value as string);

                            // Try to auto-select the latest version of the adapter:
                            const possibleAdapterVersionOptions = adapterRepoLookup[value as string] as
                                | adapter[]
                                | undefined;
                            if (possibleAdapterVersionOptions) {
                                let latestVersionAdapter = possibleAdapterVersionOptions[0];
                                possibleAdapterVersionOptions.forEach((adapter) => {
                                    if (adapter.versionTag > latestVersionAdapter.versionTag) {
                                        latestVersionAdapter = adapter;
                                    }
                                });
                                dispatch({
                                    type: ActionType.UPDATE,
                                    selectedAdapter: latestVersionAdapter,
                                });

                                // If it's a legacy adapter (i.e. has a prompt template), clear out the current prompt:
                                if (latestVersionAdapter.legacyModelId) {
                                    dispatch({
                                        type: ActionType.UPDATE,
                                        prompt: "",
                                        promptTemplate: null,
                                        promptTemplateVariables: {},
                                    });
                                }
                            }
                            // Otherwise, clear the adapter state in the reducer (anticipating the user's choice):
                            else {
                                dispatch({
                                    type: ActionType.UPDATE,
                                    selectedAdapter: null,
                                });
                            }

                            // Cancel any running query and clear the current response data:
                            stopGenerateQuery();
                        }}
                        selection
                        fluid
                        search={rawtextSearch}
                        clearable
                        disabled={adapterRepoOptions.length === 0}
                    />
                </div>

                <Popup
                    className="transition-scale"
                    content={"No adapter versions available for this repo"}
                    position={"right center"}
                    wide={true}
                    trigger={
                        <div>
                            <Form.Select
                                className={metrics.BLOCK_AUTO_CAPTURE}
                                name="selectedAdapterVersion"
                                label={
                                    <label>
                                        Adapter Version <ApplyChatTemplateTooltip />{" "}
                                    </label>
                                }
                                options={adapterVersionOptions}
                                // ! NOTE: Leaving value as undefined may not propery clear selection when user clicks the X. See:
                                // https://github.com/Semantic-Org/Semantic-UI-React/issues/3625#issuecomment-654199235
                                value={selectedAdapter?.versionTag.toString() || ""}
                                placeholder="None"
                                onChange={(_, { value }) => {
                                    // Update the adapter selection, clear the prompt state:
                                    const selectedAdapter = adapterVersionLookup[value as string] as
                                        | adapter
                                        | undefined;
                                    dispatch({
                                        type: ActionType.UPDATE,
                                        selectedAdapter,
                                    });

                                    // If it's a legacy adapter (i.e. has a prompt template), clear out the current prompt:
                                    if (selectedAdapter?.legacyModelId) {
                                        dispatch({
                                            type: ActionType.UPDATE,
                                            prompt: "",
                                            promptTemplate: null,
                                            promptTemplateVariables: {},
                                        });
                                    }

                                    // Cancel any running query and clear the current response data:
                                    stopGenerateQuery();
                                }}
                                selection
                                fluid
                                search={rawtextSearch}
                                clearable
                                disabled={adapterVersionOptions.length === 0}
                            />
                        </div>
                    }
                    // TODO: Log an error because this should technically never happen (every repo must have at LEAST
                    // one version for the UI to even work!)
                    disabled={adapterVersionOptions.length > 0}
                />
            </Form>
            <MaxTokensSlider />
            <DoSampleSwitch />
            <TemperatureSlider />
            <TopPSlider />
        </div>
    );
};

export default ControlBar;
