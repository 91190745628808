import React, { useState } from "react";

import { Button, Checkbox, Form, Grid, Header, Modal, Segment } from "semantic-ui-react";

import metrics from "../../../metrics/metrics";
import ExplanationArray from "./ExplanationArray";

function renderFeatureList(features: ShapFeature[]) {
    return features.map((feature) => feature.name).join(", ");
}

const ExplanationMetricRoot = "Query.QueryResults.Explanation";

const SUMMARY = "summary";
const FORCE = "force";

function ExplanationArrayModal(props: {
    metadata: ShapMetadata;
    data: (any | ShapResult)[];
    index: number;
    name: React.ReactNode;
}) {
    const [open, setOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const metadata = props.metadata;
    const target = metadata.target;

    const idx2str = metadata.targetIdx2str;
    const indexSelector = idx2str ? (
        <>
            <Header as="h5">Class</Header>
            <Form>
                {idx2str.map((label: string, labelIndex: number) => (
                    <Form.Field key={label}>
                        <Checkbox
                            radio
                            label={label}
                            checked={labelIndex === activeIndex}
                            onChange={(e, data) => setActiveIndex(labelIndex)}
                        />
                    </Form.Field>
                ))}
            </Form>
        </>
    ) : null;
    const Explanation = (
        <ExplanationArray
            metadata={props.metadata}
            data={props.data}
            colIndex={props.index}
            activeIndex={activeIndex}
            name={props.name}
            insideModal={true}
            target={target}
        />
    );

    return (
        <Modal
            className={metrics.BLOCK_AUTO_CAPTURE}
            onClose={() => {
                setOpen(false);
            }}
            onOpen={() => {
                setOpen(true);
            }}
            open={open}
            trigger={<button className={`${metrics.BLOCK_AUTO_CAPTURE} button-reset`}>{props.name}</button>}
        >
            <Modal.Header>Explanation: {target}</Modal.Header>
            <Modal.Content style={{ paddingTop: 10 }}>
                {indexSelector ? (
                    <Grid>
                        <Grid.Column computer={3}>
                            <Segment style={{ backgroundColor: "rgba(0, 0, 0, 0.03)" }}>{indexSelector}</Segment>
                        </Grid.Column>
                        <Grid.Column computer={13}>{Explanation}</Grid.Column>
                    </Grid>
                ) : (
                    Explanation
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    color="black"
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default ExplanationArrayModal;
