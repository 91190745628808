import streamSaver from "streamsaver";

import {
    adapterVersion,
    beginAdapterUploadRequest,
    beginAdapterUploadResponse,
    completeAdapterUploadRequest,
    createFinetuningJobRequest,
    createRepoRequest,
    finetuningJob,
    finetuningJobParams,
    finetuningJobTimeline,
    grpoJobLogs,
    listReposResponse,
    promptCompletions,
    promptRewardsResponse,
    promptsResponse,
    repo,
    rewardFunctionsConfig,
    updateAdapterVersionRequest,
    updateRepoRequest,
} from "@/autogen/openapi";

import { getTraceId } from "../api/trace";
import { APIResponse, Auth0TokenOptions } from "../data";
import { PodLogsWithTimestamp } from "../deployments/views/deployment/tabs/GrafanaLogs";
import metrics from "../metrics/metrics";
import {
    createV2APIServer,
    getAuth0TokenWithFallbacks,
    getV2APIEndpoint,
    redirectIfSessionInvalid,
} from "../utils/api";
import { getErrorMessage } from "../utils/errors";

// TODO: Should be autogenerated:
export interface listAdapterReposParams {
    name?: string;
    baseModel?: string;
    limit?: number;
    offset?: number;
}

export interface versionArchivalParams {
    repoUUID: string;
    versionTag: number;
    archive: boolean;
}

// CRUD operations around adapter repos:
export const createAdapterRepo = async (config: createRepoRequest, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "/repos";
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .post<repo>(endpoint, config)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getAdapterRepo = async (uuid: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `/repos/${uuid}`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .get<repo>(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const updateAdapterRepoDescription = async (
    repoUUID: string,
    description: string,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `/repos/${repoUUID}`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .put<updateRepoRequest>(endpoint, { description })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "PUT",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getAdapterRepos = async (params?: listAdapterReposParams, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "/repos";
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return (
        v2APIServer
            // TODO: Makes me think: should we let this endpoint accept no params, or do we always have to pass at least
            // an empty set of params?
            .get<APIResponse<listReposResponse>>(endpoint, { params: { ...params } })
            .then((res) => {
                return res.data.data;
            })
            .catch((error) => {
                const errorMsg = getErrorMessage(error) ?? "";
                metrics.captureError("api_error", errorMsg, {
                    method: "GET",
                    endpoint,
                    trace_id: getTraceId(error),
                });
                redirectIfSessionInvalid(errorMsg);
                throw errorMsg;
            })
    );
};

// CRUD operations around adapters (incomplete):
export const getAdapterVersion = async (
    repoUUID: string,
    versionTag: number,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `/repos/${repoUUID}/version/${versionTag}`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .get<adapterVersion>(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const beginAdapterVersionUpload = async (
    req: beginAdapterUploadRequest,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `/adapters/upload`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .post<beginAdapterUploadResponse>(endpoint, req)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const completeAdapterVersionUpload = async (
    req: completeAdapterUploadRequest,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `/adapters/upload`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .put<adapterVersion>(endpoint, req)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "PUT",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const updateAdapterVersionDescription = async (
    repoUUID: string,
    versionTag: number,
    description: string,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `/repos/${repoUUID}/version/${versionTag}`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .put<updateAdapterVersionRequest>(endpoint, { description })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "PUT",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

// CRUD operations around jobs (incomplete):
export const createFinetuningJob = async (
    config: createFinetuningJobRequest,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = "/finetuning/jobs";
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .post<finetuningJob>(endpoint, config)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getFinetuningJob = async (uuid: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `/finetuning/jobs/${uuid}`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .get<finetuningJob>(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getFinetuningJobTimeline = async (uuid: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `/finetuning/jobs/${uuid}/timeline`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .get<finetuningJobTimeline>(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getGRPOPodLogsWithTimestamp = async (uuid: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `/finetuning/jobs/${uuid}/grpo_logs`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .get<APIResponse<grpoJobLogs>>(endpoint)
        .then((res) => {
            return {
                data: res.data.data,
                timestamp: Date.now(),
            } as PodLogsWithTimestamp;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getGRPOPrompts = async (
    uuid: string,
    offset: number,
    total: number,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `/finetuning/jobs/${uuid}/grpo_prompts?offset=${offset}&total=${total}`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .get<promptsResponse>(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getGRPOPromptRewards = async (uuid: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `/finetuning/jobs/${uuid}/grpo_prompt_rewards`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .get<promptRewardsResponse>(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getGRPOPromptCompletions = async (
    uuid: string,
    prompt_id: string,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `/finetuning/jobs/${uuid}/grpo_prompt_completions/${prompt_id}`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .get<promptCompletions>(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getGRPOFunctions = async (uuid: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `/finetuning/jobs/${uuid}/reward_functions?history=true`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .get<rewardFunctionsConfig>(endpoint)
        .then((res) => {
            for (const func in res.data.functions) {
                if (res.data.functions[func].history) {
                    res.data.functions[func].history = res.data.functions[func].history.toReversed();
                }
            }

            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const cancelFinetuningJob = async (uuid: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `/finetuning/jobs/${uuid}/cancel`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .post<undefined>(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error: any) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const archiveOrUnarchiveVersion = async (
    params: versionArchivalParams,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const { repoUUID, versionTag, archive } = params;
    const endpoint = `/repos/${repoUUID}/version/${versionTag}`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer.put<updateAdapterVersionRequest>(endpoint, { archived: archive });
};

export interface versionDeleteParams {
    repoUUID: string;
    versionTag: number;
}

export const deleteAdapterVersion = async (params: versionDeleteParams, auth0TokenOptions?: Auth0TokenOptions) => {
    const { repoUUID, versionTag } = params;
    const endpoint = `/repos/${repoUUID}/version/${versionTag}`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .delete(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "DELETE",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const streamDownloadAdapterVersion = async (
    repoUUID: string,
    versionTag: number,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const fullEndpoint = `${getV2APIEndpoint()}/repos/${repoUUID}/version/${versionTag}/download`;
    let token = "";
    if (
        auth0TokenOptions !== undefined &&
        auth0TokenOptions.getAccessTokenSilently !== undefined &&
        auth0TokenOptions.getAccessTokenWithPopup !== undefined &&
        auth0TokenOptions.loginWithPopup !== undefined
    ) {
        token = await getAuth0TokenWithFallbacks(
            auth0TokenOptions.getAccessTokenSilently,
            auth0TokenOptions.getAccessTokenWithPopup,
            auth0TokenOptions.loginWithPopup,
        );
    }

    try {
        const response = await fetch(fullEndpoint, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Get filename from Content-Disposition header or use a default
        const contentDisposition = response.headers.get("content-disposition");
        const filenameMatch = contentDisposition?.match(/filename="?([^"]+)"?/);
        const filename = filenameMatch?.[1] || `adapter-${repoUUID}-${versionTag}.safetensors.zip`;

        // Create a write stream
        const fileStream = streamSaver.createWriteStream(filename);

        // Pipe the response to the write stream
        if (response.body) {
            const readableStream = response.body;
            return readableStream.pipeTo(fileStream);
        }

        throw new Error("Response body is null");
    } catch (error: any) {
        const errorMsg = getErrorMessage(error) ?? "";
        metrics.captureError("api_error", errorMsg, {
            method: "GET",
            endpoint: fullEndpoint,
            trace_id: getTraceId(error),
        });
        redirectIfSessionInvalid(errorMsg);
        throw errorMsg;
    }
};

// TODO: The codegen failed to generate a complete type here, which is surprising:
type updateJobConfigParams = finetuningJobParams & {
    rewardFns?: rewardFunctionsConfig;
};
export const updateJobConfig = async (
    uuid: string,
    params: updateJobConfigParams,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    console.log("Updating job config");
    const endpoint = `/finetuning/jobs/${uuid}/config`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .put<undefined>(endpoint, params)
        .then((res) => {
            return res.data;
        })
        .catch((error: any) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "PUT",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};
