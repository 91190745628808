import React, { useEffect, useMemo, useRef, useState } from "react";

import { keepPreviousData, useQueryClient } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import { Icon, Loader, Message, Pagination } from "semantic-ui-react";
import { PaginationProps } from "semantic-ui-react/dist/commonjs/addons/Pagination/Pagination";

import ScopeUserToggle from "../../components/ScopeUserToggle";
import SimpleSearch from "../../components/SimpleSearch";
import {
    DATASETS_OFFSET_STATE,
    DATASETS_SCOPE_USER_STATE,
    DATASETS_SEARCH_VAL_STATE,
    DATASETS_TOTAL_STATE,
} from "../../state/data";
import { generateParamsNoRef, getActivePage, getTotalPages } from "../../utils/pagination";
import { GET_DATASETS_QUERY_KEY, useDatasetsQuery } from "../query";
import DatasetsTableBase from "./DatasetsTableBase";

const DATASETS_PER_PAGE = 10;

const DatasetsTable = (props: { indexKeys?: string; indexVals?: string | number; ignoreRecoilSearch?: boolean }) => {
    const [datasetsOffset, setDatasetsOffset] = useRecoilState(DATASETS_OFFSET_STATE);
    const [datasetsTotal, setDatasetsTotal] = useRecoilState(DATASETS_TOTAL_STATE);
    const [scopeUser, setScopeUser] = useRecoilState(DATASETS_SCOPE_USER_STATE);
    const [datasetSearchVal] = useRecoilState(DATASETS_SEARCH_VAL_STATE);
    // SimpleSearch mutates the searchVal since using recoil state alone isn't sufficient
    const searchValRef = useRef(props.ignoreRecoilSearch ? "" : datasetSearchVal);

    // TODO: I hate this, but it's the simplest fix. The ref and timer usage nonsense here is so stupid.
    const [debouncedSearchVal, setDebouncedSearchValRef] = useState<string>(searchValRef.current);
    const params = useMemo(() => {
        return generateParamsNoRef(
            datasetsOffset,
            DATASETS_PER_PAGE,
            scopeUser,
            ["name"],
            debouncedSearchVal,
            props.indexVals,
            props.indexKeys,
        );
    }, [datasetsOffset, debouncedSearchVal, props.indexKeys, props.indexVals, scopeUser]);

    // Query (pagination-based) state:
    const queryClient = useQueryClient();
    const { status, data, error } = useDatasetsQuery(params, {
        placeholderData: keepPreviousData,
        staleTime: 5 * 1000, // 5 seconds
        refetchInterval: 5 * 1000, // 5 seconds
    });
    useEffect(() => {
        if (data) {
            setDatasetsOffset(data.offset);
            setDatasetsTotal(data.total);
        }
    }, [data, setDatasetsOffset, setDatasetsTotal]);

    const getActiveDatasetsPage = () => getActivePage(datasetsOffset, DATASETS_PER_PAGE);
    const getTotalSourcesPages = () => getTotalPages(datasetsTotal, DATASETS_PER_PAGE);
    const handleSourcesPageChange = (e: React.MouseEvent<HTMLAnchorElement>, { activePage }: PaginationProps) => {
        const offset = (Number(activePage) - 1) * DATASETS_PER_PAGE;
        setDatasetsOffset(offset);
        queryClient.invalidateQueries({ queryKey: GET_DATASETS_QUERY_KEY(params) });
    };

    // Reset the Recoil state when viewing Connect Details
    useEffect(() => {
        return () => {
            if (props.indexVals) {
                setDatasetsOffset(0);
            }
        };
    }, []);

    if (status === "pending") {
        return <Loader />;
    }

    if (status === "error") {
        return <Message error>{error.message}</Message>;
    }

    if (typeof data === "undefined" || !Array.isArray(data.datasets)) {
        return <Message error>Unable to load datasets.</Message>;
    }

    const { datasets } = data;

    return (
        <>
            <DatasetsTableBase
                datasets={datasets}
                searchbar={
                    <SimpleSearch
                        searchValRef={searchValRef}
                        searchValRecoilState={DATASETS_SEARCH_VAL_STATE}
                        setOffset={setDatasetsOffset}
                        placeholder={"Search..."}
                        ignoreRecoilState={props.ignoreRecoilSearch}
                        debouncedFunction={(debouncedParams: PaginationParams) => {
                            setDebouncedSearchValRef(debouncedParams.searchVals[0]);
                        }}
                    />
                }
                scopeUserToggle={<ScopeUserToggle scopeUser={scopeUser} setScopeUser={setScopeUser} />}
            />
            <div style={{ justifyContent: "center", display: "flex" }}>
                <Pagination
                    activePage={getActiveDatasetsPage()}
                    totalPages={getTotalSourcesPages()}
                    onPageChange={handleSourcesPageChange}
                    firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                    lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                    prevItem={{ content: <Icon name="angle left" />, icon: true }}
                    nextItem={{ content: <Icon name="angle right" />, icon: true }}
                />
            </div>
        </>
    );
};

export default DatasetsTable;
