import { QueryClient, UseQueryOptions, useQuery } from "@tanstack/react-query";

import { adapter } from "@/autogen/openapi";

import { useAuth0TokenOptions } from "../data";
import { ADAPTERS_CONSTANT, DEPLOYMENTS_CONSTANT } from "../query";
import {
    DeploymentIsReadyResponse,
    GenerateParameters,
    GenerateResponse,
    generate_stream,
    getAdaptersList,
    getDeploymentIsReady,
    getLegacyModelByID,
} from "./data";

export const GET_LEGACY_MODEL_BY_ID = (modelID: string) => [ADAPTERS_CONSTANT, "legacy", modelID];
export const useLegacyModelByIDQuery = (modelID: string, options?: Partial<UseQueryOptions<Model>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<Model>({
        queryKey: GET_LEGACY_MODEL_BY_ID(modelID),
        queryFn: () => getLegacyModelByID(modelID, auth0TokenOptions),
        ...options,
    });
};

export const GET_ADAPTERS_LIST_QUERY_KEY = [ADAPTERS_CONSTANT];
export const useAdaptersListQuery = (options?: Partial<UseQueryOptions<adapter[]>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<adapter[]>({
        queryKey: GET_ADAPTERS_LIST_QUERY_KEY,
        queryFn: () => getAdaptersList(auth0TokenOptions),
        ...options,
    });
};

export const GET_DEPLOYMENT_IS_READY_QUERY_KEY = (deploymentName: string) => [
    DEPLOYMENTS_CONSTANT,
    deploymentName,
    "is_ready",
];
export const useDeploymentIsReadyQuery = (
    deploymentName: string,
    options?: Partial<UseQueryOptions<DeploymentIsReadyResponse>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<DeploymentIsReadyResponse>({
        queryKey: GET_DEPLOYMENT_IS_READY_QUERY_KEY(deploymentName),
        queryFn: () => getDeploymentIsReady(deploymentName, auth0TokenOptions),
        ...options,
    });
};

export const GENERATE_STREAM_QUERY_KEY = ["generate", "stream"];
// TODO: Perhaps convert this to a mutation?
export const useGenerateStreamQuery = (
    tenantShortcode: string,
    apiTokenString: string,
    generateParameters: GenerateParameters,
    queryClient: QueryClient,
    options?: Partial<UseQueryOptions<GenerateResponse | undefined>>,
) => {
    return useQuery<GenerateResponse | undefined>({
        queryKey: GENERATE_STREAM_QUERY_KEY,
        queryFn: (context) =>
            generate_stream(tenantShortcode, apiTokenString, generateParameters, context, queryClient),
        ...options,
    });
};
