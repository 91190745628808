import { setupVPCRequest } from "@/autogen/openapi";

import { getTraceId } from "../../api/trace";
import { Auth0TokenOptions } from "../../data";
import metrics from "../../metrics/metrics";
import { createV1APIServer, createV2APIServer, redirectIfSessionInvalid } from "../../utils/api";
import { getErrorMessage } from "../../utils/errors";

export const reprovisionEnvironment = async (shortCode: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `environments/${shortCode}/provision`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer.post(endpoint).catch((error) => {
        const errorMsg = getErrorMessage(error) ?? "";
        metrics.captureError("api_error", errorMsg, {
            method: "POST",
            endpoint,
            trace_id: getTraceId(error),
        });
        redirectIfSessionInvalid(errorMsg);
        throw errorMsg;
    });
};

export const setupVPC = async (config: setupVPCRequest, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `auth/setup-vpc`;
    const apiServer = await createV2APIServer(auth0TokenOptions);

    return apiServer.post(endpoint, config).catch((error) => {
        const errorMsg = getErrorMessage(error) ?? "";
        metrics.captureError("api_error", errorMsg, {
            method: "POST",
            endpoint,
            trace_id: getTraceId(error),
        });
        redirectIfSessionInvalid(errorMsg);
        throw errorMsg;
    });
};
