import AceEditor from "react-ace";

// ! ace-builds must ALWAYS be imported AFTER react-ace
// TODO: Why tho... (https://stackoverflow.com/questions/69545499/referenceerror-ace-is-not-defined
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/theme-chrome";

import { toYAML } from "../../utils/config";

function ModelConfigViewer(props: { config: any }) {
    return (
        <AceEditor
            readOnly={true}
            maxLines={5000}
            width="100%"
            mode="yaml"
            theme="chrome"
            fontSize={14}
            showPrintMargin={false}
            showGutter={false}
            tabSize={4}
            highlightActiveLine={false}
            value={toYAML(props.config)}
            setOptions={{
                // fontFamily: '"Fira code", "Fira Mono", monospace',
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
                // Fixes issue with Ace Editor trying to import global worker
                // https://stackoverflow.com/questions/60857214/uncaught-domexception-failed-to-execute-importscripts-on-workerglobalscope
                useWorker: false,
            }}
        />
    );
}

export default ModelConfigViewer;
