import { useState } from "react";

import { Form } from "semantic-ui-react";

import { SideBySideField, SingularField, StackedFormGroup } from "../../../components/StackedForm";
import { SEMANTIC_GREY } from "../../../utils/colors";
import ConnectorPanel from "../ConnectorPanel";
import { Connector, CreateConnectionEndpoint } from "../util";
import { getAlreadyConnectedMessage } from "./util";

function BigQueryConnector(props: ConnectorProps) {
    const [connectionName, setConnectionName] = useState(
        props.connectorConfig.connectionName ? props.connectorConfig.connectionName : "",
    );

    const [project, setProject] = useState(props.connectorConfig.project ? props.connectorConfig.project : "");
    const [dataset, setDataset] = useState(props.connectorConfig.dataset ? props.connectorConfig.dataset : "");
    const [token, setToken] = useState(props.connectorConfig.token ? props.connectorConfig.token : "");

    const alreadyConnected = props.connection !== undefined;

    const reset = () => {
        setProject("");
        setDataset("");
        setToken("");
    };

    const getAddRequest = () => {
        // const name = dataset;
        const config = {
            project: project.trim(),
            dataset: dataset.trim(),
            token: token.trim(),
        };

        return {
            endpoint: CreateConnectionEndpoint,
            body: {
                connection: {
                    type: "bigquery",
                    name: connectionName.trim(),
                },
                secret: {
                    secret: config,
                },
            },
            headers: {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        };
    };

    const getUpdateRequest = () => {
        // const name = dataset;
        const config = {
            project: project,
            dataset: dataset,
            token: token,
        };

        return {
            endpoint: "sources/update",
            body: {
                connection: {
                    id: props.connection!.id,
                    type: props.connection!.type,
                    uri: project,
                    name: connectionName,
                },
                secret: {
                    secret: config,
                },
            },
            headers: {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        };
    };

    const isDisabled = () => {
        return !dataset || !token || !project || !connectionName;
    };

    const tokenPlaceholder = JSON.stringify(
        {
            type: "service_account",
            project_id: "yourProjectID",
            private_key_id: "yourPrivateKeyId",
            private_key: "-----BEGIN PRIVATE KEY-----999999999999999999999999-----END PRIVATE KEY-----\n",
            client_email: "predibase-admin-email@example.iam.gserviceaccount.com",
            client_id: "111111111111111111111",
            auth_uri: "https://accounts.google.com/o/oauth2/auth",
            token_uri: "https://oauth2.googleapis.com/token",
            auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
            client_x509_cert_url:
                "https://www.googleapis.com/robot/v1/metadata/x509/predibase-admin-email%40example.iam.gserviceaccount.com",
        },
        undefined,
        4,
    );

    const getModalContent = () => {
        return (
            <>
                {getAlreadyConnectedMessage(alreadyConnected, !props.isEditView)}
                <Form>
                    <SingularField
                        autoFocus={true}
                        disabled={alreadyConnected && !props.isEditView}
                        name="name"
                        placeholder="bigquery_datasets"
                        value={connectionName}
                        setState={setConnectionName}
                        description="Choose a name for your connection in Predibase"
                        label="Connection Name"
                    />
                    <StackedFormGroup>
                        <SideBySideField
                            disabled={alreadyConnected && !props.isEditView}
                            name="project"
                            label="Project ID"
                            placeholder="my-bqproject"
                            value={project}
                            setState={setProject}
                            description={
                                <>
                                    The{" "}
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://cloud.google.com/resource-manager/docs/creating-managing-projects"
                                    >
                                        project ID
                                    </a>{" "}
                                    used to identify your project in BigQuery
                                </>
                            }
                        />
                        <SideBySideField
                            disabled={alreadyConnected && !props.isEditView}
                            name="dataset"
                            label="Dataset"
                            placeholder="test-dataset"
                            value={dataset}
                            setState={setDataset}
                            description={
                                <>
                                    The{" "}
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://cloud.google.com/bigquery/docs/datasets-intro"
                                    >
                                        dataset
                                    </a>{" "}
                                    that contains your tables and views in BigQuery
                                </>
                            }
                        />
                    </StackedFormGroup>
                    <StackedFormGroup>
                        <Form.Field>
                            <label style={{ paddingLeft: "7px" }}>Service Account Key</label>
                            <p
                                style={{
                                    color: SEMANTIC_GREY,
                                    fontSize: "0.9em",
                                    marginBottom: "5px",
                                    paddingLeft: "7px",
                                }}
                            >
                                Paste the JSON associated with a service account that has BigQuery privileges.&nbsp;
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://cloud.google.com/iam/docs/creating-managing-service-account-keys#iam-service-account-keys-create-console"
                                >
                                    See Google Cloud documentation for further details
                                </a>
                            </p>
                            <Form.TextArea
                                disabled={alreadyConnected && !props.isEditView}
                                autoComplete="off"
                                rows={8}
                                name="token"
                                placeholder={tokenPlaceholder}
                                value={token}
                                onChange={(event, data) => setToken(event.target.value)}
                            />
                        </Form.Field>
                    </StackedFormGroup>
                </Form>
            </>
        );
    };

    return (
        <ConnectorPanel
            modalHeader="Connect to BigQuery"
            header="BigQuery"
            editModalHeader="Edit BigQuery Connection"
            submitLabel="Connect"
            name={Connector.BIGQUERY}
            errorHeader="Error in BigQuery connection"
            isDisabled={isDisabled}
            getModalContent={getModalContent}
            getAddRequest={getAddRequest}
            getUpdateRequest={getUpdateRequest}
            reset={reset}
            {...props}
        />
    );
}

export default BigQueryConnector;
