import { useEffect, useState } from "react";

import type { Dayjs } from "dayjs";
import type { Duration } from "dayjs/plugin/duration";
import { Loader } from "semantic-ui-react";

import dayjsExtended, { durationAsString } from "../../../utils/dayjs";

const getDurationSinceStart = (start: Dayjs): Duration => {
    return dayjsExtended.duration(dayjsExtended().diff(start));
};

const CountUpTimer = (props: { start: Dayjs; color: string; posLoaderLeft?: boolean }) => {
    const { start, color, posLoaderLeft } = props;
    const [duration, setDuration] = useState<Duration>();

    useEffect(() => {
        setDuration(getDurationSinceStart(start));
        const interval = setInterval(() => {
            setDuration(getDurationSinceStart(start));
        }, 1000);
        return () => clearInterval(interval);
    }, [start]);

    const durationString = durationAsString(duration);

    const durationComp = <span style={{ color: color }}>{durationString}</span>;
    const loader = <Loader inline active size={"tiny"} />;

    return posLoaderLeft ? (
        <>
            {loader}&emsp;{durationComp}
        </>
    ) : (
        <>
            {durationComp}&emsp;{loader}
        </>
    );
};

export default CountUpTimer;
