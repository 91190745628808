import { useState } from "react";

import { Form, Icon, Message, Popup } from "semantic-ui-react";
import urlJoin from "url-join";

import { SideBySideField, SingularField, StackedFormGroup } from "../../../components/StackedForm";
import { getDocsHome } from "../../../utils/api";
import ConnectorPanel from "../ConnectorPanel";
import { Connector, CreateConnectionEndpoint } from "../util";
import { getAlreadyConnectedMessage } from "./util";

const AccountIdentifierDocsLink = () => {
    return (
        <a
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "underline" }}
            href={urlJoin(getDocsHome(), "sdk-guide/data/connections/connect_snowflake#snowflake-rbac-requirements")}
        >
            docs
        </a>
    );
};

const AccountIdentifierPopup = () => {
    return (
        <Popup
            // className="transition-scale"
            position={"right center"}
            trigger={<Icon name={"question circle"} color={"blue"} />}
            content={
                <span>
                    This is different from your account locator, which may appear in your instance's URL. It usually
                    takes the form of "org-accountname". Check out the <AccountIdentifierDocsLink /> for further
                    clarification.
                </span>
            }
            on={"click"}
        />
    );
};

const SnowflakeConnector = (props: ConnectorProps) => {
    const [connectionName, setConnectionName] = useState("");

    const [user, setUser] = useState(props.connectorConfig.user ? props.connectorConfig.user : "");
    const [password, setPassword] = useState(props.connectorConfig.password ? props.connectorConfig.password : "");
    const [account, setAccount] = useState(props.connectorConfig.account ? props.connectorConfig.account : "");
    const [database, setDatabase] = useState(props.connectorConfig.database ? props.connectorConfig.database : "");
    const [warehouse, setWarehouse] = useState(props.connectorConfig.warehouse ? props.connectorConfig.warehouse : "");
    const [schema, setSchema] = useState(props.connectorConfig.schema ? props.connectorConfig.schema : "");

    const alreadyConnected = props.connection !== undefined;

    const reset = () => {
        setConnectionName("");
        setUser("");
        setPassword("");
        setAccount("");
        setDatabase("");
        setWarehouse("");
        setSchema("");
    };

    const getAddRequest = () => {
        const config = {
            user: user.trim(),
            password: password.trim(),
            account: account.trim().replace(".", "-"),
            database: database.trim(),
            warehouse: warehouse.trim(),
            schema: schema.trim(),
        };

        return {
            endpoint: CreateConnectionEndpoint,
            body: {
                connection: {
                    type: "snowflake",
                    name: connectionName.trim(),
                },
                secret: {
                    secret: config,
                },
            },
            headers: {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        };
    };

    const getUpdateRequest = () => {
        const config = {
            user: user,
            password: password,
            account: account.replace(".", "-"),
            database: database,
            warehouse: warehouse,
            schema: schema,
        };

        return {
            endpoint: "sources/update",
            body: {
                connection: {
                    id: props.connection!.id,
                    uri: database,
                    type: props.connection!.type,
                    name: connectionName,
                },
                secret: {
                    secret: config,
                },
            },
            headers: {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        };
    };

    const isDisabled = () => {
        return !user || !password || !account || !database || !schema || !warehouse || !connectionName;
    };

    const getModalContent = () => {
        return (
            <>
                {getAlreadyConnectedMessage(alreadyConnected, !props.isEditView)}
                <Form>
                    <Message info>
                        <b>Note</b>: Please ensure that you've set up the correct access rights in your Snowflake
                        account to allow Predibase to connect to your data.{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={urlJoin(
                                getDocsHome(),
                                "sdk-guide/data/connections/connect_snowflake#snowflake-rbac-requirements",
                            )}
                        >
                            {" "}
                            Learn more
                        </a>
                    </Message>
                    <SingularField
                        autoFocus={true}
                        disabled={alreadyConnected && !props.isEditView}
                        name="name"
                        placeholder="snowflake_datasets"
                        value={connectionName}
                        setState={setConnectionName}
                        description="Choose a name for your connection in Predibase"
                        label="Connection Name"
                    />
                    <StackedFormGroup>
                        <SideBySideField
                            disabled={alreadyConnected && !props.isEditView}
                            name="user"
                            placeholder="predibase"
                            value={user}
                            setState={setUser}
                            description="User to authenticate as"
                            label="Username"
                        />
                        <SideBySideField
                            disabled={alreadyConnected && !props.isEditView}
                            name="password"
                            placeholder="predibase"
                            value={password}
                            setState={setPassword}
                            description="Password used to authenticate"
                            label="Password"
                            password
                        />
                    </StackedFormGroup>
                    <StackedFormGroup>
                        <SideBySideField
                            disabled={alreadyConnected && !props.isEditView}
                            name="account"
                            placeholder="BNXGNSP.IM27383"
                            value={account}
                            setState={setAccount}
                            description={
                                <span>
                                    Identifier of your snowflake instance. <AccountIdentifierPopup />
                                </span>
                            }
                            label="Account Identifier"
                        />
                        <SideBySideField
                            disabled={alreadyConnected && !props.isEditView}
                            name="database"
                            placeholder="DEMO_DB"
                            value={database}
                            setState={setDatabase}
                            description={"Database in Snowflake that contains the schema and tables you'd like to use"}
                            label="Database Name"
                        />
                    </StackedFormGroup>
                    <StackedFormGroup>
                        <SideBySideField
                            disabled={alreadyConnected && !props.isEditView}
                            name="warehouse"
                            placeholder="COMPUTE_WH"
                            value={warehouse}
                            setState={setWarehouse}
                            description="Warehouse in Snowflake that will be used for performing queries, when possible"
                            label="Database Warehouse"
                        />
                        <SideBySideField
                            disabled={alreadyConnected && !props.isEditView}
                            name="schema"
                            placeholder="public"
                            value={schema}
                            setState={setSchema}
                            description="Logical grouping of tables within Snowflake database"
                            label="Database Schema"
                        />
                    </StackedFormGroup>
                </Form>
            </>
        );
    };

    return (
        <ConnectorPanel
            modalHeader="Connect to Snowflake"
            header="Snowflake"
            editModalHeader="Edit Snowflake Connection"
            submitLabel="Connect"
            name={Connector.SNOWFLAKE}
            errorHeader="Error in Snowflake connection"
            isDisabled={isDisabled}
            getModalContent={getModalContent}
            getAddRequest={getAddRequest}
            getUpdateRequest={getUpdateRequest}
            reset={reset}
            {...props}
        />
    );
};

export default SnowflakeConnector;
