import { Icon } from "semantic-ui-react";

import Chip from "../../components/Chip";
import { SEMANTIC_GREEN, SEMANTIC_GREEN_ACTIVE, SEMANTIC_GREY } from "../../utils/colors";

const AdapterStatusChip = (props: { adapterPath?: string; style?: React.CSSProperties }) => {
    // Parent props:
    const { adapterPath, style } = props;

    let text = <>Not Ready</>;
    let color = SEMANTIC_GREY;
    let textColor = undefined;
    let opacity = 0.1;

    if (adapterPath) {
        text = (
            <>
                <Icon name="check circle" />
                Ready
            </>
        );
        color = SEMANTIC_GREEN;
        textColor = SEMANTIC_GREEN_ACTIVE;
    }

    return (
        <Chip
            color={color}
            text={text}
            textColor={textColor}
            opacity={opacity}
            width={`${80 / 14}rem`}
            style={{ fontSize: `${11 / 14}rem`, maxHeight: "1.64286rem", marginRight: "0.57rem", ...style }}
        />
    );
};

export default AdapterStatusChip;
