import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Button, Divider, Dropdown, Header, Loader, Popup } from "semantic-ui-react";
import urlJoin from "url-join";

import {
    adapterTaskType,
    adapterVersion,
    finetuningJobStatus,
    grpoJobMetrics,
    repo,
    role,
    sftMetricsPayload,
    tier,
} from "@/autogen/openapi";

import CopyButton from "../../../components/CopyButton";
import { SubscriptionButton } from "../../../components/GlobalHeader/SubscriptionButtons";
import { useAuth0TokenOptions } from "../../../data";
import { formatAdapterType } from "../../../deployments/misc/utils";
import metrics from "../../../metrics/metrics";
import { useUserRole } from "../../../settings/query";
import { USER_STATE } from "../../../state/global";
import { getClientEndpoint } from "../../../utils/api";
import { SEMANTIC_BLUE, SEMANTIC_GREY, SEMANTIC_WHITE } from "../../../utils/colors";
import AdapterBreadcrumb from "../../AdapterBreadcrumb";
import { streamDownloadAdapterVersion } from "../../data";
import AdapterStatusChip from "../../misc/AdapterStatusChip";
import JobStatusChip, { getColorAndOpacityForStatus } from "../../misc/JobStatusChip";
import { isTerminalJobStatus } from "../../misc/utils";
import { adapterTypes } from "../create/utils";

import "./AdapterVersionView.css";

const JobStatusSubheader = (props: { adapterVersion: adapterVersion }) => {
    const { adapterVersion } = props;
    const showSpinner = adapterVersion.status === finetuningJobStatus.TRAINING;
    return (
        <span style={{ marginRight: "1.71rem" }}>
            {adapterVersion.finetuningJob ? (
                <>
                    Training Status: <JobStatusChip status={adapterVersion.status} />{" "}
                    {showSpinner && <Loader active inline size="mini" />}
                </>
            ) : (
                <>Imported Adapter</>
            )}
        </span>
    );
};

const EpochSubheader = (props: {
    jobStatus: finetuningJobStatus | undefined;
    latestStreamingMetrics: sftMetricsPayload | grpoJobMetrics | undefined;
}) => {
    const { jobStatus, latestStreamingMetrics } = props;
    const currentEpoch = latestStreamingMetrics?.data?.epoch;
    const totalSteps = latestStreamingMetrics?.meta?.total_steps;
    const currentStep = latestStreamingMetrics?.data?.steps;

    if (currentEpoch && totalSteps && currentStep && jobStatus) {
        const { textColor } = getColorAndOpacityForStatus(jobStatus);
        return (
            <span style={{ marginRight: "1.71rem", color: textColor }}>
                Epoch: {currentEpoch} (Step {currentStep} of {totalSteps})
            </span>
        );
    }
    return null;
};

const AdapterHeader = (props: {
    repo: repo;
    adapterVersion: adapterVersion;
    repoUUID: repo["uuid"];
    versionTag: number;
    setPromptInstructionsModalOpen: (open: boolean) => void;
    setRetrainModalOpen: (open: boolean) => void;
    setCancelTrainingJobModalOpen: (open: boolean) => void;
    latestStreamingMetrics: sftMetricsPayload | grpoJobMetrics | undefined;
}) => {
    const {
        repo,
        adapterVersion,
        repoUUID,
        versionTag,
        setPromptInstructionsModalOpen,
        setRetrainModalOpen,
        setCancelTrainingJobModalOpen,
        latestStreamingMetrics,
    } = props;

    // Router state:
    const navigate = useNavigate();

    // User state:
    const [userContext] = useRecoilState(USER_STATE);
    const userRole = useUserRole();
    // Derived user state:
    const userTier = userContext?.tenant.tier;
    const userIsInEnterpriseTier = userTier && [tier.ENTERPRISE_SAAS, tier.ENTERPRISE_VPC].includes(userTier);

    // Query state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Derived adapter state:
    const adapterName = `${repo?.name}/${adapterVersion?.tag}`;
    const adapterPathIsAvailable = Boolean(adapterVersion?.adapterPath);
    const isLegacyAdapter = adapterVersion?.legacyModelId !== undefined;
    const isSolarAdapter = adapterVersion?.baseModel.includes("solar-1-mini-chat");
    const isImportedAdapter = adapterVersion?.importedAdapterProperties !== undefined;
    const isTurboAdapter = (adapterVersion?.adapterType as adapterTypes) === "turbo";
    const isLoRAAdapter = (adapterVersion?.adapterType as adapterTypes) === "lora";
    const isGRPOTask = adapterVersion?.taskType === adapterTaskType.GRPO;
    const canBeContinued = adapterPathIsAvailable && (isImportedAdapter || isLoRAAdapter);

    return (
        <>
            <div className={"version-header"}>
                <AdapterBreadcrumb
                    adapterRepo={repo}
                    adapterVersionTag={adapterVersion.tag}
                    archived={adapterVersion.archived}
                />
                <SubscriptionButton isExpired={userContext?.isExpired} currentTier={userTier} />
            </div>

            <Divider hidden />

            <div className="adapter-header-layout">
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "0.47rem" }}>
                        <AdapterStatusChip adapterPath={adapterVersion.adapterPath} />
                        <Header as="h2" style={{ margin: 0 }}>
                            {adapterName}
                        </Header>
                        <CopyButton text={adapterName} />
                    </div>
                    <div style={{ display: "flex", alignItems: "center", color: SEMANTIC_GREY }}>
                        <span style={{ marginRight: "1.71rem" }}>{adapterVersion.baseModel}</span>
                        <JobStatusSubheader adapterVersion={adapterVersion} />
                        <EpochSubheader
                            jobStatus={adapterVersion.status}
                            latestStreamingMetrics={latestStreamingMetrics}
                        />
                        {adapterVersion.baseVersionRepoName &&
                            adapterVersion.baseVersionTag &&
                            adapterVersion.baseVersionRepoUUID && (
                                <span style={{ marginRight: "1.71rem" }}>
                                    Continued training from{" "}
                                    <a
                                        href={urlJoin(
                                            getClientEndpoint(),
                                            `/adapters/repo/${adapterVersion.baseVersionRepoUUID}/version/${adapterVersion?.baseVersionTag}`,
                                        )}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {adapterVersion.baseVersionRepoName}/{adapterVersion.baseVersionTag}
                                    </a>
                                </span>
                            )}
                        {adapterVersion.finetuningJob && (
                            <span>Type: {formatAdapterType(adapterVersion.adapterType)}</span>
                        )}
                    </div>
                </div>
                <div style={{ display: "flex", marginLeft: "auto", maxHeight: `${36 / 14}rem` }}>
                    {adapterPathIsAvailable && (
                        <Popup
                            disabled={userIsInEnterpriseTier}
                            className={"transition-scale"}
                            position={"top left"}
                            trigger={
                                // To enable the popup when the button is disabled we have to wrap it with a
                                // div, see: https://github.com/Semantic-Org/Semantic-UI-React/issues/2804
                                <div>
                                    <Button
                                        icon="download"
                                        aria-label="Download Adapter"
                                        style={{ marginRight: `${8 / 14}rem`, minWidth: `${42 / 14}rem` }}
                                        disabled={
                                            !userIsInEnterpriseTier &&
                                            !isSolarAdapter &&
                                            new Date(adapterVersion.createdAt) > new Date("31 May 2024 00:00:00 UTC")
                                        }
                                        onClick={() => {
                                            // Purposely updated to match PostHog's suggested naming convention
                                            metrics.capture("adapter_exported");
                                            streamDownloadAdapterVersion(repoUUID, versionTag, auth0TokenOptions);
                                        }}
                                    />
                                </div>
                            }
                            content={
                                <>
                                    Contact us at <a href="mailto:support@predibase.com">support@predibase.com</a> to
                                    download your adapter in the Developer tier. Downloading adapters is available for
                                    Enterprise users by default.
                                </>
                            }
                        />
                    )}
                    {adapterPathIsAvailable && (
                        <Dropdown
                            button
                            text="Prompt"
                            style={{
                                marginRight: `${8 / 14}rem`,
                                whiteSpace: "nowrap",
                            }}
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() =>
                                        navigate(
                                            `/prompt?version=${adapterVersion?.tag}&name=${encodeURIComponent(
                                                repo.name,
                                            )}&model=${encodeURIComponent(adapterVersion?.baseModel ?? "")}`,
                                        )
                                    }
                                >
                                    Prompt via UI
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => setPromptInstructionsModalOpen(true)}>
                                    Prompt via REST API/SDK/CLI
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                    {userRole !== role.READONLY && (
                        <>
                            {!isLegacyAdapter && !isGRPOTask && (
                                <Dropdown
                                    text="New Adapter"
                                    button
                                    direction="left"
                                    style={{
                                        color: SEMANTIC_WHITE,
                                        backgroundColor: SEMANTIC_BLUE,
                                        whiteSpace: "nowrap",
                                    }}
                                    disabled={!adapterVersion.finetuningJob && !canBeContinued}
                                >
                                    <Dropdown.Menu>
                                        {!isImportedAdapter && !isTurboAdapter && (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    window.open(
                                                        `/adapters/create/${repo?.uuid}?adapterVersionID=${adapterVersion?.tag}`,
                                                        "_blank",
                                                    );
                                                }}
                                            >
                                                New adapter from #{adapterVersion.tag}
                                            </Dropdown.Item>
                                        )}
                                        {!isImportedAdapter && (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    // TODO: Grab the entire repo to get the latest tag.
                                                    setRetrainModalOpen(true);
                                                }}
                                            >
                                                Retrain
                                            </Dropdown.Item>
                                        )}
                                        {canBeContinued && (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    window.open(
                                                        `/adapters/create/${repo?.uuid}/continue?adapterVersionID=${adapterVersion?.tag}`,
                                                        "_blank",
                                                    );
                                                }}
                                            >
                                                (Beta) Continue Training Adapter
                                            </Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}

                            {adapterVersion.finetuningJob &&
                                !isTerminalJobStatus(adapterVersion.status) &&
                                !isLegacyAdapter && (
                                    <Button
                                        color={"black"}
                                        style={{ marginRight: 0 }}
                                        disabled={adapterVersion.status === finetuningJobStatus.STOPPING}
                                        onClick={() => setCancelTrainingJobModalOpen(true)}
                                    >
                                        <span style={{ display: "flex" }}>
                                            {adapterVersion.status === finetuningJobStatus.STOPPING && (
                                                <Loader active inline size="tiny" />
                                            )}
                                            &nbsp;Stop
                                        </span>
                                    </Button>
                                )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default AdapterHeader;
