import { Table, TableBody, TableCell, TableRow } from "semantic-ui-react";

import { adapterVersion } from "@/autogen/openapi";

import { SEMANTIC_GREY } from "../../../../utils/colors";

const ImportedAdapterConfigTable = (props: { adapterVersion: adapterVersion }) => {
    const { adapterVersion } = props;
    return (
        <Table basic={"very"} collapsing compact>
            <TableBody>
                <TableRow>
                    <TableCell style={{ borderTop: "none", color: SEMANTIC_GREY }}>Base model</TableCell>
                    <TableCell style={{ borderTop: "none" }}>{adapterVersion.baseModel}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ borderTop: "none", color: SEMANTIC_GREY }}>Rank</TableCell>
                    <TableCell style={{ borderTop: "none" }}>
                        {adapterVersion.importedAdapterProperties?.rank || "--"}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ borderTop: "none", color: SEMANTIC_GREY }}>Target modules</TableCell>
                    <TableCell style={{ borderTop: "none" }}>
                        {adapterVersion.importedAdapterProperties?.targetModules?.join(", ") || "--"}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default ImportedAdapterConfigTable;
