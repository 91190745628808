import { useEffect, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useMatch } from "react-router-dom";
import { Divider, Loader } from "semantic-ui-react";

import { finetuningJobStatus } from "@/autogen/openapi";

import { BadLink } from "../../../components/BadLink/BadLink";
import PromptInstructionsModal from "../../../deployments/views/modals/PromptInstructionsModal";
import updateFavicon from "../../../utils/favicon";
import { isTerminalJobStatus } from "../../misc/utils";
import {
    GET_FINETUNING_JOB_TIMELINE_QUERY_KEY,
    useAdapterRepoQuery,
    useAdapterVersionQuery,
    useDeploymentCompatibleWithAdapterVersion,
    useFinetuningJobTimelineQuery,
} from "../../query";
import CancelFinetuningJobModal from "../modals/CancelFinetuningJobModal";
import RetrainAdapterModal from "../modals/RetrainAdapterModal";
import AdapterHeader from "./AdapterHeader";
import AdapterSummary from "./AdapterSummary";
import AdapterTabPanel from "./AdapterTabPanel";
import { useLiveJobMetrics } from "./metrics/websocket";

import "./AdapterVersionView.css";

const AdapterVersionView = () => {
    // Local state:
    const [promptInstructionsModalOpen, setPromptInstructionsModalOpen] = useState(false);
    const [retrainModalOpen, setRetrainModalOpen] = useState<boolean>(false);
    const [cancelTrainingJobModalOpen, setCancelTrainingJobModalOpen] = useState<boolean>(false);

    // Meta state:
    const match = useMatch("/adapters/repo/:repoUUID/version/:tag");
    const repoUUID = match?.params.repoUUID;
    const versionTag = Number(match?.params.tag);

    // Query state:
    const queryClient = useQueryClient();

    const {
        data: adapterVersion,
        isFetching: adapterVersionIsFetching,
        error: getAdapterVersionQueryError,
    } = useAdapterVersionQuery(repoUUID!, versionTag, {
        enabled: repoUUID !== undefined && versionTag !== undefined && versionTag > 0,
        refetchInterval: (query) => {
            if (isTerminalJobStatus(query.state.data?.status)) {
                return false;
            }
            return 30 * 1000; // 30 seconds
        },
        refetchIntervalInBackground: true,
        refetchOnWindowFocus: false,
    });
    const jobStatus = adapterVersion?.status;
    const jobUUID = adapterVersion?.finetuningJob?.uuid;

    const {
        data: repo,
        isFetching: repoIsFetching,
        error: getReppQueryError,
    } = useAdapterRepoQuery(repoUUID ?? "", {
        enabled: repoUUID !== undefined,
        refetchOnWindowFocus: false,
    });

    const {
        data: finetuningJobTimeline,
        // TODO: Let's prefetch this data and actualy fetch it when needed in the component.
        // isFetching: finetuningJobTimelineIsFetching,
        // TODO: Let's show this as an error message instead of completely failing.
        // error: getFinetuningJobTimelineQueryError,
    } = useFinetuningJobTimelineQuery(adapterVersion?.finetuningJob?.uuid ?? "", {
        enabled: adapterVersion?.finetuningJob?.uuid !== undefined,
        refetchOnWindowFocus: false,
        refetchInterval: () => {
            if (isTerminalJobStatus(adapterVersion?.status)) {
                return false;
            }
            return 30 * 1000; // 30 seconds
        },
        refetchIntervalInBackground: true,
    });

    // Metrics-specific state:
    // ! We place the metrics data queries here because they are expensive and we don't want to reset them when a tab
    // component remounts. Sucks, but it is what it its.
    const [websocketOpen, setWebsocketOpen] = useState<boolean>(false); // extra state to manually close websocket ASAP
    const { data: websocketData } = useLiveJobMetrics(jobUUID ?? "", repoUUID ?? "", versionTag ?? 0, {
        enabled: !isTerminalJobStatus(jobStatus) && websocketOpen,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
    // TODO: There is a lot of cognitive load to managing the websocket state like this, so we might want to use a
    // reducer instead:
    useEffect(() => {
        if (!websocketOpen && jobStatus === finetuningJobStatus.TRAINING) {
            setWebsocketOpen(true);
        }
    }, [jobStatus, websocketOpen]);
    const latestStreamingMetrics = websocketData?.at(-1);

    const compatibleDeployment = useDeploymentCompatibleWithAdapterVersion(adapterVersion) ?? undefined;

    // Keep timeline in sync with the adapter version query
    useEffect(() => {
        if (isTerminalJobStatus(adapterVersion?.status)) {
            queryClient.invalidateQueries({
                queryKey: GET_FINETUNING_JOB_TIMELINE_QUERY_KEY(adapterVersion?.finetuningJob?.uuid ?? ""),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adapterVersion]);

    // Update the favicon based on the adapter version status
    useEffect(() => {
        switch (adapterVersion?.status) {
            case finetuningJobStatus.ERRORED:
            case finetuningJobStatus.CANCELED:
            case finetuningJobStatus.STOPPING:
                updateFavicon("failed");
                break;
            case finetuningJobStatus.COMPLETED:
                updateFavicon("success");
                break;
            case finetuningJobStatus.PENDING:
            case finetuningJobStatus.QUEUED:
            case finetuningJobStatus.TRAINING:
                updateFavicon("processing");
                break;
        }
    }, [adapterVersion]);

    if (getAdapterVersionQueryError || getReppQueryError) {
        return <BadLink authenticated={true} />;
    }

    // Derived adapter state:
    const dataIsMissing =
        (adapterVersionIsFetching && adapterVersion === undefined) || (repoIsFetching && repo === undefined);

    return (
        <>
            {dataIsMissing && (
                <div className="loading-overlay">
                    <Loader active />
                </div>
            )}
            {adapterVersion && repoUUID && repo && (
                <div style={{ padding: "20px" }}>
                    <AdapterHeader
                        repo={repo}
                        adapterVersion={adapterVersion}
                        repoUUID={repoUUID}
                        versionTag={versionTag}
                        setPromptInstructionsModalOpen={setPromptInstructionsModalOpen}
                        setRetrainModalOpen={setRetrainModalOpen}
                        setCancelTrainingJobModalOpen={setCancelTrainingJobModalOpen}
                        latestStreamingMetrics={latestStreamingMetrics}
                    />
                    <Divider hidden />
                    <AdapterSummary
                        repo={repo}
                        adapterVersion={adapterVersion}
                        finetuningJobTimeline={finetuningJobTimeline}
                    />
                    <Divider hidden />
                    <AdapterTabPanel
                        adapterVersion={adapterVersion}
                        repoUUID={repoUUID}
                        websocketOpen={websocketOpen}
                        setWebsocketOpen={setWebsocketOpen}
                        websocketData={websocketData}
                    />
                    <Divider hidden />
                    <PromptInstructionsModal
                        deployment={compatibleDeployment}
                        adapter={adapterVersion}
                        open={promptInstructionsModalOpen}
                        setOpen={setPromptInstructionsModalOpen}
                    />
                    <RetrainAdapterModal
                        adapterRepo={repo}
                        adapterVersion={adapterVersion}
                        open={retrainModalOpen}
                        setOpen={setRetrainModalOpen}
                    />
                    <CancelFinetuningJobModal
                        adapterVersion={adapterVersion}
                        open={cancelTrainingJobModalOpen}
                        setOpen={setCancelTrainingJobModalOpen}
                    />
                </div>
            )}
        </>
    );
};

export default AdapterVersionView;
