import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { useRecoilState } from "recoil";

import { integration, integrationToken } from "@/autogen/openapi";

import { useAuth0TokenOptions } from "../data";
import { USER_STATE } from "../state/global";
import { MetronomeDashboardType } from "../types/billing";
import { CurrentUser } from "../types/user";
import { getIntegrationToken, getMetronomeDashboard } from "./data";

// Constants:
export const DASHBOARDS_CONSTANT = "dashboards";
export const INTEGRATIONS_CONSTANT = "integrations";

// Defaults:
export const commonGetMetronomeDashboardQuerySettings = <T>(): Partial<UseQueryOptions<T>> => ({
    // Per Convo with Metronome, dashboard link is valid for 1 hour
    // https://predibase.slack.com/archives/C062V7BKB44/p1702318732279829
    staleTime: 1000 * 60 * 55, // 55 minutes
    gcTime: 1000 * 60 * 55, // 55 minutes
    refetchOnWindowFocus: false,
    refetchOnMount: false,
});

// Queries:
export const GET_METRONOME_DASHBOARD_QUERY_KEY = (type: MetronomeDashboardType) => [DASHBOARDS_CONSTANT, { type }];
export const useMetronomeDashboardQuery = (
    type: MetronomeDashboardType,
    options?: Partial<UseQueryOptions<string>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<string>({
        queryKey: GET_METRONOME_DASHBOARD_QUERY_KEY(type),
        queryFn: () => getMetronomeDashboard(type, auth0TokenOptions),
        ...options,
    });
};

export const USE_INTEGRATION_TOKEN_QUERY_KEY = (integration: integration) => [
    INTEGRATIONS_CONSTANT,
    `${integration}/token`,
];
export const useIntegrationTokenQuery = (
    integration: integration,
    options?: Partial<UseQueryOptions<integrationToken>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<integrationToken>({
        queryKey: USE_INTEGRATION_TOKEN_QUERY_KEY(integration),
        queryFn: () => getIntegrationToken(integration, auth0TokenOptions),
        retry: false,
        ...options,
    });
};

export const useUserRole = () => {
    const [userContext] = useRecoilState(USER_STATE);
    return (userContext as CurrentUser | undefined)?.role?.toUpperCase(); // uppercase to match case of generated role types
};
