import { CSSProperties } from "react";

import TruncatedText from "../components/TruncatedText";

export const cssTruncate: CSSProperties = {
    display: "inline-block",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
};

export const truncateString = (text: any, limit: number) => {
    if (text === null || text === undefined) {
        return null;
    }

    if (text.length > limit) {
        return text.substring(0, limit - 3) + "...";
    }
    return text;
};

// TODO: These all need to be components, not functions:
export const getOverflowItem = (text: any, current = false, width = 80, display?: string) => {
    if (text === null || text === undefined) {
        return null;
    }

    // Because current selected item is bolded in the dropdown options, we need to subtract width so that
    // it doesn't overflow, as this truncation library doesn't resize on JSX, only text.
    const widthStr = current ? width - 10 + "%" : width + "%";

    return (
        <div style={{ width: widthStr, height: "100%", whiteSpace: "nowrap", display: display }}>
            <TruncatedText text={text} />
        </div>
    );
};
