import React, { ReactNode, useContext, useEffect, useState } from "react";

import { setUser as setSentryUser, withSentryReactRouterV6Routing } from "@sentry/react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Dimmer, Loader } from "semantic-ui-react";

import { role, tenantStatus, tier } from "@/autogen/openapi";

import MainMenu from "./MainMenu";
import AdaptersViewRouter from "./adapters/AdaptersViewRouter";
import ForwardFreeTrial from "./auth/auth0/ForwardFreeTrial";
import InviteView from "./auth/auth0/InviteView";
import SigninView from "./auth/auth0/SigninView";
import WelcomeView from "./auth/auth0/WelcomeView";
import { welcomeUrl } from "./auth/auth0/utils";
import { useAuth0Auth, useInviteParams } from "./auth/hooks";
import VPCSetupView from "./auth/vpc/VPCSetupView";
import ErrorBoundary from "./components/ErrorBoundary";
import GlobalHeader from "./components/GlobalHeader/GlobalHeader";
import InternalBanner from "./components/InternalBanner";
import PosthogBanner from "./components/PosthogBanner";
import { EnvironmentStatus } from "./data";
import DataViewRouter from "./data/DataViewRouter";
import DeploymentsViewRouter from "./deployments/DeploymentsViewRouter";
import EnginesView from "./engines/EnginesView";
import HomePage from "./home/HomePage";
import { identify as juneIdentify, identifyGroup as juneIdentifyGroup, track as juneTrack } from "./metrics/june";
import metrics from "./metrics/metrics";
import PromptView from "./prompt/PromptView";
import { useEnvironmentsQuery, useUserContextQueryV2 } from "./query";
import SettingsView from "./settings/SettingsView";
import { useUserRole } from "./settings/query";
import { ENVIRONMENT_SHORTCODE_STATE, SESSION_STATE, USER_STATE } from "./state/global";
import { isLocal } from "./utils/environment";
import updateFavicon from "./utils/favicon";
import { FeatureFlagsContext } from "./utils/feature-flags";

const CreateGrid = (props: { headerName: string | null; component: JSX.Element; menuComponent: JSX.Element }) => {
    const [userCtx] = useRecoilState(USER_STATE);

    return (
        <div style={{ display: "flex" }}>
            <div style={{ width: "88px" }}>{props.menuComponent}</div>
            <div style={{ width: "100%", overflowX: "auto" }}>
                {props.headerName ? (
                    <GlobalHeader userContext={userCtx} headerName={props.headerName} component={props.component} />
                ) : (
                    props.component
                )}
            </div>
        </div>
    );
};

const StyledLoader = () => {
    const style = {
        position: "fixed",
        left: "0px",
        top: "0px",
        width: "100%",
        height: "100%",
        zIndex: 9999,
    } as React.CSSProperties;
    return (
        <div style={{ ...style }}>
            <Dimmer active inverted size="massive">
                <Loader inverted>Loading</Loader>
            </Dimmer>
        </div>
    );
};

export enum EnvironmentConfigurationStatus {
    NOT_CONFIGURED = "not_configured",
    PARTLY_CONFIGURED = "partly_configured",
    FULLY_CONFIGURED = "fully_configured",
}

export const getEnvironmentConfigurationStatus = (status?: EnvironmentStatus) => {
    if (!status) {
        return undefined;
    }

    switch (status) {
        case EnvironmentStatus.CREATED:
            return EnvironmentConfigurationStatus.NOT_CONFIGURED;
        case EnvironmentStatus.READY: // NOTE: As long as the environment status has reached READY once, they can use the app.
        case EnvironmentStatus.UPDATING:
        case EnvironmentStatus.ERRORED: // ! BUG: If the environment goes from CREATED to ERRORED before READY then we should still show the setup view
        case EnvironmentStatus.CANCELLED: // ? NOTE: If you cancel the terraform update this can happen - see Slack: https://predibase.slack.com/archives/C07GAJTDG2E/p1740696702132629?thread_ts=1740694277.800999&cid=C07GAJTDG2E
            return EnvironmentConfigurationStatus.FULLY_CONFIGURED;
        default:
            return EnvironmentConfigurationStatus.PARTLY_CONFIGURED;
    }
};

// Fetches the shortcode and status information for the root (i.e. last available) environment. At the moment, there is
// only environment per tenant, so in practice the only diference between this and the singular useEnvironmentQuery is
// that you don't need to pass the environment shortcode as a parameter. As a result, this is one of the first calls the
// app makes after a user logs in.
export const useSetupRootEnvironmentInfo = (loggedInToPredibase: boolean) => {
    // Local state:
    const [envShortCode, setEnvShortCode] = useState("");
    const [envConfigStatus, setEnvConfigStatus] = useState<EnvironmentConfigurationStatus | undefined>(undefined);

    // Recoil state:
    const [, setEnvironmentShortCodeState] = useRecoilState(ENVIRONMENT_SHORTCODE_STATE);

    // Environments state:
    const { data: environments } = useEnvironmentsQuery({
        enabled: loggedInToPredibase,
    });
    // For now, we only support one environment per tenant. Grab the last one.
    const rootEnvShortCode = environments?.[environments.length - 1].shortCode;
    const rootEnvStatus = environments?.[environments.length - 1].status;
    useEffect(() => {
        if (rootEnvShortCode) {
            const envConfigStatus = getEnvironmentConfigurationStatus(rootEnvStatus);

            // Set local (hook) state:
            setEnvShortCode(rootEnvShortCode);
            setEnvConfigStatus(envConfigStatus);

            // Set recoil state:
            setEnvironmentShortCodeState(rootEnvShortCode);
        }
    }, [rootEnvShortCode]);

    return {
        envShortCode,
        envConfigStatus,
    };
};

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

// Protects pages that should only be accessed by anonymous users
const AnonymousBoundary = (props: { children: ReactNode; hasAuthSession: boolean; loggedIn: boolean }) => {
    const { children, loggedIn, hasAuthSession } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const returnTo = urlSearchParams.get("return_to");

    // TODO: Grab any QSPs and redirect to the correct page
    // Redirect tracker:
    // ? NOTE: To protect the app from being shown (and to prevent unnecessary Auth0 popups), assume the user is not
    // allowed to access the page until proven otherwise:
    const [redirect, setRedirect] = useState(true);
    useEffect(() => {
        if (loggedIn) {
            navigate(returnTo ?? "/");
        } else if (hasAuthSession) {
            navigate(welcomeUrl);
        } else {
            setRedirect(false);
        }
    }, [loggedIn, hasAuthSession]);

    if (redirect) {
        return null;
    }

    return children;
};

// Protects pages that should only be accessed by users with a valid Auth0 session
// but no Predibase account yet
const AuthOnlyBoundary = (props: { children: ReactNode; hasAuthSession: boolean; loggedIn: boolean }) => {
    const { children, loggedIn, hasAuthSession } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const inviteTokenHook = useInviteParams();
    const inviteToken = inviteTokenHook.inviteToken;
    const urlSearchParams = new URLSearchParams(location.search);
    const returnTo = urlSearchParams.get("return_to");

    // Redirect tracker:
    // ? NOTE: To protect the app from being shown (and to prevent unnecessary Auth0 popups), assume the user is not
    // allowed to access the page until proven otherwise:
    const [redirect, setRedirect] = useState(true);
    useEffect(() => {
        if (!hasAuthSession) {
            navigate(`/auth/signin?return_to=${returnTo}${inviteToken ? `&inviteToken=${inviteToken}` : ""}`);
        }

        // When users are switching tenants, they will be fully logged in:
        // TODO: Should we show a specific error message here?
        else if (loggedIn && !inviteToken) {
            navigate(returnTo ?? "/");
        } else {
            setRedirect(false);
        }
    }, [hasAuthSession, loggedIn, returnTo, inviteToken]);

    if (redirect) {
        return null;
    }

    return children;
};

// Protects pages that should only be accessed by signed in users with a Predibase account
const SignedInBoundary = (props: { children: ReactNode; hasAuthSession: boolean; loggedIn: boolean }) => {
    // Parent props:
    const { children, hasAuthSession, loggedIn } = props;

    // Fetch root environment:
    const { envConfigStatus } = useSetupRootEnvironmentInfo(loggedIn);

    // Router state:
    const location = useLocation();
    const pathname = location.pathname;
    const navigate = useNavigate();

    // Invite token state:
    const inviteTokenHook = useInviteParams();
    const inviteToken = inviteTokenHook.inviteToken;

    // Redirect tracker:
    // ? NOTE: To protect the app from being shown (and to prevent unnecessary Auth0 popups), assume the user is not
    // allowed to access the page until proven otherwise:
    const [redirect, setRedirect] = useState(true);
    useEffect(() => {
        // Anonymous users need to sign into the app via our Auth Provider (Auth0)
        if (!hasAuthSession) {
            metrics.capture("signin_redirect", {
                source: "private_route",
            });
            navigate(`/auth/signin?return_to=${pathname}${inviteToken ? `&inviteToken=${inviteToken}` : ""}`);
        }
        // If a user has a valid auth session but is not logged in, they need to finish setting up their account
        else if (!loggedIn) {
            navigate(`${welcomeUrl}?return_to=${pathname}${inviteToken ? `&inviteToken=${inviteToken}` : ""}`);
        }
        // Auth0 is redirecting to the Home Page instead of the Auth Welcome Page.
        // Send users to the Auth Welcome page to complete the signup process:
        else if (pathname !== welcomeUrl && inviteToken) {
            navigate(`${welcomeUrl}${inviteToken ? `?inviteToken=${inviteToken}` : ""}`);
        }
        // Reaching this point means the user is trying to provision a VPC environment that is not ready, so forward them
        // to the VPC view router:
        else if (envConfigStatus !== undefined && envConfigStatus !== EnvironmentConfigurationStatus.FULLY_CONFIGURED) {
            navigate(`/vpc/setup`);
        } else {
            setRedirect(false);
        }
    }, [hasAuthSession, loggedIn, pathname, inviteToken, envConfigStatus]);

    if (redirect) {
        return null;
    }

    return children;
};

const Predibase = () => {
    const { posthogFeatureFlags } = useContext(FeatureFlagsContext);

    // PostHog returns undefined if the feature flags are not loaded yet.
    if (!Array.isArray(posthogFeatureFlags)) {
        return <StyledLoader />;
    }

    return <PredibaseAuthorizationLayer />;
};

const isPredibaseAuthLayerLoading = (
    authLoading: boolean,
    hasValidAuthSession: boolean,
    userContextLoading: boolean,
) => {
    // Auth service
    // Predibase User Context
    // Unknown state, auth service hasn't responded yet
    if (authLoading) {
        return true;
    }

    // Anonymous user (No Auth0 or Pbase account)
    // Auth service has responded, since user doesn't have a session they are anonymous
    if (!hasValidAuthSession) {
        return false;
    }

    // Auth only user (No Pbase account yet)
    // Waiting to see if user has a Pbase account
    if (userContextLoading) {
        return true;
    }

    // Auth service and Predibase user context have responded
    return false;
};

const PredibaseAuthorizationLayer = () => {
    // Global Auth state:
    const [session] = useRecoilState(SESSION_STATE);
    const [, setUserContext] = useRecoilState(USER_STATE);

    const { authLoading: authLoading, validSession: hasValidAuthSession } = useAuth0Auth();

    // TODO: Show an error message somewhere if this fails:
    const { data: fetchedUserContext, isLoading: userContextLoading } = useUserContextQueryV2({
        // TODO: Clean up session handling:
        enabled: hasValidAuthSession,
        // TODO: We may eventually want to refetch, see disc.:
        // https://github.com/predibase/predibase/pull/6906/files/3c3762b514e23a03885fd7d32fb86bf60988684f..560c84e02a5aae048edb8af3b2fe0d96268a6b5a#r1525653492
        // refetchOnWindowFocus: false,
        retry: false,
    });

    useEffect(() => {
        if (!session || !fetchedUserContext) {
            return;
        }

        const email = session?.email;
        const tenantTier = fetchedUserContext?.tenant.tier;
        const shortCode = fetchedUserContext.tenant.shortcode;
        const user = {
            ...fetchedUserContext,
            isSystemUser:
                fetchedUserContext.role.toLowerCase() === role.ADMIN ||
                fetchedUserContext.role.toLowerCase() === role.SUPPORT,
            isExpired: fetchedUserContext.tenant.status === tenantStatus.SUSPENDED,
            isComputeLimited: tenantTier === tier.FREE,
            isFeatureLimited: false, // This is here in case we want to limit certain features in the future
        };

        setSentryUser({
            id: String(user.uuid),
            username: user.username,
            tenantID: String(user.tenant.uuid),
            tenantName: user.tenant?.name,
            isSystemUser: user.isSystemUser,
        });

        // June metrics
        juneIdentify(user, email).then(() => {
            juneIdentifyGroup(user).then(() => {
                if (user.isExpired) {
                    juneTrack(user, "expired_trial_login", {
                        username: user.username,
                        userID: user.uuid,
                        subscription: tenantTier,
                    });
                }
            });
        });

        // PostHog metrics
        metrics.identify(user.uuid, email ?? "", user.username, tenantTier, shortCode);
        metrics.group("company", user.tenant.uuid, {
            name: user.tenant.name,
            shortCode: shortCode,
            subscription: tenantTier,
            status: user.tenant.status,
        });
        if (user.isExpired) {
            metrics.capture("expired_trial_login", {
                username: user.username,
                userID: user.uuid,
                subscription: tenantTier,
            });
        }

        setUserContext(user);
    }, [session, fetchedUserContext]);

    const isLoggedIn = Boolean(hasValidAuthSession && fetchedUserContext);
    const isAuthLayerloading = isPredibaseAuthLayerLoading(authLoading, hasValidAuthSession, userContextLoading);

    if (isAuthLayerloading) {
        return <StyledLoader />;
    }

    return <PredibasePage hasValidAuthSession={hasValidAuthSession} loggedInToPredibase={isLoggedIn} />;
};

const PredibasePage = (props: { hasValidAuthSession: boolean; loggedInToPredibase: boolean }) => {
    const { loggedInToPredibase, hasValidAuthSession } = props;

    // Global Auth state:
    const userRole = useUserRole();
    const isViewerSupport = userRole?.toUpperCase() === role.SUPPORT;

    // Meta state:
    const location = useLocation();

    // Update the favicon every time the route changes
    // so that the user doesn't see a stale status in the
    // browser window.
    useEffect(() => {
        updateFavicon();
    }, [location]);

    return (
        <>
            <PosthogBanner />
            <InternalBanner />
            <SentryRoutes>
                {/* Unguarded routes: */}
                <Route
                    path="/auth/signin"
                    element={
                        <AnonymousBoundary hasAuthSession={hasValidAuthSession} loggedIn={loggedInToPredibase}>
                            <SigninView />
                        </AnonymousBoundary>
                    }
                />
                <Route
                    path="/auth/forward_free_trial"
                    element={
                        <AnonymousBoundary hasAuthSession={hasValidAuthSession} loggedIn={loggedInToPredibase}>
                            <ForwardFreeTrial />
                        </AnonymousBoundary>
                    }
                />
                <Route
                    path={welcomeUrl}
                    element={
                        <AuthOnlyBoundary hasAuthSession={hasValidAuthSession} loggedIn={loggedInToPredibase}>
                            <WelcomeView />
                        </AuthOnlyBoundary>
                    }
                />
                <Route
                    path="/invite"
                    element={
                        <AnonymousBoundary hasAuthSession={hasValidAuthSession} loggedIn={loggedInToPredibase}>
                            <InviteView />
                        </AnonymousBoundary>
                    }
                />

                {/* Top level redirects: */}
                {/* TODO: remove at some point? */}
                <Route path="/queries" element={<Navigate to="/prompt" replace />} />

                {/* Guarded routes: */}

                {/* TODO: This check should really be part of the auth boundary, right? */}
                {(isViewerSupport || isLocal()) && (
                    <Route
                        path="/engines"
                        element={
                            <SignedInBoundary hasAuthSession={hasValidAuthSession} loggedIn={loggedInToPredibase}>
                                <CreateGrid
                                    headerName={"Engines"}
                                    component={
                                        <ErrorBoundary>
                                            <EnginesView />
                                        </ErrorBoundary>
                                    }
                                    menuComponent={<MainMenu activeItem="engines" />}
                                />
                            </SignedInBoundary>
                        }
                    />
                )}

                <Route
                    path="/vpc/setup"
                    element={
                        <SignedInBoundary hasAuthSession={hasValidAuthSession} loggedIn={loggedInToPredibase}>
                            <VPCSetupView />
                        </SignedInBoundary>
                    }
                />

                <Route
                    path="/data/*"
                    element={
                        <SignedInBoundary hasAuthSession={hasValidAuthSession} loggedIn={loggedInToPredibase}>
                            <CreateGrid
                                headerName={"Data"}
                                component={
                                    <ErrorBoundary>
                                        <DataViewRouter />
                                    </ErrorBoundary>
                                }
                                menuComponent={<MainMenu activeItem="data" />}
                            />
                        </SignedInBoundary>
                    }
                />
                <Route
                    path="/adapters/*"
                    element={
                        <SignedInBoundary hasAuthSession={hasValidAuthSession} loggedIn={loggedInToPredibase}>
                            <CreateGrid
                                headerName={location.pathname === "/adapters" ? "Adapters" : null}
                                component={
                                    <ErrorBoundary>
                                        <AdaptersViewRouter />
                                    </ErrorBoundary>
                                }
                                menuComponent={<MainMenu activeItem="adapters" />}
                            />
                        </SignedInBoundary>
                    }
                />
                <Route
                    path="/prompt"
                    element={
                        <SignedInBoundary hasAuthSession={hasValidAuthSession} loggedIn={loggedInToPredibase}>
                            <CreateGrid
                                headerName={"Prompt"}
                                component={
                                    <ErrorBoundary>
                                        <PromptView />
                                    </ErrorBoundary>
                                }
                                menuComponent={<MainMenu activeItem="prompt" />}
                            />
                        </SignedInBoundary>
                    }
                />
                <Route
                    path="/deployments/*"
                    element={
                        <SignedInBoundary hasAuthSession={hasValidAuthSession} loggedIn={loggedInToPredibase}>
                            <CreateGrid
                                headerName={location.pathname === "/deployments" ? "Deployments" : null}
                                component={
                                    <ErrorBoundary>
                                        <DeploymentsViewRouter />
                                    </ErrorBoundary>
                                }
                                menuComponent={<MainMenu activeItem="deployments" />}
                            />
                        </SignedInBoundary>
                    }
                />
                <Route
                    path="/settings"
                    element={
                        <SignedInBoundary hasAuthSession={hasValidAuthSession} loggedIn={loggedInToPredibase}>
                            <CreateGrid
                                headerName={"Settings"}
                                component={
                                    <ErrorBoundary>
                                        <SettingsView />
                                    </ErrorBoundary>
                                }
                                menuComponent={<MainMenu activeItem="settings" />}
                            />
                        </SignedInBoundary>
                    }
                />
                <Route
                    path="/"
                    element={
                        <SignedInBoundary hasAuthSession={hasValidAuthSession} loggedIn={loggedInToPredibase}>
                            <CreateGrid
                                headerName={"Home"}
                                component={
                                    <ErrorBoundary>
                                        <HomePage />
                                    </ErrorBoundary>
                                }
                                menuComponent={<MainMenu activeItem="home" />}
                            />
                        </SignedInBoundary>
                    }
                />
            </SentryRoutes>
        </>
    );
};

export default Predibase;
