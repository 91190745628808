import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import {
    adapterVersion,
    finetuningJob,
    finetuningJobTimeline,
    listReposResponse,
    promptCompletions,
    promptRewardsResponse,
    promptsResponse,
    repo,
    rewardFunctionsConfig,
} from "@/autogen/openapi";

import { useAuth0TokenOptions } from "../data";
import { PodLogsWithTimestamp } from "../deployments/views/deployment/tabs/GrafanaLogs";
import {
    generateDeploymentSelectorOptions,
    generateDeploymentToAdapterRepoMap,
    sortDeployments,
} from "../prompt/utils/dropdown-utils";
import { matchQueryString } from "../prompt/utils/model-matching";
import { ADAPTERS_CONSTANT, useDeploymentsQuery } from "../query";
import {
    getAdapterRepo,
    getAdapterRepos,
    getAdapterVersion,
    getFinetuningJob,
    getFinetuningJobTimeline,
    getGRPOFunctions,
    getGRPOPodLogsWithTimestamp,
    getGRPOPromptCompletions,
    getGRPOPromptRewards,
    getGRPOPrompts,
    listAdapterReposParams,
} from "./data";

// Constants:
export const REPOS_CONSTANT = "repos";
export const JOBS_CONSTANT = "jobs";
export const FINETUNING_CONSTANT = "finetuning";

// Queries:
export const GET_ADAPTER_REPOS_QUERY_KEY = (paginationParams?: listAdapterReposParams) =>
    paginationParams ? [ADAPTERS_CONSTANT, REPOS_CONSTANT, paginationParams] : [ADAPTERS_CONSTANT, REPOS_CONSTANT];
export const useAdapterReposQuery = (
    params?: listAdapterReposParams,
    options?: Partial<UseQueryOptions<listReposResponse>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<listReposResponse>({
        queryKey: GET_ADAPTER_REPOS_QUERY_KEY(params), // TODO: Does every value need to be a string?
        queryFn: () => getAdapterRepos(params, auth0TokenOptions),
        ...options,
    });
};

export const GET_ADAPTER_REPO_QUERY_KEY = (uuid: string) => [ADAPTERS_CONSTANT, REPOS_CONSTANT, uuid];
export const useAdapterRepoQuery = (uuid: string, options?: Partial<UseQueryOptions<repo>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<repo>({
        queryKey: GET_ADAPTER_REPO_QUERY_KEY(uuid),
        queryFn: () => getAdapterRepo(uuid, auth0TokenOptions),
        ...options,
    });
};

export const GET_ADAPTER_VERSION_QUERY_KEY = (repoUUID: string, versionTag: number) => [
    ADAPTERS_CONSTANT,
    REPOS_CONSTANT,
    repoUUID,
    "versions",
    versionTag,
];
export const useAdapterVersionQuery = (
    repoUUID: string,
    versionTag: number,
    options?: Partial<UseQueryOptions<adapterVersion>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<adapterVersion>({
        queryKey: GET_ADAPTER_VERSION_QUERY_KEY(repoUUID, versionTag),
        queryFn: () => getAdapterVersion(repoUUID, versionTag, auth0TokenOptions),
        ...options,
    });
};

export const GET_FINETUNING_JOB_TIMELINE_QUERY_KEY = (uuid: string) => [
    JOBS_CONSTANT,
    FINETUNING_CONSTANT,
    uuid,
    "timeline",
];
export const useFinetuningJobTimelineQuery = (
    fineTuningJobUUID: string,
    options?: Partial<UseQueryOptions<finetuningJobTimeline>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<finetuningJobTimeline>({
        queryKey: GET_FINETUNING_JOB_TIMELINE_QUERY_KEY(fineTuningJobUUID),
        queryFn: () => getFinetuningJobTimeline(fineTuningJobUUID, auth0TokenOptions),
        ...options,
    });
};

export const GET_FINETUNING_JOB_QUERY_KEY = (uuid: string) => [JOBS_CONSTANT, FINETUNING_CONSTANT, uuid];
export const useFinetuningJobQuery = (uuid: string, options?: Partial<UseQueryOptions<finetuningJob>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<finetuningJob>({
        queryKey: GET_FINETUNING_JOB_QUERY_KEY(uuid),
        queryFn: () => getFinetuningJob(uuid, auth0TokenOptions),
        ...options,
    });
};

export const GET_GRPO_POD_LOGS_QUERY_KEY = (uuid: string) => [JOBS_CONSTANT, FINETUNING_CONSTANT, uuid, "grpo_logs"];
export const useGRPOPodLogsWithTimestampQuery = (
    uuid: string,
    options?: Partial<UseQueryOptions<PodLogsWithTimestamp>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<PodLogsWithTimestamp>({
        queryKey: GET_GRPO_POD_LOGS_QUERY_KEY(uuid),
        queryFn: () => getGRPOPodLogsWithTimestamp(uuid, auth0TokenOptions),
        ...options,
    });
};

// TODO: How should we include the query string parameters in the query key?
export const GET_GRPO_PROMPTS_QUERY_KEY = (uuid: string, offset: number, total: number) => [
    JOBS_CONSTANT,
    FINETUNING_CONSTANT,
    uuid,
    "prompts",
    { offset, total },
];
export const useGRPOPrompts = (
    uuid: string,
    offset: number,
    total: number,
    options?: Partial<UseQueryOptions<promptsResponse>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<promptsResponse>({
        queryKey: GET_GRPO_PROMPTS_QUERY_KEY(uuid, offset, total),
        queryFn: () => getGRPOPrompts(uuid, offset, total, auth0TokenOptions),
        ...options,
    });
};

export const GET_GRPO_PROMPT_REWARDS_QUERY_KEY = (uuid: string) => [
    JOBS_CONSTANT,
    FINETUNING_CONSTANT,
    uuid,
    "prompt_rewards",
];
export const useGRPOPromptRewards = (uuid: string, options?: Partial<UseQueryOptions<promptRewardsResponse>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<promptRewardsResponse>({
        queryKey: GET_GRPO_PROMPT_REWARDS_QUERY_KEY(uuid),
        queryFn: () => getGRPOPromptRewards(uuid, auth0TokenOptions),
        ...options,
    });
};

export const GET_GRPO_ALL_PROMPT_COMPLETIONS_QUERY_KEY = (uuid: string) => [
    JOBS_CONSTANT,
    FINETUNING_CONSTANT,
    uuid,
    "prompt_completions",
];
export const GET_GRPO_PROMPT_COMPLETIONS_QUERY_KEY = (uuid: string, id: string) => [
    ...GET_GRPO_ALL_PROMPT_COMPLETIONS_QUERY_KEY(uuid),
    id,
];
export const useGRPOPromptCompletions = (
    uuid: string,
    id: string,
    options?: Partial<UseQueryOptions<promptCompletions>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<promptCompletions>({
        queryKey: GET_GRPO_PROMPT_COMPLETIONS_QUERY_KEY(uuid, id),
        queryFn: () => getGRPOPromptCompletions(uuid, id, auth0TokenOptions),
        ...options,
    });
};

export const GET_GRPO_PROMPT_FUNCTIONS_QUERY_KEY = (uuid: string) => [
    JOBS_CONSTANT,
    FINETUNING_CONSTANT,
    uuid,
    "prompt_functions",
];
export const useGRPOPromptFunctions = (uuid: string, options?: Partial<UseQueryOptions<rewardFunctionsConfig>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<rewardFunctionsConfig>({
        queryKey: GET_GRPO_PROMPT_FUNCTIONS_QUERY_KEY(uuid),
        queryFn: () => getGRPOFunctions(uuid, auth0TokenOptions),
        ...options,
    });
};

// Custom hooks:
export const useDeploymentCompatibleWithAdapterVersion = (adapterVersion: adapterVersion | undefined) => {
    // Get all current deployments:
    const { data: deployments } = useDeploymentsQuery({
        refetchOnWindowFocus: false,
        refetchInterval: false,
    });
    // Get a sorted version of the deployments:
    const sortedDeployments = sortDeployments(deployments);

    if (adapterVersion === undefined) {
        return null;
    }

    const [, deploymentUUIDLookup] = generateDeploymentSelectorOptions(sortedDeployments);
    const deploymentUUIDToAdapterReposMap = generateDeploymentToAdapterRepoMap(sortedDeployments, [
        {
            archived: adapterVersion.archived,
            baseModel: adapterVersion.baseModel,
            repo: adapterVersion.repo,
            versionTag: adapterVersion.tag,
        },
    ]);

    // Otherwise, try to match the query string:
    const match = matchQueryString(
        {
            versionTag: adapterVersion.tag,
            adapterRepoName: adapterVersion.repo,
            modelIdentifier: adapterVersion.baseModel,
        },
        deploymentUUIDToAdapterReposMap,
        deploymentUUIDLookup,
    );
    const [deploymentMatch] = match;

    return deploymentMatch;
};
