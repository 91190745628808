import { useRecoilState } from "recoil";

import { USER_STATE } from "../state/global";

const InternalBanner = () => {
    const [userCtx] = useRecoilState(USER_STATE);
    const shortCode = userCtx?.tenant.shortcode;

    return (
        <>
            {userCtx && userCtx.username === `support+${shortCode}` && (
                <div
                    style={{
                        backgroundColor: "#E25636",
                        color: "#ffffff",
                        textAlign: "center",
                        padding: "10px",
                    }}
                >
                    <p
                        style={{
                            fontSize: 12,
                            fontWeight: "bold",
                        }}
                    >
                        ⚠️ YOU ARE IN A SUPPORT ACCOUNT ⚠️
                    </p>
                </div>
            )}
        </>
    );
};

export default InternalBanner;
