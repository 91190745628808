import React, { useEffect, useMemo, useRef, useState } from "react";

import { keepPreviousData, useQueryClient } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import { Icon, Loader, Message, Pagination } from "semantic-ui-react";
import { PaginationProps } from "semantic-ui-react/dist/commonjs/addons/Pagination/Pagination";

import ScopeUserToggle from "../../components/ScopeUserToggle";
import SimpleSearch from "../../components/SimpleSearch";
import {
    CONNECTIONS_OFFSET_STATE,
    CONNECTIONS_SCOPE_USER_STATE,
    CONNECTIONS_SEARCH_VAL_STATE,
    CONNECTIONS_TOTAL_STATE,
} from "../../state/data";
import { generateParamsNoRef, getActivePage, getTotalPages } from "../../utils/pagination";
import { GET_CONNECTIONS_QUERY_KEY, useConnectionsQuery } from "../query";
import ConnectionsTable from "./ConnectionsTable";

const CONNECTIONS_PER_PAGE = 10;

const ListConnectionsView = (props: {
    selectedConnectionID?: number;
    handleConnectionChange?: (connectionID: number) => void;
}) => {
    const [connectionOffset, setConnectionOffset] = useRecoilState(CONNECTIONS_OFFSET_STATE);
    const [connectionTotal, setConnectionTotal] = useRecoilState(CONNECTIONS_TOTAL_STATE);
    const [scopeUser, setScopeUser] = useRecoilState(CONNECTIONS_SCOPE_USER_STATE);
    const [connectionSearchVal] = useRecoilState(CONNECTIONS_SEARCH_VAL_STATE);
    // SimpleSearch mutates the searchVal since using recoil state alone isn't sufficient
    const searchValRef = useRef(connectionSearchVal);

    // TODO: I hate this, but it's the simplest fix. The ref and timer usage nonsense here is so stupid.
    const [debouncedSearchVal, setDebouncedSearchValRef] = useState<string>(searchValRef.current);
    const params = useMemo(() => {
        return generateParamsNoRef(connectionOffset, CONNECTIONS_PER_PAGE, scopeUser, ["name"], debouncedSearchVal);
    }, [connectionOffset, debouncedSearchVal, scopeUser]);

    // Query (pagination-based) state:
    const queryClient = useQueryClient();
    const { status, data, error } = useConnectionsQuery(params, {
        staleTime: 5 * 1000, // 5 seconds
        refetchInterval: 5 * 1000, // 5 seconds
        placeholderData: keepPreviousData,
    });
    useEffect(() => {
        if (data) {
            setConnectionOffset(data.offset);
            setConnectionTotal(data.total);
        }
    }, [data, setConnectionOffset, setConnectionTotal]);

    const getActiveConnectionsPage = () => getActivePage(connectionOffset, CONNECTIONS_PER_PAGE);
    const getTotalConnectionsPages = () => getTotalPages(connectionTotal, CONNECTIONS_PER_PAGE);
    const handleConnectionsPageChange = (e: React.MouseEvent<HTMLAnchorElement>, { activePage }: PaginationProps) => {
        const offset = (Number(activePage) - 1) * CONNECTIONS_PER_PAGE;
        setConnectionOffset(offset);
        queryClient.invalidateQueries({ queryKey: GET_CONNECTIONS_QUERY_KEY(params) });
    };

    if (status === "pending") {
        return <Loader />;
    }

    if (status === "error") {
        return <Message error>{error.toString()}</Message>;
    }

    if (typeof data === "undefined" || !Array.isArray(data.connections)) {
        return <Message error>Unable to load connections.</Message>;
    }

    const { connections } = data;

    return (
        <>
            <ConnectionsTable
                connections={connections}
                searchbar={
                    <SimpleSearch
                        searchValRef={searchValRef}
                        searchValRecoilState={CONNECTIONS_SEARCH_VAL_STATE}
                        setOffset={setConnectionOffset}
                        placeholder={"Search..."}
                        debouncedFunction={(debouncedParams: PaginationParams) => {
                            setDebouncedSearchValRef(debouncedParams.searchVals[0]);
                        }}
                    />
                }
                selectedConnectionID={props.selectedConnectionID}
                handleConnectionChange={props.handleConnectionChange}
                scopeUserToggle={<ScopeUserToggle scopeUser={scopeUser} setScopeUser={setScopeUser} />}
            />
            <div style={{ justifyContent: "center", display: "flex" }}>
                <Pagination
                    activePage={getActiveConnectionsPage()}
                    totalPages={getTotalConnectionsPages()}
                    onPageChange={handleConnectionsPageChange}
                    firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                    lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                    prevItem={{ content: <Icon name="angle left" />, icon: true }}
                    nextItem={{ content: <Icon name="angle right" />, icon: true }}
                />
            </div>
        </>
    );
};

export default ListConnectionsView;
