import { useRecoilState } from "recoil";
import { Button, Icon } from "semantic-ui-react";

import { tier } from "@/autogen/openapi";

import { useUserCreditsQuery } from "../../query";
import { CONTACT_MODAL_STATE } from "../../state/subscription";

import "./SubscriptionButtons.css";

// Poll for credit updates every second
const creditsPollIntervalMs = 300000; // 5 minutes

export const SubscriptionButton = (props: { isExpired?: boolean; currentTier?: tier }) => {
    const { isExpired, currentTier, ...rest } = props;
    const [, setContactModal] = useRecoilState(CONTACT_MODAL_STATE);

    const { data, isSuccess } = useUserCreditsQuery({
        refetchInterval: creditsPollIntervalMs,
        refetchOnWindowFocus: false,
        retry: false,
    });

    if (!currentTier || currentTier !== tier.FREE) {
        // Only display the subscription header if we know for sure the user is in FREE tier.
        return null;
    }

    let creditsText = "";
    if (isSuccess) {
        // Remove fractional cents
        const balanceCents = Math.floor(data.balance);
        const dollars = Math.floor(balanceCents / 100);
        const cents = String(balanceCents % 100).padStart(2, "0");
        creditsText = `You have \$${dollars}.${cents} in credits remaining. `;
    }

    return (
        <Button {...rest} className="subscription-button" onClick={() => setContactModal(true)}>
            {isExpired ? (
                <span className="expired">
                    <Icon name="warning sign" className="warning-icon" />
                    You have run out of credits. <span style={{ textDecoration: "underline" }}>
                        Add a credit card
                    </span>{" "}
                    in order to continue using Predibase.
                </span>
            ) : (
                <span className="credits-available">
                    {creditsText}
                    <span style={{ textDecoration: "underline" }}>Add a credit card</span> to upgrade to the Developer
                    Tier.
                </span>
            )}
        </Button>
    );
};
