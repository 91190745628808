import commaNumber from "comma-number";
import prettyBytes from "pretty-bytes";
import { Link } from "react-router-dom";
import { Icon, Table } from "semantic-ui-react";

import EditDiv from "../../components/EditDiv";
import LiveTimeAgo from "../../components/LiveTimeAgo";
import metrics from "../../metrics/metrics";
import { deletedUser } from "../../utils/constants";
import { capitalize } from "../../utils/strings";
import { getDataStatusColor } from "../utils";

function DatasetDetailsTable(props: { dataset?: Dataset; refreshFunc?: () => void }) {
    if (!props.dataset) {
        return null;
    }

    const datasetSize = props.dataset?.datasetInfo?.info.sizeBytes;

    return (
        <div style={{ width: "50%" }}>
            <Table compact>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            <b>ID</b>
                        </Table.Cell>
                        <Table.Cell>{props.dataset?.id}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <b>Name</b>
                        </Table.Cell>
                        <EditDiv
                            ogText={props.dataset.name}
                            placeholder={"(No dataset name)"}
                            textToRequest={(name: string) => ({ id: props.dataset!.id, name: name })}
                            endpoint={"/datasets/" + props.dataset.id}
                            fitted
                            callback={props.refreshFunc}
                            asTableCell
                        />
                        {/*<EditDiv ogText={model.description}*/}
                        {/*         ogLabel={DescriptionLabel(model)}*/}
                        {/*         placeholder={"(No description)"}*/}
                        {/*         endpoint={"models/version/" + model.id}*/}
                        {/*         fitted*/}
                        {/*         textToRequest={(desc: string) => {*/}
                        {/*             return {id: model.id, description: desc, starred: model.starred}*/}
                        {/*         }}*/}
                        {/*         callback={props.refreshFunc}*/}
                        {/*         asTableCell={true}*/}
                        {/*/>*/}
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <b>Connection</b>
                        </Table.Cell>
                        <Table.Cell>
                            <Link
                                className={metrics.BLOCK_AUTO_CAPTURE}
                                onClick={() => {
                                    metrics.capture("Data.DatasetDetails.click", {
                                        datasetID: props.dataset?.id,
                                        datasetName: props.dataset?.name,
                                    });
                                }}
                                to={`/data/connections/${props.dataset?.connectionID}`}
                            >
                                {props.dataset?.connection.name}
                            </Link>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <b>Type</b>
                        </Table.Cell>
                        <Table.Cell>{props.dataset?.connection?.type}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <b>Source</b>
                        </Table.Cell>
                        <Table.Cell>
                            {props.dataset?.connection?.type !== "file" ? props.dataset?.objectName : null}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <b>Author</b>
                        </Table.Cell>
                        <Table.Cell>{props.dataset?.user?.username || deletedUser}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <b>Status</b>
                        </Table.Cell>
                        <Table.Cell>
                            <Icon name="circle" color={getDataStatusColor(props.dataset?.status as string)} />{" "}
                            {capitalize(props.dataset?.status)}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <b>Created</b>
                        </Table.Cell>
                        <Table.Cell>
                            <LiveTimeAgo fromDate={new Date(props.dataset.created)} />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <b>Updated</b>
                        </Table.Cell>
                        <Table.Cell>
                            <LiveTimeAgo fromDate={new Date(props.dataset.updated)} />
                        </Table.Cell>
                    </Table.Row>
                    {props.dataset.datasetInfo?.info ? (
                        <>
                            <Table.Row>
                                <Table.Cell>
                                    <b>Num rows</b>
                                </Table.Cell>
                                <Table.Cell>{commaNumber(props.dataset.datasetInfo.info.rowCount)}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <b>Size bytes</b>
                                </Table.Cell>
                                <Table.Cell>
                                    {datasetSize !== undefined ? prettyBytes(datasetSize) : "Error"}
                                </Table.Cell>
                            </Table.Row>
                        </>
                    ) : null}
                </Table.Body>
            </Table>
        </div>
    );
}

export default DatasetDetailsTable;
