import { useEffect, useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { Link, useMatch, useNavigate } from "react-router-dom";
import { Breadcrumb, Button, Divider, Header, Image, Message, Modal } from "semantic-ui-react";

import EditHeader from "../../components/EditHeader";
import { useAuth0TokenOptions } from "../../data";
import metrics from "../../metrics/metrics";
import { getImageForConnector } from "../../utils/connectors";
import { getErrorMessage } from "../../utils/errors";
import { deleteConnection } from "../data";
import DatasetsTable from "../datasets/DatasetsTable";
import { useConnectionQuery } from "../query";
import { connectionDisplayTitle } from "../utils";
import ConnectionDetailsTable from "./ConnectionDetailsTable";
import ImportDataModal from "./ImportDataModal";

const ConnectionDetailsView = () => {
    const navigate = useNavigate();
    const match = useMatch("/data/connections/:id");
    // TODO: Unsafe bangs:
    const connectionID = parseInt(match!.params.id!);

    const auth0TokenOptions = useAuth0TokenOptions();

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [connection, setConnection] = useState<Connection>();

    const [deleted, setDeleted] = useState(false);

    const {
        data: connectionResponse,
        error: connectionError,
        refetch: refetchConnection,
    } = useConnectionQuery(connectionID);
    useEffect(() => {
        if (connectionResponse) {
            setConnection(connectionResponse);
            setErrorMessage(null);
        }
    }, [connectionResponse]);
    useEffect(() => {
        if (connectionError) {
            setErrorMessage(getErrorMessage(connectionError));
        }
    }, [connectionError]);

    const { mutate: mutateDeleteConnection } = useMutation({
        mutationFn: () => deleteConnection(connection!.id, auth0TokenOptions),
        onSuccess: () => {
            setDeleted(true);
            setErrorMessage(null);
        },
        onError: (error) => {
            setErrorMessage(getErrorMessage(error));
        },
    });

    useEffect(() => {
        if (deleted) {
            navigate("/data");
        }
    }, [deleted]);

    let deleteButton = null;
    if (connection) {
        deleteButton = (
            <Modal
                trigger={
                    <Button
                        className={metrics.BLOCK_AUTO_CAPTURE}
                        content="Delete"
                        labelPosition="right"
                        icon="trash"
                        negative
                    />
                }
                header={"Delete " + connection?.id}
                content="Are you sure?"
                size="mini"
                actions={[
                    { key: "cancel", content: "Cancel" },
                    { key: "delete", content: "Delete", negative: true, onClick: mutateDeleteConnection },
                ]}
            />
        );
    }

    const breadcrumb = (
        <Breadcrumb>
            <Breadcrumb.Section>
                <Link to="/data">Data</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section active>Connection Details</Breadcrumb.Section>
        </Breadcrumb>
    );

    if (!connection) {
        if (errorMessage) {
            return (
                <div style={{ padding: "20px" }}>
                    <Message negative>
                        <Message.Header>Error retrieving connection</Message.Header>
                        <p>{errorMessage}</p>
                    </Message>
                </div>
            );
        }
        return null;
    }

    return (
        <div style={{ padding: "20px" }}>
            {breadcrumb}
            <Divider hidden />
            <div className="row">
                {connection?.restricted === false ? (
                    <EditHeader
                        ogText={connectionDisplayTitle(connection.name)}
                        ogLabel={
                            <span style={{ verticalAlign: "middle" }}>{connectionDisplayTitle(connection.name)}</span>
                        }
                        inputStyle={{ minWidth: "400px" }}
                        img={<Image src={getImageForConnector(connection.type!)} size="tiny" inline />}
                        placeholder={"(No connection name)"}
                        textToRequest={(name: string) => ({ id: connection.id, name: name })}
                        endpoint={"/connections/" + connection.id}
                        callback={refetchConnection}
                    />
                ) : (
                    <Header as="h2" className={"header"} style={{ display: "inline-block" }}>
                        {connection.name}
                    </Header>
                )}
            </div>
            <Divider hidden />
            {connection?.type !== "file" && connection?.type !== "public_datasets" && (
                <ImportDataModal connection={connection!} />
            )}
            {connection?.type && connection.type !== "file" && connection.type !== "public_datasets" && deleteButton}
            <Divider hidden />
            <ConnectionDetailsTable connection={connection} />
            <Divider hidden />
            <Header>Datasets For Connection</Header>
            {connection ? (
                <DatasetsTable indexKeys={"connectionID"} indexVals={connection?.id} ignoreRecoilSearch={true} />
            ) : null}
            <Divider hidden />
            {errorMessage ? (
                <Message negative>
                    <Message.Header>Error in model upload</Message.Header>
                    <p>{errorMessage}</p>
                </Message>
            ) : null}
        </div>
    );
};

export default ConnectionDetailsView;
