import { MouseEventHandler, useState } from "react";

import { useRecoilState } from "recoil";
import { Grid, Header, Icon, Input, Loader, Message, SemanticICONS } from "semantic-ui-react";

import { role } from "@/autogen/openapi";

import { useAPIToken } from "../api/useToken";
import CodeBlock from "../components/CodeBlock";
import CopyButton from "../components/CopyButton";
import { track } from "../metrics/june";
import metrics from "../metrics/metrics";
import { useUserRole } from "../settings/query";
import { USER_STATE } from "../state/global";
import { getDocsHome } from "../utils/api";
import {
    PREDIBASE_LIGHT_GRAY,
    SEMANTIC_BLACK,
    SEMANTIC_BLUE,
    SEMANTIC_GREY_ACTIVE,
    SEMANTIC_WHITE,
} from "../utils/colors";

import "./HomePage.css";

const GenerateButton = (props: { onClick?: MouseEventHandler<HTMLButtonElement> | undefined }) => (
    <>
        <button
            aria-label="Generate API token"
            className="button-reset"
            style={{
                padding: 0,
                marginBottom: props?.onClick ? undefined : "0.5rem",
            }}
            onClick={props?.onClick}
        >
            Generate
        </button>
        &nbsp;your personalized API token.
    </>
);

const GenerateAPIToken = () => {
    const [open, setOpen] = useState(false);
    const [user] = useRecoilState(USER_STATE);
    const { error, token, isLoading } = useAPIToken("Autogenerated from the Home Page Quickstart", open);
    const isError = !!error;

    if (!token && !open) {
        return (
            <GenerateButton
                onClick={(event) => {
                    event.preventDefault();
                    setOpen(true);
                    // Purposely updated to match PostHog's suggested naming convention
                    metrics.capture("api_token_generated", {
                        page: "home_page",
                    });
                    user &&
                        track(user, "generate_api_token", {
                            page: "home_page",
                        });
                }}
            />
        );
    }

    return (
        <>
            <GenerateButton />
            <div style={{ display: "flex", flexDirection: "column" }}>
                {isError && (
                    <Message style={{ maxWidth: "20rem" }} size="small" negative>
                        {String(error)}
                    </Message>
                )}
                <div style={{ width: "100%", display: "block", marginBottom: "1rem" }}>
                    <Input
                        disabled={isLoading || isError}
                        value={token?.token ?? ""}
                        style={{
                            width: "28.92857rem",
                            marginRight: "0.57rem",
                        }}
                    />
                    {isLoading ? (
                        <Loader active inline size="tiny" />
                    ) : (
                        <CopyButton
                            text={token?.token ?? ""}
                            copyText="Copy API token"
                            style={{
                                background: SEMANTIC_BLUE,
                                borderRadius: "0.28571rem",
                                width: "3rem",
                                height: "2.57143rem",
                            }}
                            iconElement={<Icon name="copy outline" style={{ color: SEMANTIC_WHITE }} />}
                            disabled={isError}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

const LargeLinks = (props: {
    background: string;
    imageURL: string;
    url: string;
    title: string;
    description: string;
}) => {
    const [user] = useRecoilState(USER_STATE);

    return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
            href={props.url}
            target="_blank"
            rel="noopener"
            style={{
                display: "flex",
                flexDirection: "column",
                padding: "2.71rem 1.71rem 1.71rem 1.71rem",
                borderRadius: "0.42857rem",
                background: props.background,
                color: SEMANTIC_WHITE,
            }}
            onClick={() => user && track(user, "docs", { url: props.url, clickSource: "homepage-large-link" })}
        >
            <img src={props.imageURL} alt="" width="46px" style={{ marginBottom: "0.5rem" }} />
            <b style={{ fontSize: "1.07143rem" }}>{props.title}</b>
            <p style={{ opacity: "0.8" }}>{props.description}</p>
        </a>
    );
};

const ItemLinks = (props: {
    iconName: SemanticICONS;
    iconStyle: any;
    url: string;
    title: string;
    description: string;
    analyticsEventPayload?: any;
}) => {
    const [user] = useRecoilState(USER_STATE);

    return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
            href={props.url}
            target="_blank"
            rel="noopener"
            style={{ display: "flex", flexDirection: "row", color: SEMANTIC_BLACK }}
            onClick={() => user && track(user, "docs", { url: props.url, clickSource: "homepage-item-link" })}
        >
            <Icon
                name={props.iconName}
                style={{
                    color: SEMANTIC_WHITE,
                    background: SEMANTIC_BLACK,
                    padding: "12px",
                    borderRadius: "0.42857rem",
                    width: "3.42857rem",
                    height: "3.42857rem",
                    ...props.iconStyle,
                }}
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "1.14rem",
                    justifyContent: "center",
                }}
            >
                <strong>{props.title}</strong>
                <p style={{ color: SEMANTIC_GREY_ACTIVE }}>{props.description}</p>
            </div>
        </a>
    );
};

const HomePage = () => {
    const userRole = useUserRole();

    return (
        <Grid columns={1} style={{ maxWidth: `${1024 / 14}rem`, margin: "2.29rem auto" }}>
            {userRole !== role.READONLY && (
                <Grid.Row className="home-page-grid-spacing">
                    <Grid.Column>
                        <div
                            style={{
                                padding: "1.71rem",
                                borderRadius: "0.42857rem",
                                background: PREDIBASE_LIGHT_GRAY,
                            }}
                        >
                            <Header as="h3">Set up the Python SDK</Header>
                            <ol style={{ marginBottom: "0" }}>
                                <li>
                                    Download the Predibase SDK: <CodeBlock>pip install -U predibase</CodeBlock>
                                </li>
                                <li>
                                    <GenerateAPIToken />
                                </li>
                            </ol>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            )}
            <Grid.Row className="home-page-grid-spacing">
                <Grid.Column>
                    <Header as="h3">Getting Started</Header>
                    <Grid stackable columns={3}>
                        <Grid.Column>
                            <LargeLinks
                                url={`${getDocsHome()}/user-guide/getting-started/end-to-end`}
                                background="linear-gradient(270deg, #2185D0 -2.23%, #6B02BD 100%)"
                                imageURL="/icons/llm-quickstart-icon.png"
                                title="Fine-tune Mistral-7B for News Article Summarization"
                                description="Fine-tune and prompt your first adapter on Predibase"
                            ></LargeLinks>
                        </Grid.Column>
                        <Grid.Column>
                            <LargeLinks
                                url={`${getDocsHome()}/user-guide/fine-tuning/grpo`}
                                background="linear-gradient(270deg, #C78F22 0%, #DF2510 100%)"
                                imageURL="/icons/rft-icon.png"
                                title="Reinforcement Fine-tuning (GRPO)"
                                description="Introducing the first RFT platform. Train your first RFT adapter with as few as 10 examples."
                            ></LargeLinks>
                        </Grid.Column>
                        <Grid.Column>
                            <LargeLinks
                                url="https://predibase.com/fine-tuning-leaderboard"
                                // background="linear-gradient(270deg, #EF694A -2.23%, #B63123 100%)"
                                background="linear-gradient(270deg, #9BBD11 0%, #2F8E4A 100%)"
                                imageURL="/icons/podium.png"
                                title="Fine-Tuning Leaderboard"
                                description="Explore detailed performance benchmarks for the most popular small language models"
                            ></LargeLinks>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className="home-page-grid-spacing">
                <Grid.Column>
                    <Header as="h3">End-to-end Examples</Header>
                    <Grid stackable columns={2}>
                        <Grid.Column>
                            <ItemLinks
                                url={`${getDocsHome()}/user-guide/examples/lora_land_for_customer_support`}
                                title="Build LoRA Land for Customer Support"
                                description="Fine-Tune and Serve 3 LoRA adapters for JSON generation"
                                iconName="file"
                                iconStyle={{
                                    color: SEMANTIC_WHITE,
                                    background: SEMANTIC_BLACK,
                                }}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <ItemLinks
                                url={`${getDocsHome()}/user-guide/examples/rag`}
                                title="Retrieval-Augmented Generation (RAG)"
                                description="Use an LLM to answer questions on unstructured documents"
                                iconName="file"
                                iconStyle={{
                                    color: SEMANTIC_WHITE,
                                    background: SEMANTIC_BLACK,
                                }}
                            />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default HomePage;
