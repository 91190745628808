import { baseModel, trainingParams } from "@/autogen/openapi";

import { sortBaseModels } from "../../../deployments/misc/dropdown-utils";
import { AdapterConfig } from "./schema";

export const getConfigValidationErrors = (
    invalidFields: any,
    connection?: Connection,
    dataset?: Dataset,
    continueMode?: boolean,
) => {
    let validationErrors: string[] = [];
    const baseModelRequired = !continueMode;

    if (!connection) {
        validationErrors.push(`Must select a connection and dataset.`);
    }
    if (connection && !dataset) {
        validationErrors.push(`Must select a dataset.`);
    }
    if (invalidFields) {
        Object.keys(invalidFields).forEach((field: string) => {
            let fieldError = invalidFields[field];
            // TODO: This specific check may not be required anymore, normal validation seems to catch it:
            if (field === "base_model" && baseModelRequired) {
                validationErrors.push(`Must specify a Base Model.`);
                return;
            }
            validationErrors.push(`${field} ${fieldError.errorMessages.join(", ")}.`);
        });
    }

    // TODO: All of this is starting to get convoluted, should consider cleanly separating the continue and
    // non-continue modes into separate logical flows:
    if (!baseModelRequired) {
        validationErrors = validationErrors.filter((error) => {
            if (error.includes("must have required property 'base_model'")) {
                return false;
            }
            return true;
        });
    }

    return validationErrors;
};

export const getSelectedLLM = (llmValue?: string, baseModels?: baseModel[]) => {
    baseModels = Array.isArray(baseModels) ? baseModels : [];

    let matchedLlms = baseModels.filter((baseModel) => baseModel.name === llmValue);
    if (matchedLlms.length) {
        return matchedLlms[0];
    }
    return undefined;
};

export const getLLMDropdownOptions = (llmValue?: string, baseModels?: baseModel[], config?: Partial<AdapterConfig>) => {
    baseModels = Array.isArray(baseModels) ? baseModels : [];
    baseModels = sortBaseModels(baseModels) ?? [];

    let llmOptions = baseModels.map((baseModel) => {
        return {
            key: baseModel.name,
            text: baseModel.name,
            value: baseModel.name,
            disabled: false,
        };
    });

    if (
        config?.base_model &&
        config?.base_model !== "" &&
        !llmOptions.filter((option) => option.value === config.base_model).length
    ) {
        llmValue = `Other (${config.base_model})`;
        llmOptions.push({
            key: llmValue,
            text: llmValue,
            value: config.base_model,
            disabled: false,
        });
    }
    return llmOptions;
};

// TODO: Ideally the adapter types should be promoted to an enum in the OpenAPI spec:
export type adapterTypes = trainingParams["adapterType"]["allValues"][0];
export type taskTypes = trainingParams["taskType"]["allValues"][0];

export const getTaskDropdownOptions = (baseModel?: baseModel) => {
    const allTaskOptions = [
        { key: "instruction_tuning", text: "SFT (default)", value: "instruction_tuning" },
        { key: "completion", text: "Continued Pretraining", value: "completion" },
    ];
    const filteredTaskOptions = allTaskOptions.filter((option) => {
        return baseModel !== undefined
            ? baseModel.trainingParams.taskType.allValues.includes(option.value as taskTypes)
            : option;
    });

    return filteredTaskOptions;
};

export const getAdapterRankDropdownOptions = (config?: Partial<AdapterConfig>) => {
    const adapterRankOptions = [
        { key: "16", text: "16 (default)", value: 16 },
        { key: "8", text: "8", value: 8 },
        { key: "32", text: "32", value: 32 },
        { key: "64", text: "64", value: 64 },
    ];
    if (config?.rank && !adapterRankOptions.filter((option) => option.value === config.rank).length) {
        adapterRankOptions.push({
            key: config.rank.toString(),
            text: `Other (${config.rank.toString()})`,
            value: config.rank,
        });
    }
    return adapterRankOptions;
};

export const getTargetModulesOptions = (baseModel?: baseModel, target_modules?: string[]) => {
    if (!baseModel) {
        if (target_modules) {
            return target_modules.map((option: string) => {
                return {
                    key: option,
                    text: option,
                    value: option,
                    disabled: false,
                };
            });
        }
        return [];
    }

    let targetModulesOptions = baseModel.trainingParams.targetModules.allValues.map((option) => {
        return {
            key: option,
            text: option,
            value: option,
            disabled: false,
        };
    });
    return targetModulesOptions;
};

export const getTargetModulesDefaultValue = (baseModel?: baseModel) => {
    if (!baseModel) {
        return [];
    }

    return baseModel.trainingParams.targetModules.defaultValue;
};
