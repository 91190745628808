import { deploymentStatus } from "@/autogen/openapi";

import Chip from "../../components/Chip";
import {
    SEMANTIC_GREEN,
    SEMANTIC_GREEN_ACTIVE,
    SEMANTIC_GREY,
    SEMANTIC_OLIVE,
    SEMANTIC_OLIVE_ACTIVE,
    SEMANTIC_RED,
    SEMANTIC_RED_ACTIVE,
} from "../../utils/colors";
import { capitalize } from "../../utils/strings";

const DeploymentStatusChip = (props: { status: deploymentStatus; width?: string }) => {
    let color = SEMANTIC_GREY;
    let textColor = undefined;
    let opacity = 0.1;
    switch (props.status) {
        case deploymentStatus.PENDING:
        case deploymentStatus.INITIALIZING:
        case deploymentStatus.UPDATING:
            color = SEMANTIC_OLIVE;
            textColor = SEMANTIC_OLIVE_ACTIVE;
            break;
        case deploymentStatus.ERRORED:
            color = SEMANTIC_RED;
            textColor = SEMANTIC_RED_ACTIVE;
            break;

        case deploymentStatus.READY:
            color = SEMANTIC_GREEN;
            textColor = SEMANTIC_GREEN_ACTIVE;
            break;

        case deploymentStatus.STANDBY:
        case deploymentStatus.STOPPED:
        case deploymentStatus.DELETED:
            opacity = 0.3;
            break;
    }
    return (
        <Chip
            color={color}
            text={capitalize(props.status)}
            textColor={textColor}
            opacity={opacity}
            width={`${100 / 14}rem`}
        />
    );
};

export default DeploymentStatusChip;
