import { useRef } from "react";

import { useQueryClient } from "@tanstack/react-query";
import Convert from "ansi-to-html";
import { Button, Icon, Tab } from "semantic-ui-react";

import { SEMANTIC_GREY_DISABLED, SEMANTIC_WHITE } from "../../../../utils/colors";
import { TERMINAL_BLACK_BACKGROUND } from "../../../misc/utils";

const AnsiConvert = new Convert();

export interface PodLogsWithTimestamp {
    data: [string, string][];
    timestamp: number;
}

const formatDateTime = (timestamp: number) => {
    const date = new Date(timestamp);
    const dateString = date.toLocaleDateString("en-US", { month: "long", day: "numeric" });
    const timezoneString = date.toLocaleTimeString("en-US", { timeZoneName: "short" });
    return `${dateString} ${timezoneString}`;
};

interface LogsStatusProps {
    logsUpdatedAt: PodLogsWithTimestamp["timestamp"];
}

const LogsStatus = (props: LogsStatusProps) => {
    const { logsUpdatedAt } = props;

    return (
        <div style={{ padding: "5px 0 10px" }}>
            Last updated at {formatDateTime(logsUpdatedAt)}
            <span style={{ color: SEMANTIC_GREY_DISABLED, marginLeft: "0.5rem" }}>(Newest First)</span>
        </div>
    );
};

const GrafanaLogs = (props: {
    logs: PodLogsWithTimestamp["data"];
    logsUpdatedAt: PodLogsWithTimestamp["timestamp"];
    loadingLogs: boolean;
    queryKeyToInvalidate: string[];
}) => {
    // Parent state:
    const { logs, logsUpdatedAt, loadingLogs, queryKeyToInvalidate: queryKey } = props;

    // Refs:
    const bottomRef = useRef<HTMLDivElement>(null);
    const topRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const scrollToTop = () => {
        topRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // Query state:
    const queryClient = useQueryClient();

    return (
        <>
            <div ref={topRef}></div>
            <div style={{ display: "flex", padding: "0 0 5px" }}>
                <div style={{ flexGrow: "1" }}>
                    <LogsStatus logsUpdatedAt={logsUpdatedAt} />
                </div>
                <Button className="small" onClick={scrollToBottom}>
                    Scroll To Bottom
                </Button>
                <Button
                    primary
                    className="small"
                    onClick={async () => {
                        await queryClient.invalidateQueries({ queryKey });
                    }}
                    loading={loadingLogs}
                    disabled={loadingLogs}
                >
                    <Icon name="refresh" />
                    Refresh
                </Button>
            </div>
            <Tab.Pane
                key={"grafanaLogs"}
                as={"div"}
                style={{
                    minHeight: "40rem",
                    background: TERMINAL_BLACK_BACKGROUND,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                }}
            >
                {Array.isArray(logs) && logs.length > 0 && (
                    <ul style={{ listStyleType: "none", padding: 0 }}>
                        {logs.map((log, index) => {
                            const timestamp = log[0];
                            const logStr = log[1];
                            const convertedStr = AnsiConvert.toHtml(logStr);
                            if (logStr.length === 0) {
                                return <></>;
                            }
                            return (
                                <div key={index} style={{ padding: "0 20px", color: SEMANTIC_WHITE }}>
                                    <code>
                                        <span style={{ color: SEMANTIC_GREY_DISABLED, marginRight: "1rem" }}>
                                            {
                                                // Timestamp is in nanoseconds
                                                formatDateTime(Number(timestamp) / 1000000)
                                            }
                                        </span>
                                        <span dangerouslySetInnerHTML={{ __html: convertedStr }}></span>
                                    </code>
                                </div>
                            );
                        })}
                    </ul>
                )}
            </Tab.Pane>
            <div ref={bottomRef}></div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
                <LogsStatus logsUpdatedAt={logsUpdatedAt} />
                <Button className="small" onClick={scrollToTop} style={{ marginRight: "10px" }}>
                    Scroll To Top
                </Button>
            </div>
        </>
    );
};

export default GrafanaLogs;
