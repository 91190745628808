import { Icon } from "semantic-ui-react";

import GrafanaLogs from "../../../../deployments/views/deployment/tabs/GrafanaLogs";
import { getDocsHome } from "../../../../utils/api";
import { GET_GRPO_POD_LOGS_QUERY_KEY, useGRPOPodLogsWithTimestampQuery } from "../../../query";

const RewardLogs = (props: { jobUUID: string }) => {
    // Parent state:
    const { jobUUID } = props;

    // Query state:
    const { data: logsData, isFetching: loadingLogs } = useGRPOPodLogsWithTimestampQuery(jobUUID ?? "", {
        enabled: Boolean(jobUUID),
        retry: (failureCount, error) => {
            const errorMessage = error?.message ?? (error as unknown as string);
            if (errorMessage === "record not found" || errorMessage?.includes("No logs found")) {
                return false;
            }
            return failureCount < 3;
        },
    });

    // Derived state:
    const logs = logsData?.data ?? [];
    const logsUpdatedAt = logsData?.timestamp ?? 0;
    const queryKey = GET_GRPO_POD_LOGS_QUERY_KEY(jobUUID ?? "");

    return (
        <>
            <a
                href={`${getDocsHome()}/user-guide/fine-tuning/grpo#how-do-i-use-the-reward-logs-tab`}
                target="_blank"
                rel="noreferrer"
                style={{ display: "block", marginBottom: "1.14rem" }}
            >
                <Icon name="book" />
                Learn how to use reward logs for debugging
            </a>
            <GrafanaLogs
                logs={logs}
                logsUpdatedAt={logsUpdatedAt}
                loadingLogs={loadingLogs}
                queryKeyToInvalidate={queryKey}
            />
        </>
    );
};

export default RewardLogs;
