import { AuthorizationParams, useAuth0 } from "@auth0/auth0-react";
import { Button, Divider } from "semantic-ui-react";

import { PredibaseURLState, welcomeUrl } from "./utils";

export enum AuthenticationModes {
    Login = "login",
    SignUp = "signup",
}

interface AuthenticationOptionsProps extends PredibaseURLState {
    mode?: AuthenticationModes;
}

const AuthenticationOptions = ({ returnTo, inviteToken, tenantName, mode }: AuthenticationOptionsProps) => {
    const { loginWithRedirect } = useAuth0();
    const returnUrl = welcomeUrl + (returnTo ? `?return_to=${returnTo}` : "");

    const appState: PredibaseURLState = {
        // Come back to welcome page for new users
        returnTo: returnUrl,
        inviteToken,
        tenantName,
    };

    const authParamsBase = {} as AuthorizationParams;
    if (mode) {
        authParamsBase.screen_hint = mode;
    }

    const labelText = mode === AuthenticationModes.SignUp ? "Sign up" : "Sign in";

    return (
        <>
            <Button
                fluid
                size="large"
                className="social-signin-button"
                onClick={() =>
                    loginWithRedirect({
                        authorizationParams: {
                            ...authParamsBase,
                            connection: "google-oauth2",
                        },
                        appState,
                    })
                }
            >
                <i className="icon google"></i>
                {labelText} with Google
            </Button>
            <Divider horizontal>or</Divider>
            <Button
                fluid
                size="large"
                color="black"
                onClick={() =>
                    loginWithRedirect({
                        authorizationParams: {
                            ...authParamsBase,
                            connection: "Username-Password-Authentication",
                        },
                        appState,
                    })
                }
            >
                {labelText} with email / password
            </Button>
            <Button
                fluid
                size="large"
                onClick={() =>
                    loginWithRedirect({
                        authorizationParams: {
                            ...authParamsBase,
                        },
                        appState,
                    })
                }
            >
                {labelText} with Okta
            </Button>
            <div>
                By signing in or creating an account, you agree with our{" "}
                {
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a target="_blank" href="https://predibase.com/terms-of-service">
                        Terms & Conditions
                    </a>
                }{" "}
                and{" "}
                {
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a target="_blank" href="https://predibase.com/privacy-policy">
                        {" "}
                        Privacy Statement.
                    </a>
                }
            </div>
            {mode !== AuthenticationModes.SignUp && (
                <div>
                    New to Predibase?{" "}
                    {
                        <Button
                            basic
                            className="no-outline blue-link"
                            onClick={() =>
                                loginWithRedirect({
                                    appState,
                                    authorizationParams: {
                                        screen_hint: AuthenticationModes.SignUp,
                                    },
                                })
                            }
                        >
                            Sign up
                        </Button>
                    }
                </div>
            )}
        </>
    );
};

export default AuthenticationOptions;
