import { useState } from "react";

import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { Button, Form, Message, Popup } from "semantic-ui-react";

import { Auth0TokenOptions, useAuth0TokenOptions } from "../../data";
import { getErrorMessage } from "../../utils/errors";

const EditableDescriptionContent = (props: { adapterURLPath?: string; description?: string }) => {
    const { adapterURLPath, description } = props;
    const renderedDescription = description ? description : "—";

    if (!adapterURLPath) {
        return <span>{renderedDescription}</span>;
    }

    return (
        <Link to={adapterURLPath} className={"black-link"}>
            {renderedDescription}
        </Link>
    );
};

const EditableDescription = (props: {
    adapterURLPath?: string;
    description?: string;
    inputMinWidth?: string;
    updateDescriptionFunction: (description: string, auth0TokenOptions?: Auth0TokenOptions) => Promise<unknown>;
    queryKeyToInvalidate: QueryKey;
}) => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Parent state:
    const { adapterURLPath, description, inputMinWidth, updateDescriptionFunction, queryKeyToInvalidate } = props;

    // Local state:
    const [popupOpen, setPopupOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [text, setText] = useState(description ?? "");

    const queryClient = useQueryClient();

    const {
        mutate: mutateAdapterVersionDescription,
        error: mutationError,
        reset: resetMutation,
    } = useMutation({
        mutationFn: () => updateDescriptionFunction(text, auth0TokenOptions),
        onSuccess: () => {
            if (queryKeyToInvalidate) {
                queryClient.invalidateQueries({ queryKey: queryKeyToInvalidate });
            }
            closeEditor();
        },
    });

    const errorMessage = getErrorMessage(mutationError);

    const closeEditor = () => {
        setText(description ?? "");
        setIsEditing(false);
        resetMutation();
    };

    if (!isEditing) {
        return (
            <div
                onMouseEnter={() => setPopupOpen(true)}
                onMouseLeave={() => setPopupOpen(false)}
                style={{ width: "100%" }}
            >
                <Popup
                    basic
                    open={popupOpen}
                    style={{ padding: "0px", border: "none", boxShadow: "none", background: "none" }}
                    offset={[0, -10]}
                    hoverable
                    className={"transition-scale"}
                    position={"right center"}
                    trigger={<EditableDescriptionContent adapterURLPath={adapterURLPath} description={description} />}
                    content={
                        <Button
                            icon={"pencil"}
                            size={"medium"}
                            className={"no-background"}
                            basic
                            style={{ boxShadow: "none", background: "none" }}
                            onClick={() => setIsEditing(true)}
                        />
                    }
                />
            </div>
        );
    }

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <Form style={{ width: "80%", minWidth: inputMinWidth }} onSubmit={() => mutateAdapterVersionDescription()}>
                <Form.Input
                    autoFocus
                    placeholder={"Description"}
                    value={text}
                    onChange={(event, data) => setText(data.value)}
                />
            </Form>
            &emsp;
            <Button
                className={"no-outline"}
                icon={"check"}
                color={"blue"}
                size={"mini"}
                basic
                circular
                onClick={() => {
                    mutateAdapterVersionDescription();
                }}
            />
            <Button
                className={"no-outline"}
                icon={"cancel"}
                color={"grey"}
                size={"mini"}
                basic
                circular
                onClick={() => {
                    closeEditor();
                }}
            />
            {errorMessage && <Message negative>{errorMessage}</Message>}
        </div>
    );
};

export default EditableDescription;
