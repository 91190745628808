import { User as Auth0User } from "@auth0/auth0-react";
import { atom } from "recoil";

import { CurrentUser } from "../types/user";

export const SESSION_STATE = atom<Auth0User | undefined>({
    key: "SESSION_STATE",
    default: undefined,
});

export const USER_STATE = atom<CurrentUser | undefined>({
    key: "USER_STATE",
    default: undefined,
});

export const ENVIRONMENT_SHORTCODE_STATE = atom<string | undefined>({
    key: "ENVIRONMENT_SHORTCODE_STATE",
    default: undefined,
});
