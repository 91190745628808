import { useEffect, useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import { Grid, Loader, Message, Table } from "semantic-ui-react";

import { useAPIToken } from "../api/useToken";
import { ErrorBoundaryMessage } from "../components/ErrorBoundary";
import { GET_DEPLOYMENT_QUERY_KEY } from "../deployments/data/query";
import metrics from "../metrics/metrics";
import { GET_USER_CREDITS_QUERY_KEY, useDeploymentsQuery } from "../query";
import { USER_STATE } from "../state/global";
import ControlBar from "./ControlBar";
import Response from "./Response";
import Workspace from "./Workspace";
import { GenerateResponse } from "./data";
import {
    GENERATE_STREAM_QUERY_KEY,
    useAdaptersListQuery,
    useDeploymentIsReadyQuery,
    useGenerateStreamQuery,
} from "./query";
import {
    DropdownItemsArray,
    generateAdapterRepoSelectorOptions,
    generateAdapterVersionSelectorOptions,
    generateDeploymentSelectorOptions,
    generateDeploymentToAdapterRepoMap,
    sortDeployments,
} from "./utils/dropdown-utils";
import { predibaseStreamHasFinished } from "./utils/lorax";
import { matchQueryString } from "./utils/model-matching";
import { ActionType, PromptStateProvider, useDispatch, usePromptState } from "./utils/reducer";

import "./PromptView.css";

const parseQueryStringParamsNumericID = (id: string | null) => {
    if (id !== null) {
        const parsed = parseInt(id);
        if (!isNaN(parsed)) {
            return parsed;
        }
    }
    return undefined;
};

const Prompt = () => {
    // User state:
    const [userContext] = useRecoilState(USER_STATE);

    const shortCode = userContext?.tenant.shortcode ?? "";

    // Reducer state:
    const {
        prompt,
        temperature,
        maxNewTokens,
        selectedDeployment,
        selectedAdapter,
        promptTemplate,
        promptTemplateVariables,
    } = usePromptState();
    const dispatch = useDispatch();

    // Local state:
    const [adapterRepoOptions, setAdapterRepoOptions] = useState<DropdownItemsArray>([]);
    const [adapterVersionOptions, setAdapterVersionOptions] = useState<DropdownItemsArray>([]);
    const [selectedAdapterRepo, setSelectedAdapterRepo] = useState<string>("");
    const [promptTemplateVisible, setPromptTemplateVisible] = useState<boolean>(false);
    const [deploymentIsInitializing, setDeploymentIsInitializing] = useState<boolean>(false);
    const [userHasTypedAtLeastOnceWithCurrentDeployment, setUserHasTypedAtLeastOnceWithCurrentDeployment] =
        useState<boolean>(false);
    const [responseHasFinishedStreaming, setResponseHasFinishedStreaming] = useState<boolean>(true);

    // Query string params:
    const queryStringParams = new URLSearchParams(window.location.search);
    const qspAdapterRepoVersion = parseQueryStringParamsNumericID(queryStringParams.get("version"));
    const qspName = queryStringParams.get("name");
    const qspAdapterRepoName = qspName ? decodeURIComponent(qspName) : undefined;
    // Can be the short name, long name or deployment name:
    const qspModel = queryStringParams.get("model");
    const qspModelIdentifier = qspModel ? decodeURIComponent(qspModel) : undefined;

    // Query state:
    const queryClient = useQueryClient();
    const {
        token: apiToken,
        error: apiTokenError,
        isLoading: apiTokenIsLoading,
    } = useAPIToken("Autogenerated from Prompt editor", true);

    // Get all current deployments:
    const {
        data: deployments,
        isLoading: deploymentsAreLoading,
        error: deploymentsQueryError,
    } = useDeploymentsQuery({
        refetchOnWindowFocus: false,
        refetchInterval: 1000 * 10, // 10 seconds
    });

    // Get a sorted version of the deployments:
    const sortedDeployments = useMemo(() => sortDeployments(deployments), [deployments]);

    // Get all adapters:
    const { data: adapters, isLoading: adaptersAreLoading, error: adaptersListQueryError } = useAdaptersListQuery();

    // Variables that should only be generated once (after new data is fetched):
    const { deploymentSelectorOptions, deploymentUUIDLookup, deploymentUUIDToAdapterReposMap } = useMemo(() => {
        const [deploymentSelectorOptions, deploymentUUIDLookup] = generateDeploymentSelectorOptions(sortedDeployments);
        const deploymentUUIDToAdapterReposMap = generateDeploymentToAdapterRepoMap(sortedDeployments, adapters);
        return { deploymentSelectorOptions, deploymentUUIDLookup, deploymentUUIDToAdapterReposMap };
    }, [sortedDeployments, adapters]);

    // Regenerate adapter options whenever data is fetched or a deployment is selected:
    const adapterRepoLookup = useMemo(() => {
        const [adapterRepoOptions, adapterRepoLookup] = generateAdapterRepoSelectorOptions(
            deploymentUUIDToAdapterReposMap[selectedDeployment?.uuid ?? ""],
        );
        setAdapterRepoOptions(adapterRepoOptions);
        return adapterRepoLookup;
    }, [deploymentUUIDToAdapterReposMap, selectedDeployment]);

    const adapterVersionLookup = useMemo(() => {
        const [adapterVersionOptions, adapterVersionLookup] = generateAdapterVersionSelectorOptions(
            adapterRepoLookup[selectedAdapterRepo],
        );
        setAdapterVersionOptions(adapterVersionOptions);
        return adapterVersionLookup;
    }, [adapterRepoLookup, selectedAdapterRepo]);

    // Initialize the prompt UI's default selections, if necessary:
    useEffect(() => {
        // Do nothing unless we have a valid list of deployments and adapters (implying that the downstrea maps are
        // also populated):
        if (deployments === null || deployments === undefined || adapters === undefined) {
            return;
        }

        // Then, if there is already a selected deployment that implies the user has selected it (or left it selected),
        // so leave the UI state alone:
        if (selectedDeployment !== null) {
            return;
        }

        // If the previous checks fail, that means the component has been freshly mounted (with new data), so all UI
        // state is uninitialized.
        //
        // If there are not query string parameters provided, just select the first deployment by default:
        if (
            qspAdapterRepoVersion === undefined &&
            qspAdapterRepoVersion === undefined &&
            qspModelIdentifier === undefined
        ) {
            dispatch({ type: ActionType.UPDATE, selectedDeployment: sortedDeployments[0] });
            return;
        }

        // Otherwise, try to match the query string:
        const match = matchQueryString(
            {
                versionTag: qspAdapterRepoVersion,
                adapterRepoName: qspAdapterRepoName,
                modelIdentifier: qspModelIdentifier,
            },
            deploymentUUIDToAdapterReposMap,
            deploymentUUIDLookup,
        );
        const [deploymentMatch, adapterMatch] = match;
        if (adapterMatch) {
            setSelectedAdapterRepo(adapterMatch.repo);
            dispatch({ type: ActionType.UPDATE, selectedDeployment: deploymentMatch, selectedAdapter: adapterMatch });
        } else if (deploymentMatch) {
            dispatch({ type: ActionType.UPDATE, selectedDeployment: deploymentMatch });
        }
    }, [
        qspAdapterRepoVersion,
        qspAdapterRepoName,
        qspModelIdentifier,
        deploymentUUIDLookup,
        deploymentUUIDToAdapterReposMap,
        selectedDeployment,
        deployments,
        sortedDeployments,
        adapters,
    ]);

    // Performs the request that generates responses from the selected deployment:
    // ! NOTE: We use a query here because RQ mutations do not expose a "cancel" feature equivalent to `cancelQueries`:
    const {
        data: generateResponse,
        error: generateError,
        refetch: generateRefetch,
        isRefetching: generateIsRefetching,
        isLoading: generateIsLoading,
        isError: generateIsErrored,
        isSuccess: generateIsSuccessful,
    } = useGenerateStreamQuery(
        shortCode,
        apiToken?.token!,
        {
            selectedDeployment,
            selectedAdapter,
            prompt,
            promptTemplate,
            promptTemplateVariables,
            temperature,
            maxNewTokens,
        },
        queryClient,
        {
            enabled: false, // Do not automatically run
            staleTime: Infinity,
            gcTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retry: (failureCount, error) => {
                if (error && ((error as any).code === 502 || (error as any).code === 503)) {
                    if (failureCount < 3) {
                        setDeploymentIsInitializing(true);
                        return true;
                    }
                }
                setDeploymentIsInitializing(false);
                return false;
            },
        },
    );

    // Side effects related to the generate query:
    const deploymentsLength = deployments?.length;
    useEffect(() => {
        if (generateError) {
            const err = generateError;
            // TODO: Weird typing error with query errors?
            metrics.captureError("prompt_error", err?.message || "", {
                source: "ui",
                wasSuccessful: false,
                isFinetuned: !!selectedAdapter,
                deployment: selectedDeployment?.name,
                adapterRepo: selectedAdapter?.repo,
                adapterVersion: selectedAdapter?.versionTag,
                error: err,
            });
            queryClient.invalidateQueries({ queryKey: GET_USER_CREDITS_QUERY_KEY });
        } else if (generateResponse) {
            setDeploymentIsInitializing(false);
            setResponseHasFinishedStreaming(false);

            // After a prompt stream has completed, the deployment must be up so refetch its scaled status:
            if (predibaseStreamHasFinished(generateResponse.details)) {
                if (selectedDeployment && selectedDeployment.uuid && selectedDeployment.uuid.length > 0) {
                    queryClient.invalidateQueries({
                        queryKey: GET_DEPLOYMENT_QUERY_KEY(selectedDeployment.uuid),
                    });
                } else {
                    metrics.captureError("selected_deployment_missing", "Deployment UUID is missing", {
                        deploymentsLength,
                        selectedDeployment,
                    });
                }
                queryClient.invalidateQueries({ queryKey: GET_USER_CREDITS_QUERY_KEY });
                setResponseHasFinishedStreaming(true);
            }
        }
    }, [generateResponse, generateError, selectedAdapter, selectedDeployment, queryClient, deploymentsLength]);

    // Utility functions:
    // Fetches whether the current deployment is scaled:
    const getDeploymentIsReadyQuery = useDeploymentIsReadyQuery(selectedDeployment?.name ?? "", {
        enabled: false, // Do not automatically run
        staleTime: Infinity,
        gcTime: 0,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });

    const submitPrompt = () => {
        queryClient.invalidateQueries({ queryKey: GENERATE_STREAM_QUERY_KEY });
        generateRefetch();
    };

    // Ping that triggers deployment to scale up:
    const pingDeploymentToScaleUp = () => {
        if (!userHasTypedAtLeastOnceWithCurrentDeployment && selectedDeployment) {
            setUserHasTypedAtLeastOnceWithCurrentDeployment(true);
            getDeploymentIsReadyQuery.refetch();
        }
    };

    // Cancel any running query WITHOUT clearing the current response data:
    const stopGenerateQuery = () => {
        const incompleteData = queryClient.getQueryData<GenerateResponse>(GENERATE_STREAM_QUERY_KEY);
        const dataWithCancelReason: GenerateResponse | undefined = incompleteData?.details?.finish_reason
            ? incompleteData
            : incompleteData
              ? {
                    ...incompleteData,
                    details: { ...incompleteData?.details, finish_reason: "early_cancellation" },
                }
              : undefined;
        queryClient.cancelQueries({ queryKey: GENERATE_STREAM_QUERY_KEY });
        queryClient.setQueryData(GENERATE_STREAM_QUERY_KEY, dataWithCancelReason);
        setResponseHasFinishedStreaming(true);
    };

    // Cancel any running query AND clear the current response data:
    const cancelGenerateQuery = () => {
        queryClient.cancelQueries({ queryKey: GENERATE_STREAM_QUERY_KEY });
        queryClient.setQueryData(GENERATE_STREAM_QUERY_KEY, undefined);
        setResponseHasFinishedStreaming(true);
    };

    // Extra component logic:
    if (adaptersListQueryError || deploymentsQueryError) {
        return <ErrorBoundaryMessage />;
    }

    // Show the loader the first time the page loads:
    const queriesAreLoadingWithoutCachedData =
        (deploymentsAreLoading && deployments === undefined) ||
        (adaptersAreLoading && adapters === undefined) ||
        (apiTokenIsLoading && apiToken === undefined);

    return (
        <>
            {queriesAreLoadingWithoutCachedData && (
                <div className="loading-overlay">
                    <Loader active />
                </div>
            )}
            <Grid style={{ height: "100vh", width: "100%", padding: 0, margin: 0 }}>
                <Grid.Column computer={3} mobile={16} tablet={6} style={{ borderRight: "1px solid #CCC" }}>
                    <ControlBar
                        deploymentSelectorOptions={deploymentSelectorOptions}
                        deploymentUUIDLookup={deploymentUUIDLookup}
                        setPromptTemplateVisible={setPromptTemplateVisible}
                        adapterRepoOptions={adapterRepoOptions}
                        adapterVersionOptions={adapterVersionOptions}
                        selectedAdapterRepo={selectedAdapterRepo}
                        setSelectedAdapterRepo={setSelectedAdapterRepo}
                        adapterRepoLookup={adapterRepoLookup}
                        adapterVersionLookup={adapterVersionLookup}
                        setUserHasTypedAtLeastOnceWithCurrentDeployment={
                            setUserHasTypedAtLeastOnceWithCurrentDeployment
                        }
                        stopGenerateQuery={stopGenerateQuery}
                    />
                </Grid.Column>
                <Grid.Column
                    computer={13}
                    mobile={16}
                    tablet={10}
                    style={{ height: "100%", paddingBottom: "0rem", paddingRight: "0rem" }}
                >
                    <Grid style={{ height: "100%" }}>
                        <Grid.Row style={{ height: "50%", padding: "0rem" }}>
                            <Grid.Column style={{ height: "100%", padding: "0rem" }}>
                                <Workspace
                                    promptTemplateVisible={promptTemplateVisible}
                                    setPromptTemplateVisible={setPromptTemplateVisible}
                                    submitPrompt={submitPrompt}
                                    queryIsLoading={generateIsLoading}
                                    queryIsRefetching={generateIsRefetching}
                                    pingDeploymentToScaleUp={pingDeploymentToScaleUp}
                                    responseHasFinishedStreaming={responseHasFinishedStreaming}
                                    stopGenerateQuery={stopGenerateQuery}
                                    cancelGenerateQuery={cancelGenerateQuery}
                                    apiTokenError={apiTokenError}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ height: "50%", paddingTop: 0, paddingBottom: 0 }}>
                            <Grid.Column style={{ paddingLeft: 0, paddingRight: 0 }}>
                                {deploymentIsInitializing ? (
                                    <Table padded basic style={{ backgroundColor: "#F7F7F7", height: "100%" }}>
                                        <Message info style={{ margin: "1rem" }}>
                                            <Message.Header>Deployment endpoint is initializing...</Message.Header>
                                        </Message>
                                    </Table>
                                ) : (
                                    <Response
                                        isSuccessful={generateIsSuccessful}
                                        isErrored={generateIsErrored}
                                        error={generateError}
                                        response={generateResponse}
                                    />
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
            </Grid>
        </>
    );
};

const PromptView = () => {
    return (
        <PromptStateProvider>
            <Prompt />
        </PromptStateProvider>
    );
};

export default PromptView;
