import { useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

import { getLocalStorageKey, setLocalStorageKey } from "../browser/storage";
import { SESSION_STATE } from "../state/global";

export const useAuth0Auth = () => {
    // Global Auth state:
    const [, setSession] = useRecoilState(SESSION_STATE);

    const { isLoading: auth0IsLoading, isAuthenticated: auth0IsAuthenticated, user: auth0User } = useAuth0();

    useEffect(() => {
        if (!auth0IsLoading && auth0IsAuthenticated) {
            setSession(auth0User);
        }
    }, [auth0IsLoading, auth0User]);

    return {
        authLoading: auth0IsLoading,
        validSession: auth0IsAuthenticated,
    };
};

export const getUTMParams = () => {
    const params = new URLSearchParams(document.location.search);
    return {
        utm_source: params.get("utm_source") ?? "",
        utm_campaign: params.get("utm_campaign") ?? "",
        utm_medium: params.get("utm_medium") ?? "",
    };
};

export const useInviteParams = () => {
    let inviteToken = undefined;
    const { search } = useLocation();
    const params = new URLSearchParams(search);

    inviteToken = params.get("inviteToken") || undefined;
    if (!inviteToken) {
        inviteToken = getLocalStorageKey("inviteToken") || undefined;
    }

    const utmURLParams = getUTMParams();
    let utmParams = `utm_source=${utmURLParams.utm_source}&utm_campaign=${utmURLParams.utm_campaign}&utm_medium=${utmURLParams.utm_medium}`;
    if (Object.keys(utmURLParams).every((key) => utmURLParams[key as keyof typeof utmURLParams] === "")) {
        utmParams = getLocalStorageKey("utmParams") || "";
    }

    return {
        inviteToken,
        utmParams,
        clearInviteParams: () => {
            setLocalStorageKey("inviteToken", null);
            setLocalStorageKey("utmParams", null);
        },
        setInviteToken: (token: string) => {
            setLocalStorageKey("inviteToken", token);
        },
        setUtmParams: (params: string) => {
            setLocalStorageKey("utmParams", params);
        },
    };
};
